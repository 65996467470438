import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { SimpleUser } from "shared";
import { AppointmentState, Schedule } from "shared/src/project/Project.types";
import { ProjectPlanningTranslations } from "../ProjectPlanning.types";
import { getAbsentUserList } from "./PlanningBoard.util";

interface AvailableUsersProps {
  startDate: dayjs.Dayjs;
  schedules: Schedule[];
  users: SimpleUser[];
  translations: ProjectPlanningTranslations;
}

export const AvailableUsers: React.FC<AvailableUsersProps> = ({
  startDate,
  schedules,
  users,
  translations,
}) => {
  const endDate: dayjs.Dayjs = useMemo(
    (): dayjs.Dayjs => startDate.add(4, "day"),
    [startDate]
  );

  /**
   * Helper to get the ids of the users which are scheduled for the given day
   *
   * @param date The date to check for
   */
  const getScheduledUserIds = useCallback(
    (date: dayjs.Dayjs) => {
      const schedulesToday: Schedule[] = schedules.filter((schedule) =>
        dayjs(schedule.scheduleDate).isSame(date, "day")
      );
      const schedulesUserIds = schedulesToday
        .map((scheduleToday) =>
          Array.from(scheduleToday.scheduledUsers.values()).flat()
        )
        .flat();

      const userSchedules: string[] = schedulesToday
        .filter(
          (schedule) =>
            users.some((user) => user.id === schedule.referenceId) &&
            schedule.appointmentDetail?.state !== AppointmentState.AVAILABLE
        )
        .map((schedule) => schedule.referenceId);
      return [...schedulesUserIds, ...userSchedules];
    },
    [schedules, users]
  );

  /**
   * Callback method to get the absent user ids
   */
  const getAbsentUsersIds = useCallback(
    (date: dayjs.Dayjs) => getAbsentUserList(schedules, date),
    [schedules]
  );

  /**
   * Helper to extract the actual available users for the given day
   */
  const getAvailableUser = useCallback(
    (date: dayjs.Dayjs): SimpleUser[] => {
      // check which user is scheduled
      const allScheduledUsers: string[] = getScheduledUserIds(date);
      // check which user is absent
      const allAbsentUserIds: string[] = getAbsentUsersIds(date);

      return users.filter(
        (user) =>
          !allAbsentUserIds.includes(user.id) &&
          !allScheduledUsers.includes(user.id)
      );
    },
    [getAbsentUsersIds, getScheduledUserIds, users]
  );

  /**
   * Util to generate the header information which includes how many users are
   * available or absent
   */
  const headerElements = useCallback(
    (type: "available" | "absent"): JSX.Element[] => {
      const targetArray: JSX.Element[] = [
        <p className="project-planning__header-wrapper__header__date" />,
      ];
      for (
        let currDate = startDate;
        currDate.isBefore(endDate) || currDate.isSame(endDate);
        currDate = currDate.add(1, "day")
      ) {
        let userAmount: number = 0;
        if (type === "available")
          userAmount = getAvailableUser(currDate).length;
        else if (type === "absent")
          userAmount = getAbsentUsersIds(currDate).length;
        targetArray.push(
          <p
            className={`project-planning__header-wrapper__header__date ${
              currDate.day() === 0 || currDate.day() === 6 ? "weekend-row" : ""
            }`}
          >{`${
            type === "available"
              ? translations.availableUserHeader
              : translations.absentUserHeader
          } ${userAmount}`}</p>
        );
      }
      return targetArray;
    },
    [endDate, getAbsentUsersIds, getAvailableUser, startDate, translations]
  );

  /**
   * Generates the actual entries of the available or absent users
   */
  const availableUserElements = useCallback(
    (type: "available" | "absent"): JSX.Element[] => {
      let targetArray: JSX.Element[] = [
        <div className={"project-planning__entry-row__cell"} />,
      ];
      for (
        let currDate = startDate;
        currDate.isBefore(endDate) || currDate.isSame(endDate);
        currDate = currDate.add(1, "day")
      ) {
        const availableUsers: SimpleUser[] = getAvailableUser(currDate);
        targetArray = targetArray.concat(
          <div
            className={`project-planning__entry-row__cell ${
              currDate.day() === 0 || currDate.day() === 6 ? "weekend-row" : ""
            }`}
          >
            {type === "available"
              ? availableUsers
                  .sort((user1, user2) =>
                    user1.lastName.localeCompare(user2.lastName)
                  )
                  .map((user) => (
                    <div
                      className={`project-planning__entry-row__swimlane__days__entry__name passive ${user.contractInformation.employmentType}`}
                    >
                      <p>
                        {`${user.lastName}, ${user.firstName.slice(0, 1)}.`}
                      </p>
                      <div
                        className={`project-planning__entry-row__employment-marker ${user.contractInformation.employmentType}`}
                      >
                        <div
                          className="project-planning__entry-row__employment-marker__pie-chart"
                          style={{
                            backgroundImage: `conic-gradient(transparent ${
                              (user.contractInformation.weeklyWorkingTime /
                                40) *
                              100
                            }%, white ${
                              (user.contractInformation.weeklyWorkingTime /
                                40) *
                              100
                            }%)`,
                          }}
                        />
                      </div>
                    </div>
                  ))
              : users
                  .filter(
                    (user) =>
                      !availableUsers.includes(user) &&
                      !getScheduledUserIds(currDate).includes(user.id)
                  )
                  .map((user) => (
                    <div className="project-planning__entry-row__swimlane__days__entry__name passive">
                      <p>{`${user.lastName} ${user.firstName.slice(0, 1)}`} </p>
                      <div
                        className={`project-planning__employment-marker ${user.contractInformation.employmentType}`}
                      >
                        <div
                          className="project-planning__entry-row__employment-marker__pie-chart"
                          style={{
                            backgroundImage: `conic-gradient(transparent ${
                              (user.contractInformation.weeklyWorkingTime /
                                40) *
                              100
                            }%, white ${
                              (user.contractInformation.weeklyWorkingTime /
                                40) *
                              100
                            }%)`,
                          }}
                        />
                      </div>
                    </div>
                  ))}
          </div>
        );
      }
      return targetArray;
    },
    [endDate, getAvailableUser, getScheduledUserIds, startDate, users]
  );

  return (
    <div>
      <div className="project-planning__header-wrapper">
        <div className="project-planning__header-wrapper__header">
          {headerElements("available")}
        </div>
      </div>
      <div className="project-planning__entry-row">
        {availableUserElements("available")}
      </div>
      <div className="project-planning__header-wrapper">
        <div className="project-planning__header-wrapper__header">
          {headerElements("absent")}
        </div>
      </div>
      <div className="project-planning__entry-row">
        {availableUserElements("absent")}
      </div>
    </div>
  );
};
