import { AxiosInstance } from "axios";
import { UserFunction } from "../user/User.types";
import { generateNotification, mapReplacer } from "..";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";

/**
 * API Method to get all UserFunctions
 * @param axios instance of axios
 * @returns  array of all found entries
 */
export const getAllUserFunctions = async (
  axios: AxiosInstance
): Promise<UserFunction[]> => {
  return axios
    .get("/user/function/all")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllUserFunction"),
      });
      console.error("Error while loading userFunction", exc);
      return [];
    });
};

/**
 * API Method to create a new userfunction
 * @param axios instance of axios
 * @param entry  to persist on the database
 * @returns  created object or undefined on error
 */
export const createNewUserFunction = async (
  axios: AxiosInstance,
  entry: UserFunction
): Promise<UserFunction | undefined> => {
  return axios
    .post("/user/function/", JSON.stringify(entry, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewUserFunction"),
      });
      console.error("Error while saving userFunctionEntry", exc);
      return undefined;
    });
};

/**
 * API Method to update a userFunctionEntry
 * @param axios instance of axios
 * @param entry updated entry to save
 * @returns updated object or undefined on error
 */
export const updateUserFunction = async (
  axios: AxiosInstance,
  entry: UserFunction
): Promise<UserFunction | undefined> => {
  return axios
    .post("/user/function/update", JSON.stringify(entry, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateUserFunction"),
      });
      console.error("Error while updating entry", exc);
      return undefined;
    });
};
