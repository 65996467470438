import { TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import { WorkQualification } from "shared";
import { Language } from "shared/src/Language/Language.types";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";

/**
 * Util method to convert workQualifications into tableEntries
 * @param qualifications to display
 * @param navigate navigateFunction
 * @returns Array of TableRows
 */
export const convertQualificationsIntoTableRows = (
  qualifications: WorkQualification[],
  navigate: NavigateFunction
): TableRow[] => {
  return qualifications.map((quali) => ({
    id: quali.id,
    onClick: () =>
      navigate("/qualifications/edit", {
        state: { qualification: quali },
      }),
    content: [
      quali.name,
      quali.createDate.toLocaleDateString("DE-de"),
      quali.disabled
        ? i18n.t("pages.qualifications.edit.deactivated")
        : i18n.t("pages.qualifications.edit.active"),
      <div>
        <EditIcon
          title={i18n.t("general.icons.edit")}
          width={20}
          onClick={() =>
            navigate("/qualifications/edit", {
              state: { qualification: quali },
            })
          }
        />
      </div>,
    ],
  }));
};

/**
 * Util method to generate a new workQualification
 * @param override override setter to adjust the created object
 * @returns generated workQualification
 */
export const createNewQualification = (
  override?: Partial<WorkQualification>
): WorkQualification => ({
  createDate: new Date(),
  createdBy: "",
  disabled: true,
  id: undefined!,
  lastUpdate: new Date(),
  name: "",
  userFunctionIds: [],
  updatedBy: "",
  names: new Map<Language, string>(),
  ...override,
});
