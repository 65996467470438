import { TableRow, Toggle } from "@sam/components";
import { Dispatch, SetStateAction } from "react";
import { Country, County, HolidayResponse, OfficialHoliday } from "shared";

/**
 * Helper function to get correct county code from code
 * @param code provided from openHolidayAPI
 * @returns correct County code
 */
export const getCorrectCounty = (code: string): County => {
  switch (code) {
    case "DE-BY":
      return County.BAVARIA;
    case "DE_TH":
      return County.THURINGIA;
    case "DE-SN":
      return County.SAXONY;
    default:
      return County.OTHER;
  }
};

/**
 * Helper method to convert HolidayResponses to OfficialHolidays
 * @param holidays to convert
 * @param userId that created the OfficialResponse
 * @returns array of officialHolidays
 */
export const convertResponseToOfficialHoliday = (
  responses: HolidayResponse[],
  country: Country,
  userId: string
): OfficialHoliday[] => {
  const mappedArray: OfficialHoliday[] = [];
  responses.forEach((response) => {
    const mappedObject: OfficialHoliday = {
      id: undefined!,
      createDate: new Date(),
      createdBy: userId,
      payment150: false,
      date: new Date(response.startDate),
      name: response.name[0].text,
      comment:
        response.comment && response.comment.length > 0
          ? response.comment[0].text
          : "",
      country,
      offices: [],
      nationWide: response.nationwide || false,
      county:
        response.subdivisions?.map((division) =>
          getCorrectCounty(division.code)
        ) || [],
    };
    mappedArray.push(mappedObject);
  });

  return mappedArray;
};

/**
 * Util method to convert OfficialHolidays into TableEntries
 * @param officialHolidays to convert
 * @param setOfficialHolidays zsed for the toggle of 150%payment
 * @returns array of TableRows
 */
export const convertOfficialHolidaysIntoTableEntries = (
  officialHolidays: OfficialHoliday[],
  setOfficialHolidays: Dispatch<SetStateAction<OfficialHoliday[]>>
): TableRow[] => {
  return officialHolidays.map((holiday) => ({
    id: holiday.id,
    content: [
      <Toggle
        active={holiday.payment150}
        onToggle={(payment150) =>
          setOfficialHolidays(
            officialHolidays.map((officialHoliday) =>
              officialHoliday.id === holiday.id
                ? { ...officialHoliday, payment150 }
                : officialHoliday
            )
          )
        }
      />,
      holiday.date.toLocaleDateString(),
      holiday.name,
    ],
  }));
};

/**
 * Util method to filter officialHolidays for different years, countries and counties
 * @param officialHolidays to filter
 * @param year optional filter for the year of the holidays
 * @param country optional filter
 * @param county optional filter
 * @returns array of filtered holidays
 * @tested
 */
export const filterOfficialHolidays = (
  officialHolidays: OfficialHoliday[],
  year?: string | number,
  country?: Country,
  county?: County
): OfficialHoliday[] => {
  let filteredHolidays: OfficialHoliday[] = officialHolidays;
  if (year)
    filteredHolidays = filteredHolidays.filter(
      (holiday) => holiday.date.getFullYear().toString() === year.toString()
    );
  if (country)
    filteredHolidays = filteredHolidays.filter(
      (holiday) => holiday.country === country
    );
  if (county)
    filteredHolidays = filteredHolidays.filter(
      (holiday) => holiday.county.includes(county) || holiday.nationWide
    );
  return filteredHolidays;
};
