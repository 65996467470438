import { Box, Button, Dropdown, Input, TopBar } from "@sam/components";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Country,
  PaymentCondition,
  createNewPaymentCondition,
  generateNotification,
  updatePaymentConditions,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { ReactComponent as ArrowLeft } from "../../assets/chevron-left.svg";
import { ReactComponent as SaveIcon } from "../../assets/save.svg";
import { useUser } from "../../components/UserContext";
import { generateEmptyPaymentCondition } from "../../utils/invoice/Invoice.utils";
import { generateDropdownOptionsForCountry } from "../../utils/timeTracking/TimeTracking.utils";

export const PaymentConditionEdit: React.FC = () => {
  const location = useLocation<{ paymentCondition?: PaymentCondition }>();
  const navigate = useNavigate();
  const { axios } = useUser();
  const [conditionToEdit, setConditionToEdit] = useState<PaymentCondition>(
    location.state?.paymentCondition
      ? location.state.paymentCondition
      : generateEmptyPaymentCondition()
  );
  const { t } = useTranslation();

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  /**
   * Helper method to update or create a new paymentCondition
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    if (!conditionToEdit.id)
      createNewPaymentCondition(axios, conditionToEdit).then(
        (createdPaymentCondition) => {
          if (createdPaymentCondition && redirect) navigate("/payment");
          else if (createdPaymentCondition) {
            setConditionToEdit(createdPaymentCondition);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.saveSuccessfull"),
            });
          }
        }
      );
    else
      updatePaymentConditions(axios, conditionToEdit).then(
        (updatedCondition) => {
          if (updatedCondition && redirect) navigate("/payment");
          else if (updatedCondition) {
            setConditionToEdit(updatedCondition);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.saveSuccessfull"),
            });
          }
        }
      );
  };
  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          location.state?.paymentCondition
            ? t("pages.paymentCondition.edit.topBarHeadlineEdit")
            : t("pages.paymentCondition.edit.topBarHeadlineCreate")
        }
      >
        <Button
          value={
            <div className="save-and-navigate__wrapper">
              <ArrowLeft className="save-and-navigate__arrow" />
              <SaveIcon className="save-and-navigate__save" />
            </div>
          }
          type="button"
          onClick={() => handleSubmit(true)}
        />
        <Button
          value={<SaveIcon className="save-icon" />}
          type="button"
          onClick={() => handleSubmit(false)}
        />
      </TopBar>
      <Box title={t("pages.paymentCondition.edit.boxTitle")}>
        <div className="three-columns">
          <Input
            type="text"
            value={conditionToEdit.description}
            onChange={(description) =>
              setConditionToEdit({ ...conditionToEdit, description })
            }
            label={t("pages.paymentCondition.edit.description")}
          />
          <Input
            type="text"
            value={conditionToEdit.identifier}
            onChange={(identifier) =>
              setConditionToEdit({ ...conditionToEdit, identifier })
            }
            label={t("pages.paymentCondition.edit.identifier")}
          />
          <Input
            type="number"
            value={conditionToEdit.daysToPay}
            onChangeNumber={(daysToPay) =>
              setConditionToEdit({ ...conditionToEdit, daysToPay })
            }
            label={t("pages.paymentCondition.edit.daysToPay")}
          />
          <Input
            type="number"
            value={conditionToEdit.daysToPayDiscount}
            onChangeNumber={(daysToPayDiscount) =>
              setConditionToEdit({ ...conditionToEdit, daysToPayDiscount })
            }
            label={t("pages.paymentCondition.edit.daysToPayDiscount")}
          />
          <Input
            type="number"
            value={conditionToEdit.discountPercentage}
            onChangeNumber={(discountPercentage) =>
              setConditionToEdit({ ...conditionToEdit, discountPercentage })
            }
            label={t("pages.paymentCondition.edit.discountPercentage")}
          />
          <Dropdown
            options={generateDropdownOptionsForCountry()}
            selectedOption={conditionToEdit.country}
            onChange={(country) =>
              setConditionToEdit({
                ...conditionToEdit,
                country: country as Country,
              })
            }
            label={t("pages.paymentCondition.edit.country")}
          />
        </div>
      </Box>
    </form>
  );
};
