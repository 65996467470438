import { Dispatch, SetStateAction } from "react";
import { TimeTracking } from "shared";
import {
  convertNumberOfMinutesToString,
  generateDropdownOptionsForYear,
} from "../../utils/timeTracking/TimeTracking.utils";
import i18n from "../../i18n/i18n";
import { Dropdown } from "@sam/components";
import { ReactComponent as FilterIcon } from "../../assets/filter_list.svg";

export const GenerateMonthlyOverviewBoxes: React.FC<{
  timeTrackings: TimeTracking[];
  selectedMonthAndYear: [number, number];
  setSelectedMonthAndYear: Dispatch<SetStateAction<[number, number]>>;
}> = ({ timeTrackings, selectedMonthAndYear, setSelectedMonthAndYear }) => {
  const boxes: JSX.Element[] = [];
  const monthNow: number = new Date().getMonth();

  for (let curMonth = 0; curMonth <= 11; curMonth++) {
    const trackingsCurrentMonth: TimeTracking[] = timeTrackings.filter(
      (tracking) => {
        const startDate: Date = tracking.startDate;
        return (
          startDate.getMonth() === curMonth &&
          startDate.getFullYear() === selectedMonthAndYear[1]
        );
      }
    );
    let totalWorkTime: number = 0;
    trackingsCurrentMonth.forEach(
      (tracking) => (totalWorkTime += tracking.totalWorkTime)
    );

    boxes.push(
      <div
        onClick={() =>
          setSelectedMonthAndYear([curMonth, selectedMonthAndYear[1]])
        }
        className={[
          "timetracking-overview-monthly__boxes",
          monthNow >= curMonth ? "past-month__box" : "future-month__box",
          selectedMonthAndYear[0] === curMonth ? "active-month__box" : "",
        ].join(" ")}
      >
        <div
          className={[
            "timetracking-overview-monthly__boxes__month",
            monthNow >= curMonth ? "past-month" : "future-month",
            selectedMonthAndYear[0] == curMonth ? "active-month" : "",
          ].join(" ")}
        >
          <p
            className={"timetracking-overview-monthly__boxes__month-shortname"}
          >
            {i18n.t(`pages.timekeeping.months.${curMonth}`)}
          </p>
        </div>
        <p className="timetracking-overview-monthly__boxes__total-worktime">
          {convertNumberOfMinutesToString(totalWorkTime)}
        </p>
      </div>
    );
  }
  return (
    <div className="timetracking-overview-monthly__wrapper">
      <p className="timetracking-overview-monthly__headline">
        {i18n.t("pages.timekeeping.boxesHeadline")}
      </p>
      <div className="timetracking-overview-monthly__options">
        <FilterIcon width={30} />
        <Dropdown
          onChange={(year) =>
            setSelectedMonthAndYear([selectedMonthAndYear[0], Number(year)])
          }
          selectedOption={selectedMonthAndYear[1].toString()}
          options={generateDropdownOptionsForYear()}
        />
      </div>
      <div className="timetracking-overview-monthly__boxes-container">
        {timeTrackings && boxes}
      </div>
    </div>
  );
};
