import { Box, Dropdown, Input, Option } from "@sam/components";
import { useEffect, useState } from "react";
import dayjs from "shared/src/tools/Dayjs";
import { ReactComponent as UpArrow } from "../../assets/up_arrow.svg";
import { FilterComponentProps } from "./FilterComponent.types";

const keysToExclude: string[] = [
  "id",
  "createDate",
  "createdBy",
  "lastUpdated",
  "updatedBy",
];
const FilterComponent = <T extends { [x: string]: unknown }>({
  entries,
  setFilteredEntries,
}: FilterComponentProps<T>) => {
  const [filterValues, setFilterValues] = useState<Map<keyof T, string>>(
    new Map<keyof T, string>()
  );

  const [isOpen, toggleOpen] = useState<boolean>(false);

  /**
   * Hepler method to get the correct type of a value
   * @param value  to get the time for
   * @returns string containing the type
   */
  const getCorrectType = (value: unknown): string => {
    const mailRegex = new RegExp(
      "/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+.[a-zA-Z0-9_-]+)/gi"
    );

    // detect if value is defined. An empty string is enough to decide it is a string and therefore not undefined
    if (value !== "" && !value) return "undefined";
    const isEmail: boolean = typeof value === "string" && mailRegex.test(value);

    switch (true) {
      case isEmail:
        return "email";
      case value instanceof Date:
        return "date";
      case value instanceof Array:
        return "array";
      default:
        return typeof value;
    }
  };

  /**
   * Helper method to check if an array contains all entries of another entry
   * @param items  to check
   * @param filter entries that should be contained in the other array
   * @returns boolean if the aray contains all
   */
  const arrayContainsAllFilters = (
    items: string[],
    filter: string[]
  ): boolean => {
    let result: boolean = true;
    filter.forEach((filterEntry) => {
      if (!items.includes(filterEntry)) result = false;
    });
    return result;
  };

  /**
   * useEffect hook to apply the selected filters and set the entries
   */
  useEffect(() => {
    let updatedEntries: T[] = [...entries];
    filterValues.forEach((v, k) => {
      updatedEntries = updatedEntries.filter((entry) => {
        const type: string = getCorrectType(entry[k]);
        switch (type) {
          case "string":
            return String(entry[k])
              .toLowerCase()
              .includes((v as string).toLowerCase());
          case "number":
            return entry[k] === Number(v);
          case "boolean":
            return String(entry[k] === v);
          case "date":
            return dayjs(String(entry[k])).isSame(new Date(v));
          case "array":
            return (
              entry[k] instanceof Array &&
              arrayContainsAllFilters(
                entry[k] as string[],
                String(v).split(",")
              )
            );
          default:
            return false;
        }
      });
    });
    setFilteredEntries(updatedEntries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries.length, filterValues]);

  const generatedInputs: JSX.Element[] = [];

  /**
   * Method to generate inputs for each key/value of the entries
   * @param entries to generate
   * @returns Array with filter inputs as JSX []
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const generateInputsForValues = (entries: (T | any)[]): JSX.Element[] => {
    if (entries.length === 0) return [];

    const updateFilterValue = (key: keyof T, value: string): void => {
      const updatedMap: Map<keyof T, string> = new Map<keyof T, string>(
        filterValues
      );
      updatedMap.set(key, value);
      setFilterValues(updatedMap);
    };

    Object.keys(entries[0]).forEach((key) => {
      if (String(key).includes("password") || keysToExclude.includes(key))
        return;
      //get the first entry of the array and get the value of the corrosponding key to determinate the type
      const objectValue: unknown = entries[0][key];
      const type: string = getCorrectType(objectValue);
      if (type == "undefined") return;

      const options: Option[] = [];
      entries.forEach((entry) =>
        entry[key] instanceof Array
          ? (entry[key] as Array<string>).forEach((entry) => {
              return (
                options.includes({ label: entry, value: entry }) ||
                options.push({ label: entry, value: entry })
              );
            })
          : null
      );

      switch (type) {
        case "string":
          generatedInputs.push(
            <Input
              type="text"
              value={filterValues.get(key)}
              label={String(key)}
              onChange={(value) => updateFilterValue(key, value)}
            />
          );
          break;
        case "email":
          generatedInputs.push(
            <Input
              onChange={(value) => updateFilterValue(key, value)}
              type="email"
              value={filterValues.get(key)}
              label={String(key)}
            />
          );
          break;
        case "number":
          generatedInputs.push(
            <Input
              type="number"
              onChangeNumber={(value) => updateFilterValue(key, String(value))}
              value={filterValues.get(key)}
              label={String(key)}
            />
          );
          break;
        case "date":
          generatedInputs.push(
            <Input
              type="date"
              onChangeDate={(value) =>
                value && updateFilterValue(key, value.toDateString())
              }
              label={String(key)}
              value={filterValues.get(key)}
            />
          );
          break;
        case "array":
          generatedInputs.push(
            <Dropdown
              multi={true}
              options={options}
              label={String(key)}
              onChangeMultiple={(value) =>
                updateFilterValue(key, value.toString())
              }
            />
          );
          break;
        case "object":
          entries[0][key] && generateInputsForValues([entries[0][key]]);
          break;
      }
    });
    return generatedInputs;
  };

  return (
    <Box
      title={
        <div className="filter-component__title-wrapper">
          <p className="filter-component__title-wrapper__title">Filter</p>
          <UpArrow
            className={`filter-component__title-wrapper__arrow-${
              isOpen ? "up" : "down"
            }`}
            onClick={() => toggleOpen((prev) => !prev)}
          />
        </div>
      }
    >
      {isOpen && (
        <div className="filter-component__input-wrapper">
          {generateInputsForValues(entries)}
        </div>
      )}
    </Box>
  );
};

export default FilterComponent;
