import { AxiosInstance } from "axios";
import i18n from "../../../client/src/i18n/i18n";
import { Language } from "../Language/Language.types";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import { Offer, OfferType } from "./Offer.types";
import { OfferMailConfiguration } from "../mail/Mail.types";

/**
 * API Method to create a new Offer
 * @param axios instance of axios
 * @param offer offer to create on the db
 * @returns  creted Offer or undefined on error
 */
export const createNewOffer = async (
  axios: AxiosInstance,
  offer: Offer
): Promise<Offer | undefined> => {
  return axios
    .post("/offer/", offer)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createOffer"),
      });
      console.error("Error while creating new Order", exc);
      return undefined;
    });
};

/**
 * API method to get a offer for a specific order
 * @param axios instance of axios
 * @param id orderId to load the offer for
 * @returns  found order or undefined if nothing was found
 */
export const getOfferByOrderId = async (
  axios: AxiosInstance,
  id: string
): Promise<Offer | undefined> => {
  return axios
    .get("/offer/order", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading offer", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadOfferOrderId"),
      });
      return undefined;
    });
};

/**
 * API Method to get a pdf with an offer
 * @param axios instance of axios
 * @param offerId id of the offer to create the document for
 * @param userId user that wants to create the document
 * @returns Blob with document data
 */
export const getOfferPDF = async (
  axios: AxiosInstance,
  offerId: string,
  userId: string,
  language: Language
): Promise<Blob | undefined> => {
  return axios
    .get("/export/pdf/offer", {
      params: {
        userId,
        offerId,
        language,
      },
      responseType: "blob",
    })
    .then((res) => new Blob([res.data], { type: "application/pdf" }))
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createOfferPDF"),
      });
      console.error("Error while creating document", exc);
      return undefined;
    });
};

/**
 * API Method to load all offers
 * @param axios instance of axios
 * @param type used to differentiate standard offers from customerProjects
 * @returns Array with found offers
 */
export const getAllOffers = async (
  axios: AxiosInstance,
  type?: OfferType
): Promise<Offer[]> => {
  return axios
    .get("/offer/all", { params: { type } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all offers", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadAllOffers"),
      });
      return [];
    });
};

/**   API Method to send a new offer to a customer
 * @param axios instance of axios
 * @param offerId id of the offer to send
 * @param language to create the document in
 * @param userId id of the user that creates the document
 * @param additionalInfoConfig determinates if info texts should be drawn on the offer
 * @returns boolean depending on success
 */
export const sendNewOffer = async (
  axios: AxiosInstance,
  mailConfig: OfferMailConfiguration
): Promise<boolean> => {
  return axios
    .post("/mail/offer", mailConfig)
    .then((res) => res.status === 200)
    .catch((exc) => {
      console.error("Error while sending offer", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.sendOffer"),
      });
      return false;
    });
};

/**
 * API Method to load an existing offer pdf
 * @param axios instance of axios
 * @param offerId id of the offer to load the document for
 * @returns Blob with document if found
 */
export const getExistingOfferDocument = async (
  axios: AxiosInstance,
  offerId: string
): Promise<Blob | undefined> => {
  return axios
    .get("/offer/document", { params: { offerId }, responseType: "blob" })
    .then((res) => new Blob([res.data], { type: "application/pdf" }))
    .catch((exc) => {
      console.error("Error while loading document", exc);
      return undefined;
    });
};

/**
 * API Mehtod to generate a copy of an exisiting offer
 * @param axios instance of axios
 * @param offerId id of the offer to copy
 * @returns Promise with the created Offer or undefined on error
 */
export const generateOfferCopy = async (
  axios: AxiosInstance,
  offerId: string
): Promise<Offer | undefined> => {
  return axios
    .post("/offer/copy", offerId, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while copying offer", exc);
      return undefined;
    });
};
