import i18n, { ResourceKey } from "i18next";
import { initReactI18next } from "react-i18next";
import BrowserLanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";

i18n
  // initialize i18next for react
  .use(initReactI18next)
  // automatically detect the user's language
  .use(BrowserLanguageDetector)
  .use(
    // we use a custom backend to only load the namespaces we need
    resourcesToBackend(
      (language: string, namespace: string): Promise<ResourceKey> =>
        // it will be dynamically imported by vite and bundled with a hash
        // so we dont have problems with caching on new releases
        import(`./${language}/${namespace}.json`)
    )
  )
  .init({
    ns: ["translation"],
    fallbackLng: "de",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
