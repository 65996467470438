import { AxiosInstance } from "axios";
import { DunningLevel, generateNotification, mapReplacer } from "shared";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";

/**
 * API method to get all dunnningLevels
 * @param axios instance of axios
 * @returns Promise with all found dunningLevels
 */
export const getAllDunningLevels = async (
  axios: AxiosInstance
): Promise<DunningLevel[]> => {
  return axios
    .get("/dunning/all")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllDunningLevels"),
      });
      console.error("Error hile loading dunningLevels", exc);
      return [];
    });
};

/**
 * API Methpd to create a new dunningLevel
 * @param axios instance of axios
 * @param dunningLevel to create
 * @returns Promise with created DunningLevel or undefined on error
 */
export const createNewDunningLevel = async (
  axios: AxiosInstance,
  dunningLevel: DunningLevel
): Promise<DunningLevel | undefined> => {
  return axios
    .post("/dunning/", JSON.stringify(dunningLevel, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while saving dunning level", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewDunningLevel"),
      });
      return undefined;
    });
};

/**
 * API Method to update a dunningLevel
 * @param axios instance of axios
 * @param dunningLevel updated dunnningLevel
 * @returns Promise with updated dunningLevel or undefined on Error
 */
export const updateDunningLevel = async (
  axios: AxiosInstance,
  dunningLevel: DunningLevel
): Promise<DunningLevel | undefined> => {
  return axios
    .post("/dunning/update", JSON.stringify(dunningLevel, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updating dunning level", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateDunningLevel"),
      });
      return undefined;
    });
};
