import { AxiosInstance } from "axios";
import { ChangeHistory } from "./ChangeHistory.types";

/**
 * API method to get all changeHistory entries
 * @param axios instance of axios
 * @returns Array of all found changes
 */
export const getAllChanges = async (
  axios: AxiosInstance
): Promise<ChangeHistory[]> => {
  return axios
    .get("/changes/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading changeHistory", exc);
      return [];
    });
};
