import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useData } from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { convertUserFunctionsIntoTableEntries } from "../../utils/user/User.utils";

export const UserFunctionOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: allUserFunctions } = useData("USER_FUNCTION_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * TableRows containing all customerFeedbackEntries
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertUserFunctionsIntoTableEntries(allUserFunctions, navigate),
    [allUserFunctions, navigate]
  );
  return (
    <>
      <TopBar title={t("pages.userFunction.overview.topBarHeadline")}>
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("edit")}
        />
      </TopBar>
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.customerFeedback.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
