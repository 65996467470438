import { AxiosInstance } from "axios";
import i18n from "../../../client/src/i18n/i18n";
import { Language } from "../Language/Language.types";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import { MonthlyReceipt } from "./MonthlyReceipt";

/**
 * API method to get exisisting receipts for a month, or create them if they are not existing yet
 * @param axios instance of axios
 * @param month to load. We add 1 because JS Months are 0 based and Java Months are 1 based
 * @param year to get the overview for
 * @param createdBy user that creates the receipts
 * @returns Array of monthlyReceipts
 */
export const getOrCreateMonthlyReceiptsForMonth = async (
  axios: AxiosInstance,
  month: number,
  year: number,
  createdBy: string
): Promise<MonthlyReceipt[]> => {
  month += 1;
  return axios
    .get("/user/receipt/", { params: { year, month, createdBy } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({ type: NotificationType.ERROR, value: "Error" });
      console.error("Error while loading monthlyReceipts", exc);
      return [];
    });
};

/**
 * API method to update multiple monthlyReceipts
 * @param axios instance of axios
 * @param receipts updated receipt objects
 * @returns Array of the updated receipts
 */
export const updateMultipleMonthlyReceipts = async (
  axios: AxiosInstance,
  receipts: MonthlyReceipt[]
): Promise<MonthlyReceipt[]> => {
  return axios
    .post("/user/receipt/update/multiple", receipts)
    .then((res) => {
      generateNotification({
        type: NotificationType.SUCCESS,
        value: i18n.t("general.notification.success.updateReceipts"),
      });
      return res.data;
    })
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateReceipts"),
      });
      console.error("Error while updating receipts", exc);
      return [];
    });
};

/**
 * API method to get a pdf Document with the monthlyOverviews for all users
 * @param axios instance of Axios
 * @param month to load the document for
 * @param year of the month to load the document
 * @param language of the document
 * @returns Blob that contains the document
 */
export const getOverviewForMonth = async (
  axios: AxiosInstance,
  month: number,
  year: number,
  language: Language
): Promise<Blob | undefined> => {
  return axios
    .get("/export/pdf/summary", {
      params: { month, year, language },
      responseType: "blob",
    })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadOverview"),
      });
      console.error("Error while creating file to download", exc);
      return undefined;
    });
};

/**
 * API method to get the lohnliste csv for the selected month and year
 * @param axios  instance of axios
 * @param month to load the document for
 * @param year  to load the document for
 * @param language to return the document in
 * @returns Blob of the csv file
 */
export const getLohnlisteCSVForMonth = async (
  axios: AxiosInstance,
  month: number,
  year: number,
  language: Language
): Promise<Blob | undefined> => {
  return axios
    .get("/export/csv/lohnliste", {
      params: { month, year, language },
    })
    .then((res) => new Blob([res.data], { type: "text/csv" }))
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadCSVLohnliste"),
      });
      console.error("Error while downloading the lohnliste csv", exc);
      return undefined;
    });
};
