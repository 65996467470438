import { Box, Input, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CrmAction,
  CrmManageType,
  CrmType,
  createNewCrmAction,
  createNewCrmType,
  generateNotification,
  updateCrmAction,
  updateCrmType,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { useUser } from "../../components/UserContext";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { generateTableRowForLanguageTranslation } from "../../utils/InputUtils";
import { generateEmptyManageType } from "../../utils/crm/Crm.utils";

export const CrmManageEdit: React.FC<{ type: CrmManageType }> = ({ type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios } = useUser();
  const location = useLocation<{ entry?: CrmAction | CrmType }>();
  const [entryToEdit, setEntryToEdit] = useState<CrmAction | CrmType>(
    location.state?.entry ? location.state.entry : generateEmptyManageType(type)
  );
  const isEdit: boolean = useMemo(() => !!entryToEdit.id, [entryToEdit.id]);
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  /**
   * Rows to show and edit the different translations
   */
  const languageNameRows: TableRow[] = useMemo(
    (): TableRow[] =>
      generateTableRowForLanguageTranslation(
        entryToEdit,
        "names",
        setEntryToEdit
      ),
    [entryToEdit]
  );
  /**
   * Submit handler to update or create a crm manage object
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    let backendCall: Promise<CrmAction | CrmType | undefined>;
    if (isEdit) {
      if (type === CrmManageType.ACTION)
        backendCall = updateCrmAction(entryToEdit, axios);
      else backendCall = updateCrmType(entryToEdit, axios);
    } else {
      if (type === CrmManageType.ACTION)
        backendCall = createNewCrmAction(entryToEdit, axios);
      else backendCall = createNewCrmType(entryToEdit, axios);
    }
    backendCall.then((resp) => {
      if (redirect && resp) navigate(-1);
      else if (resp) {
        generateNotification({
          value: t(`pages.crmManage.edit.create_${type}`),
          type: NotificationType.SUCCESS,
        });
      }
    });
  };
  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={t(
          `pages.crmManage.edit.topBarHeadline${
            isEdit ? "Edit" : "Create"
          }_${type}`
        )}
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>

      <Box>
        <div className="three-columns">
          <Input
            onChange={(title) => setEntryToEdit({ ...entryToEdit, title })}
            type="text"
            value={entryToEdit.title}
            label={t("pages.crmManage.edit.title")}
          />
          <Input
            disabled
            type="date"
            value={entryToEdit.createDate}
            onChangeDate={() => {}}
            label={t("pages.crmManage.edit.createDate")}
          />
          <Input
            disabled
            type="date"
            value={entryToEdit.lastUpdated}
            onChangeDate={() => {}}
            label={t("pages.crmManage.edit.lastUpdate")}
          />
        </div>
      </Box>
      <Box>
        <Table
          rows={languageNameRows}
          header={
            t("pages.crmManage.edit.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </form>
  );
};
