import { TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import {
  CrmAction,
  CrmActionEntry,
  CrmEntry,
  CrmManageType,
  CrmType,
  Customer,
  CustomerUser,
  Language,
} from "shared";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";

/**
 * Helper to quickly generate an empty CrmEntry
 *
 * @param override Optional parameter override
 * @returns The new instance
 */
export const generateEmptyCrmEntry = (
  override?: Partial<CrmEntry>
): CrmEntry => ({
  id: undefined!,
  createDate: undefined!,
  createdBy: undefined!,
  officeId: "",
  typeId: "",
  ...override,
});

/**
 * Helper to quickly generate an empty CrmActionEntry
 *
 * @param override Optional parameter override
 * @returns The new instance
 */
export const generateEmptyCrmActionEntry = (
  override?: Partial<CrmActionEntry>
): CrmActionEntry => ({
  id: undefined!,
  createDate: undefined!,
  createdBy: undefined!,
  actionId: "",
  crmEntryId: "",
  done: false,
  internalContact: [],
  ...override,
});

/**
 * Helper to quickly generate an empty CrmAction
 *
 * @param override Optional parameter override
 * @returns The new instance
 */
export const generateEmptyCrmAction = (
  override?: Partial<CrmAction>
): CrmAction => ({
  id: undefined!,
  createDate: undefined!,
  createdBy: undefined!,
  names: new Map<Language, string>(),
  title: "",
  ...override,
});

/**
 * Helper to quickly generate an empty CrmType
 *
 * @param override Optional parameter override
 * @returns The new instance
 */
export const generateEmptyCrmType = (override?: Partial<CrmType>): CrmType => ({
  id: undefined!,
  createDate: undefined!,
  createdBy: undefined!,
  names: new Map<Language, string>(),
  title: "",
  ...override,
});

/**
 * Helper to quickly create a new manage type. This method works as long as the
 * two objects are the same!
 *
 * @param type The type of manage item to create
 * @param override Optional override params
 * @returns The newly created CrmAction or CrmType
 */
export const generateEmptyManageType = (
  type: CrmManageType,
  override?: Partial<CrmType | CrmAction>
): CrmType | CrmAction => {
  if (type === CrmManageType.ACTION) return generateEmptyCrmAction(override);
  else return generateEmptyCrmType(override);
};

/**
 * Util method to convert either crm types or actions into tableRows. This works as
 * long as the two models are the same!
 *
 * @param entries to convert
 * @param navigate navigateFunction to handle edit click
 * @returns Array of TableRows
 */
export const convertCrmManageEntriesIntoTableEntries = (
  entries: CrmType[] | CrmAction[],
  navigate: NavigateFunction
): TableRow[] => {
  return entries.map((entry) => ({
    id: entry.id,
    onClick: () => navigate("edit", { state: { entry } }),
    content: [
      entry.title,
      entry.createDate.toLocaleDateString("de-DE") || "-",
      entry.lastUpdated?.toLocaleDateString("de-DE") || "-",
      <EditIcon
        title={i18n.t("general.icons.edit")}
        width={30}
        onClick={(evt) => {
          evt.stopPropagation();
          navigate("edit", { state: { entry } });
        }}
      />,
    ],
  }));
};

/**
 * Util method to convert crmEntries into overview table entries
 *
 * @param entries to convert
 * @param navigate navigateFunction to handle edit click
 * @returns Array of TableRows
 */
export const convertCrmEntriesIntoTableEntries = (
  entries: CrmEntry[],
  navigate: NavigateFunction,
  allCustomer: Customer[],
  allContacts: CustomerUser[]
): TableRow[] => {
  return entries.map((entry) => ({
    id: entry.id,
    onClick: () => navigate("edit", { state: { entry } }),
    content: [
      allCustomer.find((cus) => cus.id === entry.customerId)?.name ||
        entry.customerName ||
        "-",
      allContacts.find((cont) => cont.id === entry.contactId)?.lastName ||
        entry.contactName ||
        "-",
      entry.createDate.toLocaleDateString("de-DE") || "-",
      <EditIcon
        title={i18n.t("general.icons.edit")}
        width={30}
        onClick={(evt) => {
          evt.stopPropagation();
          navigate("edit", { state: { entry } });
        }}
      />,
    ],
  }));
};
