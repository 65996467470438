import { TimeTracking, generateNotification } from "shared";
import { generateEmptyTimeTracking } from "../timeTracking/TimeTracking.utils";
import { db } from "./db";
import { IndexedTimeTracking } from "./db.types";
import { NotificationType } from "shared/src/notification/notification.types";
import i18n from "../../i18n/i18n";

/**
 * Helper method to save tracking in indexedDB
 * @param tracking  to save
 * @returns boolean, if successful or not
 */
export const addTimeTrackingIndexedDB = async (
  tracking: TimeTracking
): Promise<boolean> => {
  try {
    await db.timeTracking.add({
      startDate: tracking.startDate,
      serverId: tracking.id,
      startTime: tracking.startTime,
      id: undefined!,
    });
    return true;
  } catch (error) {
    generateNotification({
      type: NotificationType.ERROR,
      value: i18n.t("general.notification.error.saveTimeTrackingToIndex"),
    });
    console.error("Error while saving to indexedDB");
    return false;
  }
};
/**
 * Helper Method to convert indexedTimeTracking to TimeTracking object
 * @param indexedTracking to convert
 * @param userId of the active user
 * @returns generated TimeTracking object
 */
export const convertIndexedTimeTracking = (
  indexedTracking: IndexedTimeTracking,
  userId: string
): TimeTracking => {
  return {
    ...generateEmptyTimeTracking(userId),
    id: indexedTracking.serverId,
    startDate: indexedTracking.startDate,
    startTime: indexedTracking.startTime,
  };
};

/**
 * Helper Method to get indexedTimeTracking from indexDb, if possible
 */
export const getIndexedTimeTracking = async (): Promise<
  IndexedTimeTracking | undefined
> => {
  const foundTracking: IndexedTimeTracking | undefined = await db.timeTracking
    .toCollection()
    .first();
  return foundTracking;
};

/**
 * Helper Method to counts entries on the indexedDb for timeTracking
 * @returns number of saved entries
 */
export const countDbTimeTrackingEntries = async (): Promise<number> => {
  return await db.timeTracking
    .toCollection()
    .count()
    .then((count) => count);
};
