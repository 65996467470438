import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useData } from "shared";
import { Right } from "shared/src/userRole/UserRole.types";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { NotAllowed } from "../../components/NotAllowed";
import { useUser } from "../../components/UserContext";
import { convertPdlIntoTableEntries } from "../../utils/pdl/Pdl.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

const PdlOverviewPage: React.FC = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState<string>("");

  const { data: allPdls } = useData("PDL_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Helper Method to convert pdls to tableRows
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] => convertPdlIntoTableEntries(user.right, allPdls, navigate),
    [allPdls, navigate, user.right]
  );

  return isUserAllowedToDo(user.right, Right.PDL_OVERVIEW) ? (
    <>
      <TopBar
        title={t("pages.pdl.overview.headline")}
        onSearch={setFilterValue}
      >
        {isUserAllowedToDo(user.right, Right.PDL_CREATE) && (
          <PlusIcon
            title={t("general.icons.add")}
            onClick={() => navigate("edit")}
          />
        )}
      </TopBar>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.pdl.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  ) : (
    <NotAllowed neccessaryRight={Right.PDL_OVERVIEW} />
  );
};
export default PdlOverviewPage;
