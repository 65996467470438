import type { i18n } from "i18next";
import { AxiosInstance } from "axios";
import dayjs from "./tools/Dayjs";
import { User } from "./user/User.types";

/**
 * Variables which comes from the app clients and passed to the shared package
 */
interface Environment {
  i18n: i18n;
  dayjs: typeof dayjs;
  axios?: AxiosInstance;
  user?: User; // TODO add correct user reference as type instead of object
  selectedOffices: string[]
}

let environment: Environment | undefined = undefined;

/**
 * Gets the currently loaded variables from the app clients
 *
 * @returns {Environment} The environment variables
 */
export const getEnvironment = (): Environment => {
  if (!environment) throw new Error("Environment not initialized");
  return environment;
};

/**
 * Initializes the environment variables
 *
 * @param env The environment variables
 */
export const initEnvironment = (env: Omit<Environment, "dayjs">): void => {
  environment = { ...env, dayjs };
};

/**
 * Update specific environment variables like the user or the axios instance
 *
 * @param env The environment variables to update
 */
export const updateEnvironment = (env: Partial<Environment>): void => {
  if (!environment) throw new Error("Environment not initialized");
  environment = { ...environment, ...env };
};
