import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Option,
  Popup,
  Table,
  TableRow,
  TextArea,
  TopBar,
} from "@sam/components";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";

import { TableHeader } from "@sam/components/src/Table/Table.types";
import {
  createNewOffer,
  createNewOrder,
  Customer,
  CustomerArticle,
  customerProjectConfig,
  generateDropdownOptions,
  generateDropdownOptionsForLanguage,
  generateNotification,
  getActiveCustomerArticlesForCustomer,
  Language,
  Offer,
  OfferProvidedBy,
  OfferType,
  Office,
  Order,
  PaymentCondition,
  Task,
  updateOrderAndOffer,
  useData,
} from "shared";
import { CustomerLocation } from "shared/src/customerLocation/CustomerLocation.types";
import { NotificationType } from "shared/src/notification/notification.types";
import { createNewProjectFromCustomerProject } from "shared/src/project/Project.axios";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { SoftLockedPopup } from "../../components/SoftLockedPopup";
import { useUser } from "../../components/UserContext";
import { generateDropdownOptionsForBusinessArea } from "../../utils/businessArea/BusinessArea.utils";
import {
  convertOfferedTasksIntoTableEntries,
  generateDropdownOptionsForCustomers,
  generateDropdownOptionsForCustomerUsers,
  generateEmptyOffer,
  generateEmptyOfferedTask,
  generateEmptyOrder,
} from "../../utils/order/Order.utils";

export const CustomerProjectCreateOrEdit: React.FC = () => {
  const navigate = useNavigate();
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);
  const { axios } = useUser();
  const location = useLocation<{ order?: Order; offer?: Offer }>();
  const { t } = useTranslation();

  const [customerProject, setCustomerProject] = useState<Offer>(
    location.state?.offer ??
      generateEmptyOffer({
        offerType: OfferType.CUSTOMER_PROJECT,
        providedBy: OfferProvidedBy.CUSTOMER_PROJECT,
      })
  );
  const [projectOrder, setProjectOrder] = useState<Order>(
    location.state?.order ??
      generateEmptyOrder({ offerType: OfferType.CUSTOMER_PROJECT })
  );
  const [offeredTaskIdToDelete, setOfferedTaskIdToDelete] = useState<string>();
  const [customerArticles, setCustomerArticles] = useState<CustomerArticle[]>(
    []
  );
  const [createConfig, setCreateConfig] = useState<customerProjectConfig>();

  //useEffect hook to get the customer specific articles
  useEffect(() => {
    if (!customerProject.customerId) return;
    getActiveCustomerArticlesForCustomer(
      axios,
      customerProject.customerId
    ).then(setCustomerArticles);
  }, [axios, customerProject.customerId]);

  const { data: loadedOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedCurrencys } = useData("CURRENCY_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedCompanies } = useData("COMPANY_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedBusinessAreas } = useData("BUSINESSAREA_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerLocations } = useData(
    "CUSTOMER_LOCATION_ALL_ACTIVE",
    {
      config: { fallbackData: [] },
    }
  );
  const { data: loadedCustomerUsers } = useData("CUSTOMER_USERS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedPaymentConditions } = useData("PAYMENT_CONDITION_ALL", {
    params: { fallbackData: [] },
  });

  const { data: loadedCustomerFeedbackEntries } = useData("FEEDBACK_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Holds the tasks and the customer specific articles as tasks
   */
  const availableTasks: Task[] = useMemo(
    (): Task[] =>
      customerArticles.map(
        (article): Task => ({
          id: article.id,
          amount: 1,
          annotation: article.annotation || "",
          description: article.description || "",
          identifier: article.partnumber || "",
          price: article.price || 1,
          standard: false,
          title: article.partnumber || "",
          translations: new Map(),
          unit: article.unit || "",
          disabled: false,
        })
      ),
    [customerArticles]
  );

  /**
   * Keeps rows with the customerFeedbackEntries
   */
  const feedbackRows: TableRow[] = useMemo((): TableRow[] => {
    if (!projectOrder) return [];
    return loadedCustomerFeedbackEntries.map((feedbackCategory) => ({
      id: feedbackCategory.id,
      content: [
        feedbackCategory.title,
        <Input
          minValue={1}
          maxValue={6}
          type="number"
          onChangeNumber={(value) => {
            const date: string = new Date().toISOString().split("T")[0];
            const newFeedbackMap: Map<string, Map<string, number>> = new Map<
              string,
              Map<string, number>
            >(projectOrder.customerFeedback);
            newFeedbackMap.get(date)?.set(date, value);
            setProjectOrder({
              ...projectOrder,
              customerFeedback: newFeedbackMap,
            });
          }}
          value={projectOrder.customerFeedback
            .get(new Date().toISOString().split("T")[0])
            ?.get(feedbackCategory.id)}
        />,
      ],
    }));
  }, [loadedCustomerFeedbackEntries, projectOrder]);

  //Holds generated TableRows out of offeredTasks
  const rows: TableRow[] = useMemo((): TableRow[] => {
    return convertOfferedTasksIntoTableEntries(
      customerProject.offeredTasks,
      availableTasks,
      projectOrder.officeId,
      projectOrder.currencyId,
      setOfferedTaskIdToDelete,
      setCustomerProject
    );
  }, [
    availableTasks,
    customerProject.offeredTasks,
    projectOrder.currencyId,
    projectOrder.officeId,
  ]);

  /**
   * Submit handler to create or update the order and offer
   * @param redirect decides if page should navigate to overview after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;
    if (customerProject.id) {
      updateOrderAndOffer(axios, customerProject, projectOrder).then(
        (updatedObjects) => {
          if (updatedObjects?.order && updatedObjects?.offer && redirect)
            navigate("/customer/project");
          else if (updatedObjects) {
            setCustomerProject(updatedObjects.offer);
            setProjectOrder(updatedObjects.order);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.offerUpdate"),
            });
          }
        }
      );
    } else {
      createNewOrder(axios, projectOrder).then((createdOrder) => {
        if (!createdOrder) return;
        createNewOffer(axios, {
          ...customerProject,
          orderId: createdOrder.id,
        }).then((offer) => {
          if (offer && redirect) navigate("/customer/project");
          else if (offer) {
            setCustomerProject(offer);
            setProjectOrder(createdOrder);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.offerCreate"),
            });
          }
        });
      });
    }
  };

  const [softLockedCustomer, setSoftLockedCustomer] = useState<Customer>();

  /**
   * Helper to update all relevant locations after selecting the central customer
   *
   * @param customerId The selected customer id
   */
  const handleCentralCustomerSelection = (customerId: string): void => {
    const foundCustomer: Customer | undefined = loadedCustomers.find(
      (cust) => cust.id === customerId
    );
    if (foundCustomer?.softLocked) {
      setSoftLockedCustomer(foundCustomer);
    }
    if (!foundCustomer) return;
    setProjectOrder({
      ...projectOrder,
      customerId,
      workingLocation: {
        customerId,
        customerLocationId: "",
        customerContactId: "",
      },
      invoiceRecipient: {
        customerUserId: "",
        customerId,
      },
      paymentCondition: foundCustomer.paymentCondition,
    });
    setCustomerProject({
      ...customerProject,
      customerId,
    });
  };
  /**
   * Helper to fill some fields in respect of the given office id
   *
   * @param officeId The selected id of the office
   */
  const handleOfficeSelection = (officeId: string): void => {
    const selectedOffice: Office | undefined = loadedOffices.find(
      (office) => office.id === officeId
    );
    if (!selectedOffice) return;
    setProjectOrder({
      ...projectOrder,
      officeId,
      companyId: selectedOffice.companyId,
    });
  };

  /*
   * Helper method to find the selected paymentCondition and update the offer
   * @param id of the selected paymentCondition
   */
  const handlePaymentConditionChange = (id: string): void => {
    const condition: PaymentCondition | undefined =
      loadedPaymentConditions?.find((condition) => condition.id === id);
    if (condition)
      setProjectOrder({ ...projectOrder, paymentCondition: condition });
  };

  /**
   * DropdownOptions for the businessAreas
   */
  const businessAreaOptions: Option[] = useMemo(
    (): Option[] =>
      generateDropdownOptionsForBusinessArea(
        loadedBusinessAreas.filter(
          (area) => area.companyId === projectOrder.companyId
        )
      ),
    [loadedBusinessAreas, projectOrder.companyId]
  );

  /**
   * location of the customer that is selected for the project
   */
  const customerLocations: CustomerLocation[] = useMemo(
    (): CustomerLocation[] =>
      loadedCustomerLocations.filter(
        (location) => location.customerId === customerProject.customerId
      ),
    [customerProject.customerId, loadedCustomerLocations]
  );

  /**
   * Helper method to create a new project for a custoimerProject
   */
  const handleCreateProject = (): void => {
    createConfig &&
      createNewProjectFromCustomerProject(axios, createConfig).then(
        (createdProject) =>
          createdProject &&
          navigate("/project/edit", { state: { project: createdProject } })
      );
  };

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar onBackClick={() => navigate(-1)} title="Projekt-Bestellung">
        {customerProject.id && (
          <Button
            value={t("general.buttons.createCustomerProject")}
            onClick={() =>
              setCreateConfig({
                offerId: customerProject.id,
                customerOrderNumber: "",
                plannedWorkers: 1,
                tasks: [],
              })
            }
          />
        )}
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      {createConfig && (
        <Popup
          buttons={[
            <Button
              value={t("general.buttons.cancel")}
              onClick={() => setCreateConfig(undefined)}
            />,
            <Button
              value={t("general.buttons.create")}
              onClick={handleCreateProject}
            />,
          ]}
          isOpen={!!createConfig}
          onClose={() => setCreateConfig(undefined)}
        >
          <div>
            <Input
              type="text"
              value={createConfig?.customerOrderNumber}
              label={t(
                "pages.customerProject.edit.createPopup.customerOrderNumber"
              )}
              onChange={(customerOrderNumber) =>
                setCreateConfig({ ...createConfig, customerOrderNumber })
              }
            />
            <Input
              type="number"
              onChangeNumber={(plannedWorkers) =>
                setCreateConfig({ ...createConfig, plannedWorkers })
              }
              value={createConfig.plannedWorkers}
              label={t("pages.customerProject.edit.createPopup.plannedWorkers")}
            />
          </div>
          <Table
            header={
              t("pages.customerProject.edit.createPopup.tableHeader", {
                returnObjects: true,
              }) as TableHeader[]
            }
            rows={customerProject.offeredTasks.map((task) => ({
              id: task.id,
              content: [
                <Checkbox
                  isChecked={createConfig.tasks.some(
                    (includedTask) => includedTask.id === task.id
                  )}
                  onCheck={() =>
                    createConfig.tasks.some(
                      (includedTask) => includedTask.id === task.id
                    )
                      ? setCreateConfig({
                          ...createConfig,
                          tasks: createConfig.tasks.filter(
                            (filterTask) => filterTask.id !== task.id
                          ),
                        })
                      : setCreateConfig({
                          ...createConfig,
                          tasks: [...createConfig.tasks, task],
                        })
                  }
                />,
                task.task.identifier,
                task.task.description,
              ],
            }))}
          />
        </Popup>
      )}
      <SoftLockedPopup
        customer={softLockedCustomer}
        closePopup={() => setSoftLockedCustomer(undefined)}
      />
      <Popup
        isOpen={!!offeredTaskIdToDelete}
        onClose={() => setOfferedTaskIdToDelete(undefined)}
        title={t("pages.order.create.deleteTaskPopup")}
        buttons={[
          <Button
            backgroundColor="#BC2E46"
            value={t("general.buttons.yes")}
            onClick={() => {
              setCustomerProject((prev) => ({
                ...prev,
                offeredTasks: customerProject.offeredTasks.filter(
                  (task) => task.id !== offeredTaskIdToDelete
                ),
              }));
              setOfferedTaskIdToDelete(undefined);
            }}
          />,
          <Button
            value={t("general.buttons.no")}
            onClick={() => setOfferedTaskIdToDelete(undefined)}
          />,
        ]}
      >
        <p>{t("pages.order.create.deleteTask")}</p>
      </Popup>
      <Box>
        <Input
          onChange={() => {}}
          type="text"
          disabled
          value={customerProject.numberRangeNumber || "-"}
          label={t("pages.order.create.numberRange")}
        />
        <fieldset className="three-columns">
          <legend>{t("pages.order.create.generalInformation")}</legend>
          <Dropdown
            selectedOption={projectOrder.officeId}
            onChange={handleOfficeSelection}
            label={t("pages.order.create.office")}
            options={loadedOffices.map((office) => ({
              label: office.name,
              value: office.id,
            }))}
          />
          <Dropdown
            label={t("pages.order.create.currency")}
            selectedOption={projectOrder.currencyId}
            onChange={(currencyId) =>
              setProjectOrder({ ...projectOrder, currencyId })
            }
            options={generateDropdownOptions(loadedCurrencys, "title", "id")}
          />
          <Dropdown
            options={[
              {
                label: t(
                  `pages.order.create.providedBy.${OfferProvidedBy.CUSTOMER_PROJECT}`
                ),
                value: OfferProvidedBy.CUSTOMER_PROJECT,
              },
            ]}
            disabled
            label={t("pages.order.create.providedBy.label")}
            selectedOption={OfferProvidedBy.CUSTOMER_PROJECT}
            onChange={() => {}}
          />
          <Dropdown
            label={t("pages.order.create.language")}
            selectedOption={customerProject.language}
            onChange={(language) =>
              setCustomerProject({
                ...customerProject,
                language: language as Language,
              })
            }
            options={generateDropdownOptionsForLanguage()}
          />
          <Dropdown
            options={generateDropdownOptions(loadedCompanies, "name", "id")}
            selectedOption={projectOrder.companyId}
            onChange={(companyId) =>
              setProjectOrder({ ...projectOrder, companyId })
            }
            label={t("pages.order.create.company")}
          />
          <Dropdown
            onChange={(businessAreaId) =>
              setProjectOrder({
                ...projectOrder,
                businessAreaId,
              })
            }
            options={businessAreaOptions}
            label={t("pages.order.create.businessArea")}
            selectedOption={projectOrder.businessAreaId}
          />
        </fieldset>
      </Box>
      <Box>
        <fieldset className="three-columns">
          <legend>{t("pages.order.create.customerInformation")}</legend>
          <div>
            <Dropdown
              required
              onChange={handleCentralCustomerSelection}
              selectedOption={projectOrder.customerId}
              options={generateDropdownOptionsForCustomers(loadedCustomers)}
              label={t("pages.order.create.customer")}
            />
            <Dropdown
              required
              onChange={(locationId) => {
                setProjectOrder({ ...projectOrder, locationId });
              }}
              selectedOption={projectOrder.locationId}
              options={generateDropdownOptions(customerLocations, "name", "id")}
              label={t("pages.order.create.customerLocation")}
            />
            <Dropdown
              required
              label={t("pages.order.create.workingLocationCustomer")}
              options={generateDropdownOptions(loadedCustomers, "name", "id")}
              onChange={(customerId) =>
                setProjectOrder({
                  ...projectOrder,
                  workingLocation: {
                    customerId,
                    customerLocationId: "",
                    customerContactId: "",
                  },
                })
              }
              selectedOption={projectOrder?.workingLocation?.customerId || ""}
            />
            <Dropdown
              required
              label={t("pages.order.create.workingLocation")}
              options={generateDropdownOptions(
                loadedCustomerLocations.filter(
                  (location) =>
                    location.customerId ===
                    projectOrder.workingLocation.customerId
                ),
                "name",
                "id"
              )}
              onChange={(customerLocationId) =>
                setProjectOrder({
                  ...projectOrder,
                  workingLocation: {
                    ...projectOrder.workingLocation,
                    customerLocationId,
                  },
                })
              }
              selectedOption={
                projectOrder?.workingLocation?.customerLocationId || ""
              }
            />
            <Dropdown
              required
              options={generateDropdownOptions(
                loadedCustomerUsers.filter(
                  (custoemrUser) =>
                    custoemrUser.customerId ===
                    projectOrder.workingLocation.customerId
                ),
                ["firstName", "lastName"],
                "id"
              )}
              label={t("pages.order.create.workingLocationContact")}
              onChange={(selectedUserId) =>
                setProjectOrder({
                  ...projectOrder,
                  workingLocation: {
                    ...projectOrder.workingLocation,
                    customerContactId: selectedUserId,
                  },
                })
              }
              selectedOption={projectOrder.workingLocation.customerContactId}
            />
          </div>
          <div>
            <Dropdown
              required
              selectedOption={projectOrder.customerContact}
              options={generateDropdownOptions(
                loadedCustomerUsers.filter(
                  (customerUser) =>
                    customerUser.customerId === projectOrder.customerId
                ),
                ["firstName", "lastName"],
                "id"
              )}
              label={t("pages.order.create.contactPerson")}
              onChange={(customerContact) =>
                setProjectOrder({ ...projectOrder, customerContact })
              }
            />
            <Dropdown
              required
              options={generateDropdownOptions(
                loadedPaymentConditions ?? [],
                "description",
                "id"
              )}
              selectedOption={projectOrder.paymentCondition?.id}
              label={t("pages.order.create.paymentCondition")}
              onChange={handlePaymentConditionChange}
            />
            <TextArea
              label={t("pages.order.create.workingLocationInformation")}
              onChange={(workingLocationComment) =>
                setProjectOrder({ ...projectOrder, workingLocationComment })
              }
              value={projectOrder.workingLocationComment}
              resizable
            />
          </div>
          <div>
            <Dropdown
              selectedOption={projectOrder.additionalReceivers?.[0] || ""}
              options={generateDropdownOptions(
                loadedCustomerUsers.filter(
                  (customerUser) =>
                    customerUser.customerId === projectOrder.customerId
                ),
                ["firstName", "lastName"],
                "id"
              )}
              label={t("pages.order.create.addReceiver")}
              onChangeMultiple={(additionalReceivers) =>
                setProjectOrder({ ...projectOrder, additionalReceivers })
              }
              multi
              create
              onCreateNew={(newReceiver) =>
                setProjectOrder({
                  ...projectOrder,
                  additionalReceivers: [
                    ...(projectOrder.additionalReceivers
                      ? [...projectOrder.additionalReceivers, newReceiver.value]
                      : newReceiver.value),
                  ],
                })
              }
            />
            <Dropdown
              required
              selectedOption={projectOrder.invoiceRecipient?.customerId}
              label={t("pages.order.create.invoiceRecipient")}
              options={generateDropdownOptions(loadedCustomers, "name", "id")}
              onChange={(customerId) =>
                setProjectOrder({
                  ...projectOrder,
                  invoiceRecipient: {
                    customerUserId: "",
                    customerId,
                  },
                })
              }
            />
            <Dropdown
              required
              disabled={!projectOrder.invoiceRecipient?.customerId}
              selectedOption={projectOrder.invoiceRecipient?.customerUserId}
              label={t("pages.order.create.invoiceContact")}
              options={generateDropdownOptionsForCustomerUsers(
                loadedCustomerUsers,
                projectOrder.invoiceRecipient.customerId
              )}
              onChange={(customerUserId) => {
                if (projectOrder.invoiceRecipient)
                  setProjectOrder({
                    ...projectOrder,
                    invoiceRecipient: {
                      ...projectOrder.invoiceRecipient,
                      customerUserId,
                    },
                  });
              }}
            />
            <Checkbox
              isChecked={!projectOrder.withoutInvoice}
              onCheck={() =>
                setProjectOrder({
                  ...projectOrder,
                  withoutInvoice: !projectOrder.withoutInvoice,
                })
              }
              label={t("pages.order.create.sendInvoice")}
            />
          </div>
        </fieldset>
      </Box>
      <Box>
        <fieldset>
          <legend>{t("pages.order.create.orderDescription")}</legend>
          <Input
            type="text"
            value={customerProject.annotation}
            onChange={(annotation) =>
              setCustomerProject({ ...customerProject, annotation })
            }
            label={t("pages.order.create.description")}
          />
          <div>
            <div className="order-create--options-wrapper">
              {availableTasks.length === 0 ? (
                t("pages.order.create.noTasks")
              ) : (
                <AddIcon
                  title={t("general.icons.add")}
                  width={30}
                  onClick={() =>
                    setCustomerProject({
                      ...customerProject,
                      offeredTasks: [
                        ...customerProject.offeredTasks,
                        generateEmptyOfferedTask(),
                      ],
                    })
                  }
                />
              )}
            </div>
            {rows && (
              <Table
                rows={rows}
                header={
                  t("pages.order.create.tableHeader", {
                    returnObjects: true,
                  }) as TableHeader[]
                }
              />
            )}
          </div>
        </fieldset>
      </Box>
      <Box title={t("pages.project.edit.customerFeedback")}>
        <Table
          rows={feedbackRows}
          header={
            t("pages.project.edit.feedbackTableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </form>
  );
};
