import { AxiosInstance } from "axios";
import i18n from "../../../client/src/i18n/i18n";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import { Offer, OfferConfiguration } from "../offer/Offer.types";
import { NumberRange, Order, OrderState, OrderWithOffer } from "./Order.types";
import { Project } from "../project/Project.types";
import { mapReplacer } from "..";

/**
 * API method to load all orders
 * @param axios  instance of axios
 * @returns  array with all offers
 */
export const getAllOrders = async (axios: AxiosInstance): Promise<Order[]> => {
  return axios
    .get("order/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading orders", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.loadingOrders"),
      });
      return [];
    });
};

/**
 * API Method to create a new order on the database
 * @param axios instance of axios
 * @param order order to create
 * @returns createdOrder object or undefined on error
 */
export const createNewOrder = async (
  axios: AxiosInstance,
  order: Order
): Promise<Order | undefined> => {
  return axios
    .post("order/", JSON.stringify(order, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createOrder"),
      });
      console.error("Error while saving new Order", exc);
      return undefined;
    });
};

/**
 * API Method to get all Orders in a specific orderState
 * @param axios instance of axios
 * @param state state of the orders to load
 * @returns array of found orders
 */
export const getAllOrdersForOrderState = async (
  axios: AxiosInstance,
  state: OrderState
): Promise<Order[]> => {
  return axios
    .get("/order/all/state", { params: { state } })
    .then((res) => {
      return res.data;
    })
    .catch((exc) => {
      console.error("Error while loading orders for orderState", exc);
      return [];
    });
};

/**
 * API method to update an order and an offer combined
 * @param axios instance of axios
 * @param offer updated Offer
 * @param order updated Order
 * @returns object that contains the updated order and updated offer
 */
export const updateOrderAndOffer = async (
  axios: AxiosInstance,
  offer: Offer,
  order: Order
): Promise<OrderWithOffer | undefined> => {
  return axios
    .post(
      "/order/update/offer",
      JSON.stringify(
        {
          offer: offer,
          order: order,
        } as OrderWithOffer,
        mapReplacer
      )
    )
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating order and offer", exc);
      return undefined;
    });
};

/**
 * API method to get all numberRanges
 * @param axios instance of axios
 * @returns list of all found numberRanges
 */
export const getAllNumberRanges = async (
  axios: AxiosInstance
): Promise<NumberRange[]> => {
  return axios
    .get("/numberrange/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading numberRanges", exc);
      return [];
    });
};

/** API Method to accept an offer and create a project out of it
 * @param axios instance of axios
 * @param offer offer to accept and create the project from
 * @returns  created project or undefined on error
 */
export const acceptOffer = async (
  axios: AxiosInstance,
  offerConfig: OfferConfiguration
): Promise<Project | undefined> => {
  return axios
    .post("/offer/accept", offerConfig)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while accepting the offer", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.acceptOffer"),
      });
      return undefined;
    });
};

/**
 * API Method tp decline an offer
 * @param axios instance of axios
 * @param offer offer to decline
 * @returns updated offer or undefined on error
 */
export const rejectOffer = async (
  axios: AxiosInstance,
  offerId: string
): Promise<Offer | undefined> => {
  return axios
    .post("/offer/reject", offerId, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while declining offer", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.declineOffer"),
      });
      return undefined;
    });
};

/**
 * API Method to load an order by id
 * @param axios instance of axios
 * @param id id of the order to load
 * @returns found order or undefined if nothing was found
 */
export const getOrderById = async (
  axios: AxiosInstance,
  id: string
): Promise<Order | undefined> => {
  return axios
    .get("/order/id", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading order", exc);
      return undefined;
    });
};

/**
 * API method to load an offer by id
 * @param axios instance of axios
 * @param id of the offer to load
 * @returns found offer or undefined on error
 */
export const getOfferById = async (
  axios: AxiosInstance,
  id: string
): Promise<Offer | undefined> => {
  return axios
    .get("/offer/", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading offer", exc);
      return undefined;
    });
};

/**
 * API method to update an order
 * @param axios instance of axios
 * @param order  order to update
 * @returns updated Order
 */
export const updateOrder = async (
  axios: AxiosInstance,
  order: Order
): Promise<Order | undefined> => {
  return axios
    .post("/order/update", JSON.stringify(order, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updating order", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateOrder"),
      });
    });
};
