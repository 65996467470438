import {
  Box,
  Button,
  Input,
  ProjectPlanning,
  ProjectPlanningTranslations,
  TopBar,
} from "@sam/components";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Right,
  SchambeckUser,
  Schedule,
  generateNotification,
  useData,
} from "shared";
import {
  getAllProjectSchedulesForStartDate,
  updateMultipleProjectSchedules,
} from "shared/src/project/Project.axios";
import dayjs from "shared/src/tools/Dayjs";
import { useUser } from "../../components/UserContext";
import {
  filterUserForGChecks,
  isUserAllowedToDo,
} from "../../utils/user/User.utils";
import { NotificationType } from "shared/src/notification/notification.types";

export const ProjectPlanningPage: React.FC = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [isEdit, toggleEdit] = useState<boolean>(false);
  const [localEditSchedules, setLocalEditSchedules] = useState<Schedule[]>();
  const { user, axios } = useUser();

  const { data: allProjects } = useData("PROJECT_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allUsers } = useData("SCHAMBECK_USERS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allCars } = useData("CARS_ALL_AVAILABLE", {
    config: { fallbackData: [] },
  });
  const { data: allCustomer } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allCustomerlocations } = useData(
    "CUSTOMER_LOCATION_ALL_ACTIVE",
    {
      config: { fallbackData: [] },
    }
  );
  const { data: allOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allOffers } = useData("OFFER_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Holds the users that currently pass the operational requirements
   * to be planned
   */
  const filteredUsers: SchambeckUser[] = useMemo(
    () => filterUserForGChecks(allUsers),
    [allUsers]
  );

  // sets the local copy of the schedules for editing.
  useEffect(() => {
    getAllProjectSchedulesForStartDate(dayjs(startDate), axios).then(
      setLocalEditSchedules
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  /**
   * Callback to handle the user click on the edit/save button
   */
  const handleEditButtonClick = (): void => {
    if (isEdit) {
      if (localEditSchedules)
        updateMultipleProjectSchedules(localEditSchedules, axios).then(
          setLocalEditSchedules
        );
    }
    toggleEdit((old) => !old);
  };

  const checkPlannedWorkerAmount = (offerId: string, amount: number) => {
    const plannedWorkers: number | undefined = allOffers.find(
      (offer) => offer.id === offerId
    )?.plannedAmountWorker;
    if (plannedWorkers && amount > plannedWorkers)
      generateNotification({
        type: NotificationType.WARNING,
        value: t("general.notification.error.plannedWorkers"),
      });
  };

  return (
    <>
      <TopBar title={t("pages.project.planning.topBarHeadline")}>
        <div className="user-create__topbar-content hide-on-small">
          {isUserAllowedToDo(user.right, Right.PROJECT_PLANNING_EDIT) && (
            <Button
              value={t(`pages.project.planning.${isEdit ? "save" : "edit"}`)}
              onClick={handleEditButtonClick}
            />
          )}
        </div>
      </TopBar>
      <Box>
        <Input
          maxWidth={200}
          type="date"
          onChangeDate={(date) => setStartDate(date || new Date())}
          value={startDate}
          label={t("pages.project.planning.dateInput")}
        />

        <ProjectPlanning
          customerLocations={allCustomerlocations}
          startDate={dayjs(startDate)}
          cars={allCars}
          customers={allCustomer}
          isEdit={isEdit}
          onScheduleUpdate={setLocalEditSchedules}
          projects={allProjects}
          schedules={localEditSchedules || []}
          translations={
            t("pages.project.planning.component", {
              returnObjects: true,
            }) as ProjectPlanningTranslations
          }
          users={filteredUsers}
          orders={allOrders}
          checkWorkerAmount={checkPlannedWorkerAmount}
        />
      </Box>
    </>
  );
};
