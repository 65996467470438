import { AxiosInstance } from "axios";
import { Company } from "../office/Office.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";

/**
 * API method to get all companies
 * @param axios instance of axios
 * @returns array of companies
 */
export const getAllCompanies = async (
  axios: AxiosInstance
): Promise<Company[]> => {
  return axios
    .get("/company/all")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllCompanies"),
      });
      console.error("Error while loading companies", exc);
      return [];
    });
};

/**
 * API method to get all active companies
 * @param axios instance of axios
 * @returns array of companies
 */
export const getAllActiveCompanies = async (
  axios: AxiosInstance
): Promise<Company[]> => {
  return axios
    .get("/company/all/active")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllCompanies"),
      });
      console.error("Error while loading companies", exc);
      return [];
    });
};

/**
 * API Method to update a company
 * @param axios instance of axios
 * @param company company to update on the database
 * @returns  updated company or undefined on error
 */
export const updateCompany = async (
  axios: AxiosInstance,
  company: Company
): Promise<Company | undefined> => {
  return axios
    .post("/company/update", company)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateCompany"),
      });
      console.error("Error while updating company", exc);
    });
};

/**
 * API Method to create a new company
 * @param axios instance of axios
 * @param company company to create
 * @returns created company or undefined on error
 */
export const createNewCompany = async (
  axios: AxiosInstance,
  company: Company
): Promise<Company | undefined> => {
  return axios
    .post("/company/", company)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createCompany"),
      });
      console.error("Error while updating company", exc);
    });
};

/**
 * API Method to load a company by id
 * @param axios instance of axios
 * @param companyId id of the company to load
 * @returns found company or undefined on Error
 */
export const getCompanyById = async (
  axios: AxiosInstance,
  companyId: string
): Promise<Company | undefined> => {
  return axios
    .get("company/", { params: { companyId } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading company", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getCompanyById"),
      });
      return undefined;
    });
};
