import { Input, TableRow } from "@sam/components";
import { Dispatch, SetStateAction } from "react";
import {
  BonusPayout,
  MonthlyReceipt,
  SchambeckUser,
  YearlyTimeOverview,
} from "shared";
import { uid } from "uid";
import { ReactComponent as CheckIcon } from "../../assets/check-circle.svg";
import { ReactComponent as ErrorIcon } from "../../assets/error.svg";
import ReceiptDetailBox from "../../components/receiptDetailBox/ReceiptDetailBox";
import { handleResponseData } from "../axios/axios.utils";

/**
 * Util method to convert monthlyReceipts to tableEntries, to display them on the monthly overview
 * @param receipts monthlyReceipts to convert into TableEntries
 * @param users users that are included in the monthlyReceipt to get totalIst and totalSoll data from the last month
 * @param year of the receipts
 * @param setMonthlyOverviews dispatch action to set the monthlyReceipts
 * @returns array of TableRows
 */
export const convertMonthlyReceiptIntoTableEntry = (
  receipts: MonthlyReceipt[],
  users: SchambeckUser[],
  year: number,
  setMonthlyOverviews: Dispatch<SetStateAction<MonthlyReceipt[]>>
): TableRow[] => {
  return receipts.map((receipt) => {
    let user: SchambeckUser = users.filter(
      (user) => user.id === receipt.userId
    )[0];
    if (typeof user.contractInformation?.yearlyTimes === "object") {
      user = handleResponseData(user) as SchambeckUser;
    }
    const yearlyTimes: Map<number, YearlyTimeOverview> | undefined =
      user.contractInformation?.yearlyTimes;

    let overTimeAvailable: number;
    const totalIst: number | undefined = yearlyTimes?.get(year)?.totalIst;
    const totalSoll: number | undefined = yearlyTimes?.get(year)?.totalSoll;
    if (totalIst && totalSoll) {
      overTimeAvailable =
        totalIst - totalSoll + receipt.istHours - receipt.sollHours;
    } else {
      overTimeAvailable = receipt.istHours - receipt.sollHours;
    }
    return {
      id: receipt.id,
      content: [
        receipt.completed ? <CheckIcon width={25} /> : <ErrorIcon width={25} />,
        user ? user.firstName + " " + user.lastName : "",
        receipt.hourlySalary === 0 ? "-" : receipt.hourlySalary,
        `${receipt.istHours}/${receipt.sollHours}`,
        <Input
          withoutPadding
          form="receipt-edit-form"
          type="number"
          value={receipt.payedOutSaldo}
          onChangeNumber={(payedOutSaldo) => {
            if (!payedOutSaldo) return;
            const newMonthlyOverviews: MonthlyReceipt[] = receipts.map(
              (receiptToCheck) =>
                receiptToCheck.id !== receipt.id
                  ? receiptToCheck
                  : { ...receipt, payedOutSaldo }
            );

            setMonthlyOverviews(newMonthlyOverviews);
          }}
        />,
        overTimeAvailable,
        (receipt.payedOutSaldo,
        receipt.holidayBonus100 +
          receipt.holidayBonus150 +
          receipt.nightBonus +
          receipt.saturdayBonus +
          receipt.sundayBonus).toString() + " h",
        receipt.fixedSalary === 0 ? "-" : receipt.fixedSalary,
        receipt.istHours - receipt.sollHours - receipt.payedOutSaldo,
      ],
      children: (
        <ReceiptDetailBox
          monthlyReceipt={receipt}
          monthlyReceipts={receipts}
          setMonthlyOverviews={setMonthlyOverviews}
        />
      ),
    };
  });
};

/**
 * Util method to generate an empty BonusPayout
 * @returns created BonusPayout with code generated id
 */
export const generateEmptyBonusPayout = (): BonusPayout => ({
  id: uid(),
  description: "",
  amount: 0,
  reduction: false,
});
