import { IconProps } from "react-toastify";

/**
 * interface NotificationParams
 * for different options and styles of the notification
 */
export interface NotificationParams {
  value: string | JSX.Element;
  autoClose?: number | false;
  position?: NotificationPosition;
  className?: string;
  closeButton?: boolean;
  type: NotificationType;
  width?: number;
  height?: number;
  icon?: React.ReactElement<IconProps, string> | React.ReactNode;
}
/**
 * NotificationPositions that decide, where a notification should be displayed
 */
export enum NotificationPosition {
  TOP_RIGHT = "top-right",
  TOP_CENTER = "top-center",
  TOP_LEFT = "top-left",
  BOTTOM_RIGHT = "bottom-right",
  BOTTOM_CENTER = "bottom-center",
}

/**
 * NotificationType to render the correct form of a notification
 */
export enum NotificationType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  DEFAULT = "default",
}
