import dayjs, { Dayjs } from "dayjs";
import { DayOfWeek } from "../time/Timetracking.types";
import { WorkSchedule } from "../user/User.types";

/**
 * Helper to get dates with the same time
 * @param date to delete time from
 * @returns date with standard hours and no other times
 */
export const getDateWithoutTime = (date?: Date): Date => {
  const newDate: Date = date || new Date();
  const offset: number = newDate.getTimezoneOffset();
  newDate.setHours(12 - offset / 60);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};

/**
 * Helper to get difference in Days
 * @param startDate to check
 * @param endDate to check difference to startDate
 * @returns number of days difference
 */
export const getDifferenceInDays = (startDate: Date, endDate: Date): number => {
  const dateOne = dayjs(startDate);
  const dateTwo = dayjs(endDate);
  return dateTwo.diff(dateOne, "days");
};

/**
 * Util method to calculate VacationDays needed in a range between to dates
 * @param startDate of the range to calculate
 * @param endDate  of the range to calculate
 * @param userWorkSchedule to calculate the vacationdays for
 * @param officialHolidaysInRange List of all officialHolidays in the dateRange
 * @returns number of vacationDays that the user needs to make the vacation
 */
export const calculateVacationDaysForDateRange = (
  startDate: Date,
  endDate: Date,
  userWorkSchedule: WorkSchedule[],
  officialHolidaysInRange: Dayjs[]
): number | undefined => {
  if (userWorkSchedule.length === 0) return undefined;
  let vacationDays: number = 0;

  let currDate: Dayjs = dayjs(startDate);
  while (currDate.isBefore(endDate, "day") || currDate.isSame(endDate, "day")) {
    if (
      !officialHolidaysInRange.some((day) => day.isSame(currDate, "day")) &&
      userWorkSchedule.some(
        (schedule) =>
          schedule.dayOfWeek === convertDateToDayOfWeek(currDate.day())
      )
    ) {
      const foundSchedule: WorkSchedule = userWorkSchedule.find(
        (schedule) =>
          schedule.dayOfWeek === convertDateToDayOfWeek(currDate.day())
      )!;
      if (foundSchedule.workingHours > 0) vacationDays += 1;
    }
    currDate = currDate.add(1, "day");
  }

  return vacationDays;
};

/**
 * Helper to convert dayJs.day() to dayOfWeek
 * @param day number of the day
 * @returns correct dayOfWeek to the number of day
 */
export const convertDateToDayOfWeek = (day: number): DayOfWeek | undefined => {
  switch (day) {
    case 0:
      return DayOfWeek.SUNDAY;
    case 1:
      return DayOfWeek.MONDAY;
    case 2:
      return DayOfWeek.TUESDAY;
    case 3:
      return DayOfWeek.WEDNESDAY;
    case 4:
      return DayOfWeek.THURSDAY;
    case 5:
      return DayOfWeek.FRIDAY;
    case 6:
      return DayOfWeek.SATURDAY;
    default:
      return;
  }
};
