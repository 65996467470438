/* eslint-disable @typescript-eslint/no-explicit-any */
import { getEnvironment } from "../environment";
import { Data, dataFetchers } from "./data.fetcher";
import {
  DataFetcherFunction,
  DataFetcherReturn,
  HookOptions,
} from "./data.types";
import useSWR, { SWRResponse, SWRConfiguration } from "swr";

/**
 * A hook to fetch data which is defined in the `data.fetcher.ts` file.
 *
 * This is a wrapper around the `useSWR` hook but with predefined data keys to fetch.
 *
 * @param key The data key to fetch (defined in `data.fetcher.ts`)
 * @param params The parameters for the data fetcher function (defined in `data.types.ts`)
 * @param config Optional configuration for the `useSWR` hook (passed through to `useSWR`)
 * @returns The data fetcher response (same as `useSWR`)
 *
 * @example
 * ```tsx
 * // usersByCustomer is a swr response (same when using `useSWR`)
 * const usersByCustomer = useData("USERS_BY_CUSTOMER", {customerUid: "xyz"});
 * ```
 */
export const useData = <
  DataKey extends keyof Data,
  Config extends SWRConfiguration<ReturnData>,
  Params extends Data[DataKey] = Data[DataKey],
  ReturnData = DataFetcherReturn<DataKey>
>(
  key: DataKey,
  { params, config }: HookOptions<Params, Config>
): SWRResponse<ReturnData, any, Config> => {
  const { axios, user, selectedOffices } = getEnvironment();
  if (!axios || !user)
    throw new Error(
      "useData hook can only be used when axios and user are defined"
    );
  const dataFetcher = dataFetchers[key];

  const fetcherFunction: DataFetcherFunction<Params, ReturnData> =
    dataFetcher.fetcher as any;

  // for correct rerendering on office selection the key of the SWR
  // entry needs to be adjusted
  const data = useSWR(
    [`${selectedOffices.join("-")}/${dataFetcher.key}`, params],
    ([, opt]) =>
      fetcherFunction(opt as Params, {
        axios,
        user,
      }),
    config
  );
  return data;
};
