import Keycloak from "keycloak-js";

// Setup Keycloak instance as needed
const keycloak: Keycloak = new Keycloak({
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT,
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
  url: import.meta.env.VITE_KEYCLOAK_URL,
});

export default keycloak;
