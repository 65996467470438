import { TableRow } from "@sam/components";
import { SimpleUser } from "shared";
import { Comment } from "shared/src/comments/Comment.types";
import { uid } from "uid";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { getUserNameForSimpleUser } from "../user/User.utils";
import i18n from "../../i18n/i18n";

/**
 * Helper to create Comment object
 * @param creatorId of the comment
 * @param content of the comment
 * @returns  comment Object
 */
export const createComment = (creatorId: string, content: string): Comment => ({
  id: uid(),
  creatorId,
  createDate: new Date(),
  content,
});

/**
 * Util method to convert comments into TableEntries
 * @param comments to convert
 * @param users array of simpleUsers to get the name of the creator
 * @param removeComment method to remove a comment
 * @returns Array of TableRows containing the comments
 */
export const convertCommentsIntoTableEntries = (
  comments: Comment[],
  users: SimpleUser[],
  removeComment: (id: string) => void
): TableRow[] =>
  comments.map((comment) => ({
    id: comment.id,
    content: [
      comment.createDate.toLocaleDateString("DE-de"),
      comment.content,
      getUserNameForSimpleUser(comment.creatorId, users),
      <div>
        <DeleteIcon
          title={i18n.t("general.icons.delete")}
          width={30}
          onClick={(evt) => {
            evt.stopPropagation();
            removeComment(comment.id);
          }}
        />
      </div>,
    ],
  }));
