import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useData } from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { convertTasksIntoTableEntries } from "../../utils/task/task.utils";

export const TaskOverview: React.FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: loadedSimpleUsers } = useData("SIMPLEUSERS_ALL", {
    params: { userId: user.id },
    config: { fallbackData: [] },
  });

  const { data: loadedTasks } = useData("TASKS_ALL", {
    params: { userId: user.id },
    config: { fallbackData: [] },
  });

  //Holds the tableRows for tasks
  const rows: TableRow[] = useMemo(
    () =>
      convertTasksIntoTableEntries(loadedTasks, loadedSimpleUsers, navigate),
    [loadedSimpleUsers, loadedTasks, navigate]
  );

  return (
    <>
      <TopBar title={t("pages.tasks.overview.title")}>
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("/task/create")}
        />
      </TopBar>
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.tasks.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
