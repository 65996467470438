import React from "react";

import { LoaderComponentProps } from "./Loader.types";
import "./Loader.scss";

export const Loader: React.FC<LoaderComponentProps> = ({
  fullscreen = false,
}) => (
  <div
    data-testid="Loader"
    className={
      fullscreen ? "loader-component__fullscreen" : "laoder-component__small"
    }
  >
    <div className="loader"></div>
  </div>
);
