import { AxiosInstance } from "axios";
import { generateNotification, mapReplacer } from "..";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import { WorkEquipment } from "./WorkEquipment.types";

/**
 * API Method to get all workequipments
 * @param axios instance of axios
 * @returns  array of all found entries
 */
export const getAllWorkEquipmentEntries = async (
  axios: AxiosInstance
): Promise<WorkEquipment[]> => {
  return axios
    .get("/workequipment/all")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllWorkEquipment"),
      });
      console.error("Error while loading work equipment", exc);
      return [];
    });
};

/**
 * API Method to create a new custoemrFeedbackEntry
 * @param axios instance of axios
 * @param entry  to persist on the database
 * @returns  created object or undefined on error
 */
export const createNewWorkEquipment = async (
  axios: AxiosInstance,
  entry: WorkEquipment
): Promise<WorkEquipment | undefined> => {
  return axios
    .post("/workequipment/", JSON.stringify(entry, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewWorkEquipment"),
      });
      console.error("Error while saving workequipment", exc);
      return undefined;
    });
};

/**
 * API Method to update a workequipment
 * @param axios instance of axios
 * @param entry updated entry to save
 * @returns updated object or undefined on error
 */
export const updateWorkEquipment = async (
  axios: AxiosInstance,
  entry: WorkEquipment
): Promise<WorkEquipment | undefined> => {
  return axios
    .post("/workequipment/update", JSON.stringify(entry, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateWorkEquipment"),
      });
      console.error("Error while updating entry", exc);
      return undefined;
    });
};
