import { Agenda, Box, TopBar } from "@sam/components";
import { AgendaEntryGroup } from "@sam/components/src/Agenda/Agenda.types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Right,
  Schedule,
  ScheduleType,
  createNewSchedule,
  deleteSchedule,
  generateNotification,
  updateSchedule,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { getSchedulesForMultipleUsers } from "shared/src/project/Project.axios";
import { ScheduleEdit } from "../../components/schedule/ScheduleEdit";
import { useUser } from "../../components/UserContext";
import {
  generateEmptyAppointmentDetails,
  generateEmptySchedule,
} from "../../utils/project/Project.utils";
import {
  generatePlanningBoardEntries,
  isUserAllowedToDo,
} from "../../utils/user/User.utils";

export const PlanningBoard: React.FC = () => {
  const { axios, user } = useUser();
  const { data: loadedSimpleUsers, mutate: refreshUsers } = useData(
    "SIMPLEUSERS_ALL",
    {
      config: { fallbackData: [] },
    }
  );

  const { data: loadedHolidays } = useData("HOLIDAYS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedAbsenceReasons } = useData("ABSENCE_REASONS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedProjects } = useData("PROJECT_ALL", {
    config: { fallbackData: [] },
  });

  const [entryGroups, setEntryGroups] = useState<AgendaEntryGroup[]>([]);
  const [scheduleToEdit, setScheduleToEdit] = useState<Schedule>();
  const [userSchedules, setUserSchedules] = useState<Schedule[]>([]);

  const { t } = useTranslation();

  // Hook to load schedules for users to display
  useEffect(() => {
    if (loadedSimpleUsers.length <= 0) return;
    getSchedulesForMultipleUsers(
      axios,
      loadedSimpleUsers.map((user) => user.id)
    ).then(setUserSchedules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedSimpleUsers]);

  //Hook to generate the agenda entries after all neccessary data is available
  useEffect(() => {
    setEntryGroups(
      generatePlanningBoardEntries(
        userSchedules,
        loadedSimpleUsers,
        loadedProjects,
        loadedAbsenceReasons
      )
    );
  }, [
    loadedSimpleUsers,
    loadedHolidays,
    userSchedules,
    user.id,
    loadedAbsenceReasons,
    loadedProjects,
  ]);

  /**
   * Submit handler to create a new Absence
   * @param Schedule to save
   */
  const handleSubmit = async (schedule: Schedule): Promise<void> => {
    if (!scheduleToEdit) return Promise.resolve();
    if (!scheduleToEdit.id)
      return createNewSchedule(axios, schedule).then((createdSchedule) => {
        if (createdSchedule) {
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.absenceCreate"),
          });
          setScheduleToEdit(undefined);
          refreshUsers();
        }
      });
    else
      return updateSchedule(axios, schedule).then((updatedSchedule) => {
        if (updatedSchedule) {
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.absenceUpdate"),
          });
          setScheduleToEdit(undefined);
          refreshUsers();
        }
      });
  };

  /**
   *Helper method to check if a selected item is a user schedule and update it
   * @param id of the entry to edit. Consisting of the userid + scheduleId
   */
  const handleEdit = (id: string): void => {
    const schedule: Schedule | undefined = userSchedules.find((schedule) =>
      id.includes(schedule.id)
    );
    if (schedule?.type === ScheduleType.USER_ABSENCE)
      setScheduleToEdit(schedule);
    else {
      setScheduleToEdit(undefined);
      generateNotification({
        type: NotificationType.INFO,
        value: t("general.notification.error.selectedProjectPlanning"),
      });
    }
  };

  /**
   * Util method to delete an entry from the agenda
   * @param id of the entry to edit. Consisting of the userid + scheduleId
   */
  const handleDelete = (id: string): void => {
    const schedule: Schedule | undefined = userSchedules.find((schedule) =>
      id.includes(schedule.id)
    );
    if (schedule?.type === ScheduleType.USER_ABSENCE)
      deleteSchedule(axios, schedule.id).then((success) => {
        if (success) {
          generateNotification({
            type: NotificationType.SUCCESS,
            value: "Erfolgreich gelöscht",
          });
          refreshUsers();
        }
      });
    else {
      setScheduleToEdit(undefined);
      generateNotification({
        type: NotificationType.INFO,
        value: t("general.notification.error.selectedProjectPlanning"),
      });
    }
  };
  return (
    <form>
      <TopBar title={t("pages.planningBoard.topBarHeadline")} />
      <Box>
        <Agenda
          deleteItem={handleDelete}
          handleEdit={handleEdit}
          entryGroups={entryGroups}
          addItem={(item) => {
            setScheduleToEdit(
              generateEmptySchedule({
                startDate: new Date(item.start || new Date()),
                endDate: new Date(item.start || new Date()),
                referenceId: item.group?.toString() || "",
                appointmentDetail: {
                  ...generateEmptyAppointmentDetails(),
                  reasonId: "",
                },
                type: ScheduleType.USER_ABSENCE,
              })
            );
          }}
        />
      </Box>
      {scheduleToEdit &&
        loadedSimpleUsers &&
        isUserAllowedToDo(user.right, Right.ABSENCE_CREATE) && (
          <Box>
            <ScheduleEdit
              handleScheduleCreate={handleSubmit}
              handleScheduleDelete={handleDelete}
              schedule={scheduleToEdit}
              updateSchedule={setScheduleToEdit}
            />
          </Box>
        )}
    </form>
  );
};
