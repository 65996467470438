/**
 * This constant can be used to determine the state of a thing (mostly a
 * document) that is needed
 */
export enum RequiredState {
    UNKNOWN = "UNKNOWN",
    NOT_AVAILABLE = "NOT_AVAILABLE",
    AVAILABLE = "AVAILABLE",
    COMING = "COMING",
  }
  