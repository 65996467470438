import { Box, Button, Popup, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { deleteSchambeckUser, getUserOverviewCSV, useData } from "shared";
import { Right } from "shared/src/userRole/UserRole.types";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { NotAllowed } from "../../components/NotAllowed";
import { useUser } from "../../components/UserContext";
import { downloadFile } from "../../utils/files/Files.utils";
import {
  convertUsersIntoTableEntries,
  isUserAllowedToDo,
} from "../../utils/user/User.utils";

const UserOverviewPage: React.FC = () => {
  const [userToDeleteId, setUserToDeleteId] = useState<string>();
  const { axios, user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState<string>("");

  const { data: loadedUsers, mutate: updateUser } = useData(
    "SCHAMBECK_USERS_ALL",
    {
      config: { fallbackData: [] },
    }
  );
  const { data: loadedUserFunctions } = useData("USER_FUNCTION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  /**
   * Helper Method to convert users to tableRows
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertUsersIntoTableEntries(
        user.right,
        loadedUsers,
        loadedUserFunctions,
        loadedOffices,
        navigate,
        setUserToDeleteId
      ),

    [loadedOffices, loadedUserFunctions, loadedUsers, navigate, user.right]
  );

  /**
   * Helper method to crete and download the overview csv
   */
  const handleCsvDownload = (): void => {
    getUserOverviewCSV(axios).then(
      (generatedFile) =>
        generatedFile &&
        downloadFile(generatedFile, t("pages.user.overview.overviewFilename"))
    );
  };

  return isUserAllowedToDo(user.right, Right.USER_VIEW_ALL) ? (
    <>
      <TopBar
        title={t("pages.user.overview.headline")}
        onSearch={setFilterValue}
      >
        {isUserAllowedToDo(user.right, Right.USER_CREATE) && (
          <>
            <PlusIcon
              title={t("general.icons.add")}
              onClick={() => navigate("/user/create")}
            />
            <DownloadIcon onClick={handleCsvDownload} />
          </>
        )}
      </TopBar>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.user.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
      <Popup
        isOpen={!!userToDeleteId}
        onClose={() => setUserToDeleteId(undefined)}
        title={t("pages.user.overview.deletePopup")}
        buttons={[
          <Button
            value={t("general.buttons.yes")}
            onClick={() =>
              deleteSchambeckUser(userToDeleteId!, axios).then((success) => {
                if (success) {
                  updateUser(
                    loadedUsers.filter(
                      (userToCheck) => userToCheck.id !== userToDeleteId
                    )
                  );
                  setUserToDeleteId(undefined);
                }
              })
            }
          />,
          <Button
            value={t("general.buttons.no")}
            onClick={() => setUserToDeleteId(undefined)}
          />,
        ]}
      >
        <p>{t("general.popup.deleteUser")}</p>
      </Popup>
    </>
  ) : (
    <NotAllowed neccessaryRight={Right.USER_VIEW_ALL} />
  );
};
export default UserOverviewPage;
