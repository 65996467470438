import { AxiosInstance } from "axios";
import { Holiday } from "./Holiday.types";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import { generateNotification } from "../notification/notification.util";

/**
 * API Method to load all holidays
 * @param axios instance of axois
 * @returns Array of holidays
 */
export const getAllHolidays = async (
  axios: AxiosInstance
): Promise<Holiday[]> => {
  return axios
    .get("/holiday/all")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllHolidays"),
      });
      console.error("Error while loading holidays", exc);
      return [];
    });
};

/**
 * API Method to load all holidays for given user
 * @param userId to load holidays for
 * @param axios instance of axios
 * @returns Holiday Array if found
 */
export const getAllHolidaysForUser = async (
  userId: string,
  axios: AxiosInstance
): Promise<Holiday[]> => {
  return axios
    .get("/holiday/user", { params: { userId } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllHolidaysUser"),
      });
      console.error("Error while loading all holidays for user", exc);
      return [];
    });
};
/**
 * API Method to create holiday
 * @param holiday to create
 * @param axios Instance of Axios
 * @returns created holiday object
 */
export const createHoliday = async (
  holiday: Holiday,
  axios: AxiosInstance
): Promise<Holiday> => {
  return axios
    .post("/holiday/", holiday)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createHolliday"),
      });
      console.error("Error while creating holiday", exc);
      return false;
    });
};

/**
 * API Method to load holiday by id
 * @param id of the holiday to load
 * @param axios instance of axios
 * @returns holiday if found
 */
export const getHolidayById = async (
  id: string,
  axios: AxiosInstance
): Promise<Holiday | undefined> => {
  return axios
    .get("/holiday/holiday", { params: { id } })
    .then((res) => {
      if (res.data) return res.data;
    })
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getHolidayById"),
      });
      console.error("Error while loading holiday", exc);
      return;
    });
};

/**
 * API Method to load all requests of subordinate employees of a given user
 * @param axios instance of axios
 * @param userId of the supervisor to load subordinate requests for
 * @returns  Array of found holidays or empty array
 */
export const getAllSubordinateHolidayRequests = async (
  axios: AxiosInstance,
  userId: string
): Promise<Holiday[]> => {
  return axios
    .get("/holiday/supervised/all", { params: { userId } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.subordinateHolidays"),
      });
      console.error(
        "Error while loading holidayRequests for subordinate holidayRequests",
        exc
      );
      return [];
    });
};
/**
 * API Method to update hooliday on DB
 * @param axios instance of axios
 * @param updatedHoliday to save
 * @returns boolean if successful or not
 */
export const updateHoliday = async (
  axios: AxiosInstance,
  updatedHoliday: Holiday
): Promise<boolean> => {
  return axios
    .post("/holiday/update", updatedHoliday)
    .then((res) => res.status === 200)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateHoliday"),
      });
      console.error("Error while updating holiday", exc);
      return false;
    });
};
