import { Box, Table, TableRow, TopBar } from "@sam/components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useData } from "shared";
import { useMemo } from "react";
import { convertFeedbackEntriesIntoTableEntries } from "../../utils/customerFeedbackEntry/CustomerFeedbackEntry.utils";
import { TableHeader } from "@sam/components/src/Table/Table.types";

export const CustomerFeedbackEntryOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: loadedFeedbackEntries } = useData("FEEDBACK_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * TableRows containing all customerFeedbackEntries
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertFeedbackEntriesIntoTableEntries(loadedFeedbackEntries, navigate),
    [loadedFeedbackEntries, navigate]
  );
  return (
    <>
      <TopBar title={t("pages.customerFeedback.overview.topBarHeadline")}>
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("/customer/feedback/edit")}
        />
      </TopBar>
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.customerFeedback.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
