import { PopupProps } from "./Popup.types";
import "./Popup.scss";
import React from "react";

export const Popup: React.FC<PopupProps> = ({
  isOpen,
  children,
  onClose,
  buttons,
  title,
}) => {
  return isOpen ? (
    <div className="popup-component__wrapper" onClick={onClose}>
      <div
        className="popup-component__content"
        onClick={(event) => event.stopPropagation()}
      >
        {title && <p className="popup-component__title">{title}</p>}
        <div className="popup-component__children">{children}</div>
        {buttons && <div className="popup-component__buttons">{buttons}</div>}
      </div>
    </div>
  ) : null;
};
