import { AxiosInstance } from "axios";
import { DeliveryNoteMailConfiguration, mapReplacer } from "..";
import i18n from "../../../client/src/i18n/i18n";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import { DeliveryNote, DeliveryNoteConfig } from "./DeliveryNote.types";

/**
 * API Method to create a new deliveryNote
 * @param axios instance of axios
 * @param deliveryNote to create
 * @returns saved deliveryNote or undefined
 */
export const saveNewDeliveryNote = async (
  axios: AxiosInstance,
  deliveryNote: DeliveryNote
): Promise<DeliveryNote | undefined> => {
  return axios
    .post("/delivery/", JSON.stringify(deliveryNote, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewDeliveryNote"),
      });
      console.error("Error while saving deliveryNote", exc);
      return undefined;
    });
};

/**
 * API Method to create  a delivery note as a pdf document without saving
 * @param axios instance of axios
 * @param config neccessary config to crete a deliveryNote
 * @returns Blob of the created document or undefined on error
 */
export const createTransientDeliveryNotePdf = async (
  axios: AxiosInstance,
  config: DeliveryNoteConfig
): Promise<Blob | undefined> => {
  return axios
    .post("/export/pdf/delivery", config, { responseType: "blob" })
    .then((res) => new Blob([res.data], { type: "application/pdf" }))
    .catch((exc) => {
      console.error("Error while creating deliveryNote", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createTransientDeliveryNote"),
      });
      return undefined;
    });
};

/**
 * API Method to create and save a deliveryNote as a pdf document
 * @param axios instance of axios
 * @param config neccessary config to create the note
 * @returns Promise containing a boolean value if the creation was successfull
 */
export const createAndSaveDeliveryNotePdf = async (
  axios: AxiosInstance,
  config: DeliveryNoteConfig
): Promise<boolean> => {
  return axios
    .post("/export/pdf/delivery/save", config)
    .then((res) => res.status === 201)
    .catch((exc) => {
      console.error("Error while saving document", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createAndSaveDeliveryNote"),
      });
      return false;
    });
};

/**
 * API Method to create a deliveryNote and ssend it per mail
 * @param axios instance of axios
 * @param config mailConfiguration with the neccessary data
 * @returns Promise containing a boolean depending of the success
 */
export const createAndSendDeliveryNote = async (
  axios: AxiosInstance,
  config: DeliveryNoteMailConfiguration
): Promise<boolean> => {
  return axios
    .post("/mail/deliverynote", config)
    .then((res) => res.status === 200)
    .catch((exc) => {
      console.error("Error while sending mail", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createAndSendDeliveryNote"),
      });
      return false;
    });
};
