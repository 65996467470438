import { useKeycloak } from "@react-keycloak/web";
import axios, { AxiosInstance } from "axios";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { SchambeckUser, loadSchambeckUserById } from "shared";
import { updateEnvironment } from "shared/src/environment";
import { handleResponseConversion } from "../utils/axios/axios.utils";

/**
 * The context model which is available in the useUser hook
 */
interface UserContextData {
  user: SchambeckUser;
  axios: AxiosInstance;
  update: (selectedOffices: string[]) => Promise<void>;
}

/**
 * Created react context
 */
const UserContext = createContext<UserContextData>(undefined!);

/**
 * A user provider which provides the user context to all children
 *
 * @param fallback The fallback component which is rendered if the user is not logged in
 * @param loading The loading component which is rendered while the user is loading
 * @param children The children which are rendered if the user is logged in
 */
export const UserProvider: React.FC<{
  fallback?: ReactNode;
  loading?: ReactNode;
  children: (user: SchambeckUser) => ReactNode;
}> = ({ fallback, loading, children }) => {
  const { keycloak, initialized } = useKeycloak();
  const [contextState, setContextState] = useState<{
    user: SchambeckUser;
    axios: AxiosInstance;
  }>();
  /**
   * Function to redefine the axios instance with the current Id Token and fetch the current user
   */
  const update = useCallback(
    async (selectedOffices: string[] = []) => {
      if (!keycloak.token) {
        setContextState(undefined);
        return;
      }

      const databaseId: string = await keycloak
        .loadUserInfo()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: any) => resp.databaseId);

      // --- Axios ---
      // let token = keycloak.token;
      keycloak.onAuthRefreshSuccess = () => {
        const instance = axios.create({
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Content-Type": "application/json;charset=UTF-8",
            "X-Selected-Offices": selectedOffices.join(","),
          },
          baseURL: import.meta.env.VITE_BACKEND_URL,
        });
        instance.interceptors.response.use(handleResponseConversion);
        setContextState(user ? { user, axios: instance } : undefined);
        updateEnvironment(
          user
            ? { user, axios: instance, selectedOffices }
            : { user: undefined, axios: undefined, selectedOffices }
        );
      };
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          "Content-Type": "application/json;charset=UTF-8",
          "X-Selected-Offices": selectedOffices.join(","),
        },
        baseURL: import.meta.env.VITE_BACKEND_URL,
      });
      instance.interceptors.response.use(handleResponseConversion);
      // --- User ---
      const user: SchambeckUser | null = await loadSchambeckUserById(
        databaseId,
        instance
      );
      if (user == null) return;

      // set states
      setContextState(user ? { user, axios: instance } : undefined);
      updateEnvironment(
        user
          ? { user, axios: instance, selectedOffices }
          : { user: undefined, axios: undefined, selectedOffices }
      );
    },
    [keycloak]
  );

  /**
   * effect to load the data of the currently logged in user.
   * triggers everytime the auth state change.
   */
  useEffect(() => {
    if (!initialized) return;
    update();
  }, [initialized, keycloak, update]);

  // user & axios is defined therefore logged in
  if (contextState)
    return (
      <UserContext.Provider
        value={{
          user: contextState.user,
          axios: contextState.axios,
          update,
        }}
      >
        {children(contextState.user)}
      </UserContext.Provider>
    );

  // auth not loading and auth user undefined therefore logged out
  if (initialized && !keycloak.token) {
    localStorage.clear();
    return <>{fallback}</>;
  }

  // anywhere else should be in loading state
  return <>{loading}</>;
};

/**
 * Simple shortcut for `useContext(UserContext)`.
 *
 * @returns {@link UserContext}
 */
export const useUser = () => useContext(UserContext);
