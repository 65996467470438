import { Box, Button, Input, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getAllProtocolsInRange,
  getMultipleProtocolPdfs,
  Period,
  ProjectProtocol,
  useData,
} from "shared";
import { useUser } from "../../components/UserContext";
import { downloadFile } from "../../utils/files/Files.utils";
import { convertProtocolsIntoTableEntries } from "../../utils/project/Project.utils";

export const ProtocolOverview: React.FC = () => {
  const { axios } = useUser();
  const { t } = useTranslation();

  const [selectedPeriod, setSelectedPeriod] = useState<Period>();
  const [protocols, setProtocols] = useState<ProjectProtocol[]>([]);
  const [selectedProtocols, setSelectedProtocols] = useState<string[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");

  //Hook to set the start and endDate on the first render cycle
  useEffect(() => {
    const startDate: Date = new Date();
    startDate.setMonth(
      new Date().getMonth() < 11 ? startDate.getMonth() - 1 : 0
    );
    const endDate: Date = new Date();
    setSelectedPeriod({ startDate, endDate });
  }, []);

  //Hook to keep the protocols in sync with the selected Dates
  useEffect(() => {
    if (!selectedPeriod) return;
    getAllProtocolsInRange(axios, selectedPeriod).then(setProtocols);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod]);

  const { data: loadedCustomers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const { data: loadedSimpleUsers } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Holds rows with all protocols
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertProtocolsIntoTableEntries(
        protocols,
        loadedCustomers,
        loadedSimpleUsers,
        selectedProtocols,
        setSelectedProtocols
      ),
    [protocols, loadedCustomers, loadedSimpleUsers, selectedProtocols]
  );

  return (
    <>
      <TopBar
        title={t("pages.protocolOverview.topBarHeadline")}
        onSearch={setFilterValue}
      />
      <Box>
        <div className="three-columns">
          <Input
            type="date"
            value={selectedPeriod?.startDate}
            label={t("pages.protocolOverview.rangeStart")}
            onChangeDate={(startDate) =>
              startDate &&
              selectedPeriod &&
              setSelectedPeriod({ ...selectedPeriod, startDate })
            }
          />
          <Input
            type="date"
            value={selectedPeriod?.endDate}
            label={t("pages.protocolOverview.rangeEnd")}
            onChangeDate={(endDate) =>
              endDate &&
              selectedPeriod &&
              setSelectedPeriod({ ...selectedPeriod, endDate })
            }
          />
          <Button
            value={t("general.buttons.download")}
            onClick={() =>
              getMultipleProtocolPdfs(axios, selectedProtocols).then((file) => {
                if (file)
                  downloadFile(file, t("pages.protocolOverview.fileName"));
              })
            }
          />
        </div>
      </Box>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.protocolOverview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
