import { Box, Input, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { generateNotification } from "shared";
import {
  createNewCustomerFeedbackEntry,
  updateCustomerFeedbackEntry,
} from "shared/src/customerFeedbackEntry/CustomerFeedbackEntry.axios";
import { CustomerFeedbackEntry } from "shared/src/customerFeedbackEntry/CustomerFeedbackEntry.types";
import { NotificationType } from "shared/src/notification/notification.types";
import { useUser } from "../../components/UserContext";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { generateTableRowForLanguageTranslation } from "../../utils/InputUtils";
import { generateEmptyCustomerFeedbackEntry } from "../../utils/customerFeedbackEntry/CustomerFeedbackEntry.utils";

export const CustomerFeedbackEntryEdit: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios } = useUser();
  const location = useLocation<{ entry?: CustomerFeedbackEntry }>();
  const [entryToEdit, setEntryToEdit] = useState<CustomerFeedbackEntry>(
    location.state?.entry
      ? location.state.entry
      : generateEmptyCustomerFeedbackEntry()
  );

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const isEdit: boolean = useMemo(() => !!entryToEdit.id, [entryToEdit.id]);

  /**
   * Rows to show and edit the different translations
   */
  const languageNameRows: TableRow[] = useMemo(
    (): TableRow[] =>
      generateTableRowForLanguageTranslation(
        entryToEdit,
        "names",
        setEntryToEdit
      ),
    [entryToEdit]
  );
  /**
   * Submit handler to update or create a customerFeedbackEntry
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;
    if (isEdit) {
      updateCustomerFeedbackEntry(axios, entryToEdit).then((updatedEntry) => {
        if (updatedEntry && redirect) navigate("/customer/feedback");
        else if (updatedEntry) {
          setEntryToEdit(updatedEntry);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.updateFeedbackEntry"),
          });
        }
      });
    } else {
      createNewCustomerFeedbackEntry(axios, entryToEdit).then(
        (createdEntry) => {
          if (redirect && createdEntry) navigate("/customer/feedback");
          else if (createdEntry) {
            setEntryToEdit(createdEntry);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.createFeedbackEntry"),
            });
          }
        }
      );
    }
  };
  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={t(
          `pages.customerFeedback.edit.topBarHeadline${
            isEdit ? "Edit" : "Create"
          }`
        )}
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>

      <Box>
        <div className="three-columns">
          <Input
            onChange={(title) => setEntryToEdit({ ...entryToEdit, title })}
            type="text"
            value={entryToEdit.title}
            label={t("pages.customerFeedback.edit.title")}
          />
          <Input
            disabled
            type="date"
            value={entryToEdit.createDate}
            onChangeDate={() => {}}
            label={t("pages.customerFeedback.edit.createDate")}
          />
          <Input
            disabled
            type="date"
            value={entryToEdit.lastUpdate}
            onChangeDate={() => {}}
            label={t("pages.customerFeedback.edit.lastUpdate")}
          />
        </div>
      </Box>
      <Box>
        <Table
          rows={languageNameRows}
          header={
            t("pages.customerFeedback.edit.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </form>
  );
};
