import { Checkbox, Option, TableRow } from "@sam/components";
import {
  AgendaEntry,
  AgendaEntryGroup,
} from "@sam/components/src/Agenda/Agenda.types";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import {
  AbsenceReason,
  Contact,
  Country,
  County,
  CustomerUser,
  DayOfWeek,
  DriverLicenseType,
  EducationLevel,
  EducationQualification,
  FamilyState,
  Gender,
  Office,
  Project,
  RequiredState,
  SchambeckUser,
  Schedule,
  SimpleUser,
  TaxClass,
  UserFunction,
  WorkSchedule,
  WorkScheduleDistribution,
  generateEmptyContractInformation,
  generateIsoDateForDateAndTime,
} from "shared";
import { Language } from "shared/src/Language/Language.types";
import dayjs from "shared/src/tools/Dayjs";
import { Right, UserRight, UserRole } from "shared/src/userRole/UserRole.types";
import { uid } from "uid";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";
import {
  calculateDailyWorkForGivenDays,
  calculateDailyWorkTimeForWeeklySchedule,
  calculatePreciseWorkScheduleForUser,
  getTotalWorkingTimeFromPreciseMapping,
} from "../timeTracking/TimeTracking.utils";
/**
 * Helper to transform the given users into table row entries to display
 *
 * @param users The users to display
 * @returns List of table rows to display
 * @tested
 */
export const convertUsersIntoTableEntries = (
  userRights: UserRight,
  users: SchambeckUser[],
  functions: UserFunction[],
  offices: Office[],
  navigate: NavigateFunction,
  setUserToDeleteId: (userToDelete: string) => void
): TableRow[] => {
  return users
    .sort((userA, userB) => userA.lastName.localeCompare(userB.lastName))
    .map((user) => {
      const userFunction: UserFunction | undefined = functions.find(
        (userFunction) => userFunction.id === user.userFunctionId
      );
      const userOffice: Office | undefined = offices.find(
        (office) => office.id === user.officeId
      );
      const currentUserOffice: Office | undefined = offices.find(
        (office) => office.id === user.contractInformation.currentOfficeId
      );
      return {
        id: user.id!,
        onClick: () => navigate(`/user/create`, { state: { user } }),
        content: [
          user.lastName,
          user.firstName,
          i18n.t(
            `pages.user.create.contract.employmentType.${user.contractInformation.employmentType}`
          ),
          userFunction?.title || "-",
          userOffice?.name || "-",
          currentUserOffice?.name || "-",
          <p>
            {user.contact.phone +
              (user.contact.phone ? ", " : "") +
              (user.contact.phonePrivate &&
                user.contact.phonePrivate !== "" &&
                "(P)") +
              user.contact.phonePrivate +
              (user.contact.phonePrivate ? ", " : "") +
              user.contact.mobile +
              (user.contact.mobile ? ", " : "") +
              (user.contact.mobilePrivate &&
                user.contact.mobilePrivate !== "" &&
                "(P)") +
              user.contact.mobilePrivate}
          </p>,
          user.workQualifications
            .map(
              (quali, index) =>
                quali + (index < user.workQualifications.length ? ", " : "")
            )
            .toString(),
          user.disabled
            ? i18n.t("pages.user.overview.deactivated")
            : i18n.t("pages.user.overview.active"),
          <div className="table-actions">
            {isUserAllowedToDo(userRights, Right.USER_EDIT) && (
              <EditIcon
                title={i18n.t("general.icons.edit")}
                height={40}
                width={40}
                onClick={(evt) => {
                  evt.stopPropagation();
                  navigate(`/user/create`, { state: { user } });
                }}
              />
            )}
            {isUserAllowedToDo(userRights, Right.USER_DELETE) && (
              <DeleteIcon
                title={i18n.t("general.icons.delete")}
                height={40}
                width={40}
                onClick={(evt) => {
                  evt.stopPropagation();
                  setUserToDeleteId(user.id);
                }}
              />
            )}
          </div>,
        ],
      };
    });
};

/**
 * Helper method to convert UserRoles into TableEntries
 * @param userRoles to convert
 * @param navigate NavigateFunction to edit the roles
 * @returns Array of TableRows
 */
export const convertUserRolesIntoTableEntries = (
  userRoles: UserRole[],
  navigate: NavigateFunction
): TableRow[] => {
  return userRoles.map((role) => ({
    id: role.id,
    onClick: () => navigate("/roles/edit", { state: { historyRole: role } }),
    content: [
      role.name,
      role.disabled
        ? i18n.t("pages.userRole.overview.deactivated")
        : i18n.t("pages.userRole.overview.active"),
      <div className="table-actions">
        <EditIcon
          title={i18n.t("general.icons.edit")}
          width={40}
          onClick={(evt) => {
            evt.stopPropagation();
            navigate("/roles/edit", { state: { historyRole: role } });
          }}
        />
      </div>,
    ],
  }));
};

/**
 * Helper to generate empty user Object
 * @returns
 */
export const generateEmptySchambeckUser = (
  createdBy: string
): SchambeckUser => ({
  id: undefined!,
  password: "",
  createDate: new Date(),
  createdBy: createdBy,
  lastUpdated: new Date(),
  updatedBy: "",
  gender: Gender.MALE,
  title: "",
  userLanguages: [],
  firstName: "",
  lastName: "",
  username: "",
  contact: generateEmptyContactInformation(),
  businessAreaIds: [],
  userFunctionId: "",
  privateInformation: {
    id: uid(),
    birthDate: new Date(),
    residencePermitEndDate: new Date(),
    workPermitEndDate: new Date(),
    birthCity: "",
    nationality: "",
    bankAccount: { owner: "", bic: "", iban: "" },
    children: false,
    healthInsurance: "",
    passportNumber: "",
    religion: "",
    taxId: "",
    driverLicenseCopyState: RequiredState.UNKNOWN,
    driverLicenseType: DriverLicenseType.UNKNOWN,
    educationLevel: EducationLevel.UNKNOWN,
    educationQualification: EducationQualification.UNKNOWN,
    familyState: FamilyState.LEDIG,
    taxClass: TaxClass.III,
    equalized: false,
    severlyDisabled: false,
  },
  contractInformation: generateEmptyContractInformation(createdBy),
  personalNumber: "",
  disabled: false,
  deputyId: "",
  supervisorId: "",
  officeId: "",
  right: { customRights: new Map(), role: generateEmptyUserRole(createdBy) },
  workQualifications: [],
  customerTimes: new Map<string, number>(),
  availableOffices: [],
});

/**
 * Helper to create a new and initialized instance of a UserFunction
 *
 * @param override Optional parameter to overide
 * @returns The newly created instance
 */
export const generateEmptyUserFunction = (
  override?: Partial<UserFunction>
): UserFunction => ({
  id: undefined!,
  createdBy: undefined!,
  createDate: undefined!,
  title: "",
  names: new Map<Language, string>(),
  disabled: false,
  ...override,
});

/**
 * Helper method to create an empty UserRole
 * @param userId that generated the role
 * @returns empty UserRole
 */
export const generateEmptyUserRole = (userId: string): UserRole => ({
  createDate: new Date(),
  createdBy: userId,
  disabled: false,
  id: undefined!,
  lastUpdate: new Date(),
  name: "",
  rights: [Right.NONE],
});

/**
 * Helper to generate empty contact information
 * @returns empty contact information
 */
export const generateEmptyContactInformation = (): Contact => ({
  id: uid(),
  phone: "",
  mobile: "",
  city: "",
  country: Country.GERMANY,
  mail: "",
  street: "",
  streetNumber: "",
  zip: "",
  mailPrivate: "",
  phonePrivate: "",
  mobilePrivate: "",
  fax: "",
  cityPrivate: "",
  countryPrivate: Country.GERMANY,
  countyPrivate: County.BAVARIA,
  streetNumberPrivate: "",
  streetPrivate: "",
  website: "",
  websitePrivate: "",
  zipPrivate: "",
  county: County.BAVARIA,
});
/**
 * Helper method to get all subordinates for a given user id
 * @param users array of users to search in
 * @param supervisorId if of the user to search for
 * @returns array of subordinate users
 */
export const getSubordinatesForUser = (
  users: SchambeckUser[],
  supervisorId: string
): SchambeckUser[] =>
  users.filter((user) => user.supervisorId === supervisorId);

/**
 * Util to update a user object with the given worktime schedule. Also calculates
 * the weekly total working time.
 *
 * @param user The user to update
 * @param selectedDays The selected days in case the user has daily based schedules
 * @param preciseEntries The precise mapping in case the user has a precise schedule
 * @returns The updated user object
 * @tested
 */
export const generateWorkTimeSchedulesForUser = (
  user: SchambeckUser,
  selectedDays: DayOfWeek[] = [],
  preciseEntries: Map<DayOfWeek, number> = new Map<DayOfWeek, number>()
): SchambeckUser => {
  if (
    !user.contractInformation ||
    (user.contractInformation?.workScheduleDistribution !==
      WorkScheduleDistribution.PRECISE &&
      user.contractInformation?.weeklyWorkingTime <= 0)
  )
    return user;
  let createdSchedules: WorkSchedule[];
  let weeklyWorkingTime: number = user.contractInformation.weeklyWorkingTime;
  switch (user.contractInformation.workScheduleDistribution) {
    case WorkScheduleDistribution.WEEKLY:
      createdSchedules = calculateDailyWorkTimeForWeeklySchedule(
        user.contractInformation.weeklyWorkingTime || 0
      );
      break;
    case WorkScheduleDistribution.DAILY:
      createdSchedules = calculateDailyWorkForGivenDays(
        user.contractInformation.weeklyWorkingTime,
        selectedDays
      );
      break;
    case WorkScheduleDistribution.PRECISE:
      createdSchedules = calculatePreciseWorkScheduleForUser(preciseEntries);
      weeklyWorkingTime = getTotalWorkingTimeFromPreciseMapping(
        user,
        createdSchedules
      );
      break;
  }
  return {
    ...user,
    contractInformation: {
      ...user.contractInformation,
      weeklyWorkingTime,
      workSchedules: createdSchedules,
    },
  };
};

/**
 * Helper method to decide if a user has the needed right to do a action
 * @param userRights the rights of the user
 * @param right the right that is necessary for the action
 * @returns boolean if id the user is allowed to to a action or not
 */
export const isUserAllowedToDo = (
  userRights: UserRight,
  right: Right
): boolean => {
  if (userRights.customRights.get(Right.ALL)) return true;
  else if (userRights.customRights.get(Right.NONE)) return false;
  else if (userRights.customRights.get(right)) return true;
  else if (userRights.role.rights.includes(right)) return true;
  else if (userRights.role.rights.includes(Right.ALL)) return true;
  return false;
};

/**
 * Helper method to toggle a right on a userRole
 * If the role is already present in the role, it is filtered, otherwise it get´s added to the role
 * @param role to update
 * @param right that should be added or removed
 * @returns UserRole with the updated right
 */
export const updateRoleRights = (role: UserRole, right: Right): UserRole => {
  const newRights = role.rights.includes(right)
    ? role.rights.filter((existingRight) => existingRight !== right)
    : [...role.rights, right];
  return { ...role, rights: newRights };
};

/**
 * util method to get the name of a user out of simpleusers by the id
 * @param userId to get the name for
 * @param simpleUsers to search in
 * @returns name of the user or empty string if no user could be found for the id
 */
export const getUserNameForSimpleUser = (
  userId: string,
  simpleUsers: SimpleUser[] | SchambeckUser[] | CustomerUser[]
): string => {
  if (!userId) return "-";

  const user: SimpleUser | CustomerUser | undefined = simpleUsers.find(
    (user) => user.id === userId
  );
  if (!user) return "-";
  return `${user.firstName} ${user.lastName}`;
};

/**
 * Helper to transform the given simpleusers into dropdown options
 *
 * @param allSimpleUser The list of simple users to display
 * @returns Entries for a dropdown with all simple users as Lastname, Firstname
 */
export const generateDropdownOptionsForSimpleUser = (
  allSimpleUser: SimpleUser[]
): Option[] => {
  return allSimpleUser
    .map((user) => ({
      label: `${user.lastName}, ${user.firstName}`,
      value: user.id,
    }))
    .sort((entry1, entry2) => entry1.label.localeCompare(entry2.label));
};

/**
 * Util method to convert user functions into tableRows
 * @param entries to convert
 * @param navigate navigateFunction to handle edit click
 * @returns Array of TableRows
 */
export const convertUserFunctionsIntoTableEntries = (
  entries: UserFunction[],
  navigate: NavigateFunction
): TableRow[] => {
  return entries.map((entry) => ({
    id: entry.id,
    onClick: () => navigate("edit", { state: { entry } }),
    content: [
      entry.title,
      new Date(entry.createDate).toLocaleDateString("de-DE") || "-",
      entry.lastUpdate
        ? new Date(entry.lastUpdate).toLocaleDateString("de-DE")
        : "-",
      <EditIcon
        title={i18n.t("general.icons.edit")}
        width={30}
        onClick={(evt) => {
          evt.stopPropagation();
          navigate("edit", { state: { entry } });
        }}
      />,
    ],
  }));
};

/**
 * Util method to convert users into TableEntries including a checkbox to select them
 * @param users list of users to display
 * @param setSelectedUsers updateMethod to select users
 * @param selectedUsers users where the checkbox is checked
 * @returns Array of TableRows
 */
export const convertUsersIntoSelectableTableRows = (
  users: SimpleUser[],
  setSelectedUsers: Dispatch<SetStateAction<string[]>>,
  selectedUsers: string[]
): TableRow[] => {
  return users.map((loadedUser) => ({
    id: loadedUser.id,
    content: [
      <Checkbox
        isChecked={selectedUsers.includes(loadedUser.id)}
        onCheck={() =>
          setSelectedUsers((prev) =>
            prev.includes(loadedUser.id)
              ? prev.filter((prevUser) => prevUser !== loadedUser.id)
              : [...selectedUsers, loadedUser.id]
          )
        }
      />,
      loadedUser.firstName + " " + loadedUser.lastName,
    ],
  }));
};

/**
 * Util to convert the given schedules into a form so that an Agenda can display it
 *
 * @param schedules All schedule entries to display
 * @param userId The id of the corresponding user
 * @param allProjects List of all projects
 * @param allAbsenceReasons All configured absence reasons
 * @returns The Schedules as agenda entry
 */
export const generateUserAgendaScheduleEntries = (
  schedules: Schedule[],
  userId: string,
  allProjects: Project[],
  allAbsenceReasons: AbsenceReason[]
): AgendaEntryGroup[] => {
  const entries: AgendaEntry[] = schedules.map((schedule) => ({
    id: userId + "/" + schedule.id,
    title:
      schedule.appointmentDetail?.title ||
      allProjects.find((project) => project.id === schedule.referenceId)
        ?.numberRangeNumber ||
      i18n.t("pages.user.create.schedule.projectPlaceholder"),
    background: schedule.appointmentDetail
      ? allAbsenceReasons.find(
          (reason) => reason.id === schedule.appointmentDetail?.reasonId
        )?.color || "lightcyan"
      : "#D3F4E4",
    start: generateIsoDateForDateAndTime(
      schedule.scheduleDate || schedule.startDate!,
      schedule.appointmentDetail?.startTime || 0
    ),
    end: generateIsoDateForDateAndTime(
      schedule.scheduleDate || schedule.endDate!,
      schedule.appointmentDetail?.endTime || 140
    ),
    onClick: () => {},
  }));
  return [{ id: userId, entries, groupName: "" }];
};

/**
 * Util method to generate the Agenda Entries for the PlanningBoard
 * @param schedules to display
 * @param users to display
 * @param allProjects list of all projects to display the projectSchedules
 * @param allAbsenceReasons All possible absenceReasons
 * @returns  Array containing the AgendaEntryGroups
 */
export const generatePlanningBoardEntries = (
  schedules: Schedule[],
  users: SimpleUser[],
  allProjects: Project[],
  allAbsenceReasons: AbsenceReason[]
): AgendaEntryGroup[] => {
  const entries: AgendaEntryGroup[] = [];
  users.forEach((user) => {
    const schedulesForUser = schedules.filter((schedule) => {
      return (
        schedule.referenceId === user.id ||
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Array.from(schedule.scheduledUsers).some(([_, plannedUsers]) =>
          plannedUsers?.some((id) => id === user.id)
        )
      );
    });

    entries.push({
      groupName: getUserNameForSimpleUser(user.id, users),
      entries: generateUserAgendaScheduleEntries(
        schedulesForUser,
        user.id,
        allProjects,
        allAbsenceReasons
      )[0].entries,
      id: user.id,
    });
  });
  return entries;
};

/**
 * Util method to combine multiple agenda Entry groups by their id
 * @param entriesOne Array of entryGroups
 * @param entriesTwo Other array to combine
 * @returns Array containing the combined agendaGroups
 * //TODO TEST ME
 */
export const combineAgendaEntries = (
  entriesOne: AgendaEntryGroup[],
  entriesTwo: AgendaEntryGroup[]
): AgendaEntryGroup[] => {
  const allEntries: AgendaEntryGroup[] = [...entriesOne, ...entriesTwo];

  const combinedEntries: AgendaEntryGroup[] = [];
  allEntries.forEach((entry) => {
    const existingEntry: AgendaEntryGroup | undefined = combinedEntries.find(
      (resultEntry) => resultEntry.id === entry.id
    );

    if (existingEntry) {
      existingEntry.entries = [...existingEntry.entries, ...entry.entries];
    } else {
      combinedEntries.push({ ...entry, entries: [...entry.entries] });
    }
  });

  return combinedEntries;
};

/**
 * Util to check which of the given users can be planned on the planning board
 *
 * @param userList The complete user list
 * @returns Only operational available users
 */
export const filterUserForGChecks = (
  userList: SchambeckUser[]
): SchambeckUser[] => {
  return userList.filter((user) => {
    if (!user.contractInformation.eyeTestDate) return true;
    if (dayjs().isBefore(user.contractInformation.eyeTestDate)) return true;
    if (
      dayjs()
        .subtract(user.contractInformation.eyeTestDeadline || 0, "months")
        .isBefore(user.contractInformation.eyeTestDate)
    )
      return true;
    return false;
  });
};

/**
 * Util method to convert a simple user to tableRows that show the workExpirience at a specific customer
 * @param users array of simpleUsers to convert
 * @param customerId to get the times for
 * @returns  Array of TableRows containing the expirience
 */
export const convertSimpleUsersToTimeAtCustomerTableRows = (
  users: SimpleUser[],
  customerId: string
): TableRow[] => {
  const relevantUsers: SimpleUser[] = users.filter(
    (user) => !!user.customerTimes.get(customerId)
  );
  return relevantUsers.map((user) => ({
    id: user.id,
    content: [
      user.firstName + " " + user.lastName,
      (user.customerTimes.get(customerId)! / 60).toFixed(1),
    ],
  }));
};

/**
 * Util to determine on which date the user propation ends
 *
 * @param entryDate The user entered date on which the employee starts
 * @returns The end date of the propation (+6 Months)
 * @tested
 */
export const updatePropationEnd = (entryDate?: Date): Date | undefined => {
  if (!entryDate || !dayjs(entryDate).isValid()) return;
  return dayjs(entryDate).add(6, "months").toDate();
};
