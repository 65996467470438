/**
 * Interface for OfficialHolidays with date, name and created data
 */

export interface OfficialHoliday {
  id: string;
  createDate: Date;
  createdBy: string;
  date: Date;
  name: string;
  comment: string;
  country: Country;
  offices: string[];
  nationWide: boolean;
  county: County[];
  payment150: boolean;
}
/**
 * County enum for different Countys in Germany to check officialHolidays for
 * !Don`t confuse with country, which is a seperate enum
 */
export enum County {
  BAVARIA = "BAVARIA",
  SAXONY = "SAXONY",
  THURINGIA = "THURINGIA",
  OTHER = "OTHER",
}

/**
 * CountryCode enum according API requierements from https://openholidaysapi.org/swagger/
 */
export enum CountryCode {
  DE = "DE",
  CZ = "CZ",
  SK = "SK",
}
/**
 * interface for response of openHolidayAPI, see more here: https://openholidaysapi.org/swagger/index.html
 */
export interface HolidayResponse {
  comment?: LocalizedText[];
  endDate: string;
  id: string;
  name: LocalizedText[];
  nationwide: boolean;
  startDate: string;
  subdivisions: SubdivisionReference[];
  holidayType: HolidayType;
}
/**
 * LocalizedText with language and text that is used in the response
 */
export interface LocalizedText {
  language: string;
  text: string;
}
/**
 * References for subdivision references including code and shortName of the subdivision
 */
export interface SubdivisionReference {
  code: string;
  shortName: string;
}

/**
 * enum of holydayTypes that are possible from the api
 */
export enum HolidayType {
  PUBLIC = "PUBLIC",
  BANK = "BANK",
  SCHOOL = "SCHOOL",
  BACKTOSCHOOL = "BACKTOSCHOOL",
  ENDOFLESSONS = "ENDOFLESSONS",
}
/**
 * enum of different countrys to choose
 */
export enum Country {
  GERMANY = "GERMANY",
  CZECH = "CZECH",
  SLOWAKIA = "SLOWAKIA",
}
