import { Button, Dropdown, Input, Popup, Wysiwyg } from "@sam/components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Language, MailConfiguration, getPopulatedMailTemplate } from "shared";
import { useUser } from "../UserContext";

interface MailConfigProps {
  isOpen: boolean;
  toggleOpen(open: boolean): void;
  updateConfig(updatedConfig: MailConfiguration): void;
  mailConfig: MailConfiguration;
  children?: React.ReactNode;
  sendMail(): void;
  referenceId: string;
  userId: string;
}

/**
 * Holds the basic mail information
 */
export const MailPopup: React.FC<MailConfigProps> = ({
  mailConfig,
  isOpen,
  toggleOpen,
  updateConfig,
  sendMail,
  children,
  referenceId,
  userId,
}) => {
  const { t } = useTranslation();
  const { axios } = useUser();

  // load the correct template for the given type (does it only once during startup)
  useEffect(() => {
    if (referenceId && userId) {
      getPopulatedMailTemplate(
        axios,
        mailConfig.type,
        referenceId,
        userId,
        mailConfig.language || Language.DE
      ).then((template) =>
        updateConfig({
          ...mailConfig,
          content:
            template?.mailContent.get(mailConfig.language || Language.DE) || "",
          subject:
            template?.subject.get(mailConfig.language || Language.DE) || "",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailConfig.type, referenceId, userId, isOpen]);

  return (
    <>
      <Popup
        isOpen={isOpen}
        onClose={() => toggleOpen(false)}
        buttons={[
          <Button
            value={t("general.buttons.sendAndSave")}
            onClick={sendMail}
          />,
          <Button
            value={t("general.buttons.cancel")}
            onClick={() => toggleOpen(false)}
          />,
        ]}
        title={t("pages.invoice.edit.mailPopup")}
      >
        <>
          <Input
            type="text"
            value={mailConfig.receiver}
            label={t("pages.invoice.edit.invoiceMailRecipient")}
            disabled
          />
          <Dropdown
            multi
            create
            onChangeMultiple={(receiverCC) => {
              updateConfig({ ...mailConfig, receiverCC });
            }}
            label={t("pages.invoice.edit.invoiceMailRecipientCC")}
          />
          <Input
            label={t("pages.invoice.edit.subject")}
            type="text"
            value={mailConfig.subject}
            onChange={(subject) => updateConfig({ ...mailConfig, subject })}
          />
          <Wysiwyg
            initialContent={mailConfig.content}
            onChange={(content) => updateConfig({ ...mailConfig, content })}
          />
        </>
        <div className="mail-popup__additional__wrapper">{children}</div>
      </Popup>
    </>
  );
};
