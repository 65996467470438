import { Button, Input, TopBar } from "@sam/components";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Holiday,
  calculateVacationDaysForDateRange,
  createHoliday,
  generateNotification,
  getDateWithoutTime,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import dayjs from "shared/src/tools/Dayjs";
import { Right } from "shared/src/userRole/UserRole.types";
import { NotAllowed } from "../../components/NotAllowed";
import { useUser } from "../../components/UserContext";
import { createComment } from "../../utils/comment/Comment.utils";
import { generateEmptyHoliday } from "../../utils/holiday/Holiday.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

const HolidayCreate: React.FC<{ readOnly: boolean }> = ({ readOnly }) => {
  const { axios, user } = useUser();
  const { t } = useTranslation();
  const location = useLocation<{ holiday: Holiday }>();
  const [holiday, setHoliday] = useState<Holiday>(
    location.state?.holiday || generateEmptyHoliday({ userId: user.id })
  );
  const [requestComment, setRequestComment] = useState<string>(
    holiday.requestComment?.content || ""
  );
  const [decisionComment, setDecisionComment] = useState<string>(
    holiday.decisionComment?.content || ""
  );

  const { data: officialHolidays } = useData("OFFICIAL_HOLIDAYS_ALL", {
    config: { fallbackData: [] },
  });

  const navigate = useNavigate();

  /**
   * Submit handler for creating holiday
   * @param event HTML Form Event
   */
  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    createHoliday(
      {
        ...holiday,
        requestComment: createComment(user.id, requestComment),
        decisionComment: createComment(user.id, decisionComment),
      },
      axios
    ).then((createdHoliday) => {
      if (createdHoliday) navigate("/holiday");
    });
  };

  // useCallback to check for the totalAmount of vacationDays used for a range
  const calculateVacationDays: () => Promise<void> = useCallback(async () => {
    if (!holiday.startDate || !holiday.endDate) return;

    const officialHolidaysInRange: dayjs.Dayjs[] =
      officialHolidays
        .map((officialHoliday) => dayjs(officialHoliday.date))
        .filter(
          (holidayToCheck) =>
            holidayToCheck.isSameOrAfter(holiday.startDate) &&
            (holidayToCheck.isBefore(holiday.endDate) ||
              holidayToCheck.isSame(holiday.endDate))
        ) || [];

    const vacationDays: number | undefined = calculateVacationDaysForDateRange(
      holiday.startDate,
      holiday.endDate,
      user.contractInformation.workSchedules,
      officialHolidaysInRange
    );
    if (!vacationDays) {
      generateNotification({
        type: NotificationType.ERROR,
        value: t("general.notification.error.calculateVacationDays"),
      });
      return;
    }
    setHoliday((currentHoliday) => ({
      ...currentHoliday,
      totalAmount: vacationDays,
    }));
  }, [user, holiday.startDate, holiday.endDate, officialHolidays, t]);

  // UseEffect to update totalAmount if dates change
  useEffect(() => {
    calculateVacationDays();
  }, [calculateVacationDays]);

  return isUserAllowedToDo(user.right, Right.HOLIDAY_CREATE) ? (
    <>
      <form onSubmit={handleSubmit}>
        <TopBar
          onBackClick={() => navigate(-1)}
          title={
            readOnly
              ? t("pages.holiday.createOrEdit.readHeadline")
              : t("pages.holiday.createOrEdit.createHeadline")
          }
        >
          <Button
            disabled={readOnly}
            value={t("general.buttons.save")}
            type="submit"
            maxWidth={"100px"}
          />
        </TopBar>
        <Input
          onChangeDate={() => {}}
          disabled
          type="date"
          value={holiday.createDate}
          label={t("pages.holiday.createOrEdit.createDate")}
        />
        <Input
          disabled={readOnly}
          type="date"
          value={getDateWithoutTime(holiday.startDate)}
          label={t("pages.holiday.createOrEdit.startDate")}
          onChangeDate={(startDate) => {
            if (startDate) setHoliday({ ...holiday, startDate });
          }}
        />
        <Input
          disabled={readOnly}
          type="date"
          value={getDateWithoutTime(holiday.endDate)}
          label={t("pages.holiday.createOrEdit.endDate")}
          onChangeDate={(endDate) => {
            if (endDate) setHoliday({ ...holiday, endDate });
          }}
        />
        <Input
          onChange={() => {}}
          label={t("pages.holiday.createOrEdit.amount")}
          type="text"
          disabled
          value={holiday.totalAmount}
        />
        <Input
          disabled={readOnly}
          type="text"
          value={requestComment}
          label={t("pages.holiday.createOrEdit.requestComment")}
          onChange={(requestComment) => setRequestComment(requestComment)}
        />
        {holiday.id && (
          <Input
            disabled={readOnly}
            type="text"
            value={decisionComment}
            label={t("pages.holiday.createOrEdit.decisionComment")}
            onChange={(decisionComment) => setDecisionComment(decisionComment)}
          />
        )}
      </form>
    </>
  ) : (
    <NotAllowed neccessaryRight={Right.HOLIDAY_CREATE} />
  );
};
export default HolidayCreate;
