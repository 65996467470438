import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useData } from "shared";
import { Right } from "shared/src/userRole/UserRole.types";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { NotAllowed } from "../../components/NotAllowed";
import { useUser } from "../../components/UserContext";
import { convertOfficesIntoTableEntries } from "../../utils/office/Office.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

const OfficeOverview: React.FC = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: offices } = useData("OFFICES_ALL", {
    config: { fallbackData: [] },
  });
  const [filterValue, setFilterValue] = useState<string>("");
  //Holds tableRows for offices
  const rows: TableRow[] = useMemo(
    () => convertOfficesIntoTableEntries(user.right, offices, navigate),
    [user.right, offices, navigate]
  );

  return isUserAllowedToDo(user.right, Right.OFFICE_OVERVIEW) ? (
    <>
      <TopBar
        title={t("pages.office.overview.headline")}
        onSearch={setFilterValue}
      >
        {isUserAllowedToDo(user.right, Right.OFFICE_CREATE) && (
          <PlusIcon
            title={t("general.icons.add")}
            onClick={() => navigate("/office/edit")}
            width={40}
          />
        )}
      </TopBar>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.office.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  ) : (
    <NotAllowed neccessaryRight={Right.OFFICE_OVERVIEW} />
  );
};
export default OfficeOverview;
