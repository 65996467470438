import { Box, Button, Popup, Table, TableRow, TopBar } from "@sam/components";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Branche, useData } from "shared";

import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useNavigate } from "react-router-dom";
import {
  countBrancheDeleteAffectedEntities,
  deleteBranche,
} from "shared/src/branche/Branche.axios";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { convertBranchesIntoTableEntries } from "../../utils/branche/Branche.utils";

export const BranchesOverview: React.FC = () => {
  const { t } = useTranslation();
  const { axios } = useUser();
  const navigate = useNavigate();
  const [brancheToDelete, setBrancheToDelete] = useState<Branche>();
  const [deletedDependenciesCount, setDeleteCount] = useState<number>(-1);
  const { data: loadedBranches, mutate: updateBranches } = useData(
    "BRANCHES_ALL",
    {
      config: { fallbackData: [] },
    }
  );

  /**
   * Helper to toggle the popup open to warn before the actual deletion
   */
  const handleDeleteClick = useCallback(
    (branche: Branche): void => {
      setBrancheToDelete(branche);
      countBrancheDeleteAffectedEntities(branche.id, axios).then(
        setDeleteCount
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brancheToDelete]
  );

  /**
   * Util to actually delete the branche and close the popup
   */
  const deleteTheBranche = (): void => {
    if (!brancheToDelete) return;
    deleteBranche(brancheToDelete?.id, axios).then(() => {
      updateBranches();
      resetDelete();
    });
  };

  /**
   * Helper to quickly reset all helper variables for the deletion
   */
  const resetDelete = (): void => {
    setBrancheToDelete(undefined);
    setDeleteCount(-1);
  };

  //TableRows to be displayed in the table
  const rows: TableRow[] = useMemo(() => {
    if (!loadedBranches) return [];
    return convertBranchesIntoTableEntries(
      loadedBranches,
      navigate,
      handleDeleteClick
    );
  }, [handleDeleteClick, loadedBranches, navigate]);

  return (
    <>
      <TopBar title={t("pages.branches.overview.topBarHeadline")}>
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("/branche/edit")}
        />
      </TopBar>
      <Popup
        isOpen={deletedDependenciesCount > -1}
        onClose={resetDelete}
        buttons={[
          <Button value={"Abbrechen"} onClick={resetDelete} />,
          <Button
            value={"Löschen"}
            backgroundColor="#bc2e46"
            onClick={deleteTheBranche}
          />,
        ]}
      >
        <h1>
          {t("pages.branches.overview.deletePopup.title", {
            replace: { NAME: brancheToDelete?.name },
          })}
        </h1>
        <p>
          {t("pages.branches.overview.deletePopup.content", {
            replace: { AMOUNT: deletedDependenciesCount },
          })}
        </p>
      </Popup>
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.branches.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
