import { AxiosInstance } from "axios";
import { Absence } from "./Absence.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";

/**
 * API method to create a new Absence on the backend
 * @param axios instance of axios
 * @param absence to create
 * @returns created Absence or undefined
 */
export const createNewAbsence = async (
  axios: AxiosInstance,
  absence: Absence
): Promise<Absence | undefined> => {
  return axios
    .post("/absence/", absence)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewAbsence"),
      });
      console.error("Error while saving absence", exc);
      return undefined;
    });
};

/**
 * API method to get all absences
 * @param axios  instance of axios
 * @returns  array of all absences
 */
export const getAllAbsences = async (
  axios: AxiosInstance
): Promise<Absence[]> => {
  return axios
    .get("/absence/")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading absences", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllAbsences"),
      });
      return [];
    });
};

/**
 * API method to get the Absences for a user
 * @param axios instance of axios
 * @param userId id of the user to load the absences
 * @returns Promise containing an array with the found absences
 */
export const getAllAbsencesForUser = async (
  axios: AxiosInstance,
  userId: string
): Promise<Absence[]> => {
  return axios
    .get("/absence/user", { params: { id: userId } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading absences", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllAbsencesForUser"),
      });
      return [];
    });
};
