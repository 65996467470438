import {
  Box,
  Button,
  Input,
  Popup,
  Table,
  TableRow,
  TopBar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import React, { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  SimpleUser,
  TimeTracking,
  createNewTimeTracking,
  deleteTimeTracking,
  generateNotification,
  getTimeSheetForMonth,
  loadSimpleUserById,
  updateTimeTracking,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { Right } from "shared/src/userRole/UserRole.types";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as LoadingCircle } from "../../assets/loading.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { NotAllowed } from "../../components/NotAllowed";
import { useUser } from "../../components/UserContext";
import { GenerateMonthlyOverviewBoxes } from "../../components/timeTrackingOverviewBoxes/TimeTrackingOverviewBoxes";
import { downloadFile } from "../../utils/files/Files.utils";
import { addTimeTrackingIndexedDB } from "../../utils/indexedDB/db.utils";
import {
  calculateBreakTime,
  convertTimeTrackingsIntoTableEntries,
  endTimeTracking,
  generateEmptyTimeTracking,
  getCorrectDisplayText,
  getTimeTrackingFromIndexedOrMongoDB,
} from "../../utils/timeTracking/TimeTracking.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";
import dayjs from "shared/src/tools/Dayjs";

const TimeKeepingPage: React.FC = () => {
  const { axios, user } = useUser();
  const [activeTracking, setActiveTracking] = useState<TimeTracking>();
  const [showWarningPopup, toggleShowWarningPopup] = useState<boolean>(false);
  const { t } = useTranslation();
  const isInitialRender = useRef(true);
  const location = useLocation<{ autostart?: boolean; userId?: string }>();
  const [selectedUserId] = useState<string>(location.state?.userId || user.id);
  const [selectedSimpleUser, setSelectedSimpleUser] = useState<SimpleUser>();
  const dateNow: Date = new Date();
  //selected date for timeCard download, [month, year]
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState<
    [number, number]
  >([dateNow.getMonth(), dateNow.getFullYear()]);

  const [trackingToEdit, setTrackingToEdit] = useState<TimeTracking>();
  const [trackingToDelete, setTrackingToDelete] = useState<string>();

  const { data: timeTrackings, mutate } = useData("TIMETRACKING_ALL_USER", {
    params: { userId: selectedUserId },
    config: { fallbackData: [] },
  });

  //UseEffect to load a simpleUser by id
  useEffect(() => {
    if (!selectedUserId) return;
    loadSimpleUserById(selectedUserId, axios).then(setSelectedSimpleUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId]);

  /**
   * UseEffect hook to check for existing timeTracking on indexedDB and in mongoDB, if nothing is found in indexedDB
   * With the loaded tracking, the break and workTime until now is calculated
   * Also checks if the user was redirected from the NFC page and takes action according to it
   */
  useEffect(() => {
    if (
      activeTracking ||
      !user ||
      !isUserAllowedToDo(user.right, Right.TIME_CREATE)
    )
      return;
    // Helper to avoid duplicates because of the React render cycle
    if (!isInitialRender.current) return;
    if (isInitialRender.current) {
      if (selectedUserId === user.id) {
        getTimeTrackingFromIndexedOrMongoDB(axios, selectedUserId).then(
          (loadedTracking) => {
            //no loaded tracking and autostart => start new TimeTracking
            if (!loadedTracking && location.state?.autostart)
              handleStartTracking();
            //no loaded tracking and no autostart  => do nothing
            if (!loadedTracking) return;
            // loaded tracking and autostart => end Tracking
            if (loadedTracking && location.state?.autostart)
              !loadedTracking.endTime &&
                handleEndTracking({
                  ...loadedTracking,
                  breakTime: calculateBreakTime(loadedTracking.startTime),
                });
            //loaded tracking and no autostart => setActiveTracking
            setActiveTracking({
              ...loadedTracking,
              breakTime: calculateBreakTime(loadedTracking.startTime),
            });
          }
        );
      }
      isInitialRender.current = false;
      // needed so a reload does not trigger another autostart
      history.replaceState({ autostart: false }, "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, activeTracking]);

  //Holds the tableRows for timeTrackings
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertTimeTrackingsIntoTableEntries(
        user,
        timeTrackings.filter((timeTracking) => {
          const startDate: Date = timeTracking.startDate;
          return (
            startDate.getMonth() === selectedMonthAndYear[0] &&
            startDate.getFullYear() === selectedMonthAndYear[1]
          );
        }),
        setTrackingToEdit,
        isUserAllowedToDo(user.right, Right.TIME_EDIT)
          ? setTrackingToDelete
          : undefined
      ),

    [user, timeTrackings, selectedMonthAndYear]
  );

  //Hook to update the totalWorkTIme if a timeTracking gets edited everytime the start, end or breakTime gets changed
  useEffect(() => {
    if (!trackingToEdit) return;
    const newTotalWorkTime: number =
      trackingToEdit.endTime -
      trackingToEdit.startTime -
      trackingToEdit.breakTime;
    setTrackingToEdit({ ...trackingToEdit, totalWorkTime: newTotalWorkTime });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trackingToEdit?.startTime,
    trackingToEdit?.endTime,
    trackingToEdit?.breakTime,
  ]);

  /**
   * Helper method to end timeTracking and save updated objects on MongoDB and indexedDB
   * @returns void
   */
  const handleEndTracking = async (
    trackingToEnd: TimeTracking
  ): Promise<void> => {
    if (!activeTracking) return;
    endTimeTracking(user.id, trackingToEnd, axios).then((updatedTracking) => {
      if (updatedTracking) setActiveTracking(updatedTracking);
    });
  };

  /**
   * Helper method to start timeTracking
   */
  const handleStartTracking = (): void => {
    createNewTimeTracking(axios, generateEmptyTimeTracking(user.id)).then(
      (createdTracking) => {
        if (createdTracking) {
          setActiveTracking(createdTracking);
          addTimeTrackingIndexedDB(createdTracking);
        }
      }
    );
  };
  /**
   * Helper method to handle click on stop while timeTracking is active
   * Checks if the breaktime is correct and opens comfirmation popup if not
   * @returns void
   */
  const handleStopTracking = (): void => {
    if (!activeTracking) return;
    const expectedBreakTime: number = calculateBreakTime(
      activeTracking.startTime
    );
    expectedBreakTime > activeTracking.breakTime
      ? toggleShowWarningPopup(true)
      : handleEndTracking(activeTracking);
  };
  /**
   * Helper method to create a timeCard and start the download
   * @param evt FormEvent
   */
  const handleDownloadTimeSheet = async (): Promise<void> => {
    const timeCard: Blob = await getTimeSheetForMonth(axios, selectedUserId, [
      selectedMonthAndYear[0] + 1,
      selectedMonthAndYear[1],
    ]);
    const fileName: string = `${
      selectedSimpleUser?.firstName || user.firstName
    }-${selectedSimpleUser?.lastName || user.lastName}_${
      selectedMonthAndYear[0] + 1
    }_${selectedMonthAndYear[1]}.pdf`;
    downloadFile(timeCard, fileName);
  };

  /**
   * Helper method to update a timeTracking object
   * @param event FormEvent
   * @returns void
   */
  const handleUpdateTracking = (event: FormEvent): void => {
    event.preventDefault();
    if (!trackingToEdit) return;
    const promise: Promise<TimeTracking | undefined> = trackingToEdit.id
      ? updateTimeTracking(axios, trackingToEdit)
      : createNewTimeTracking(axios, trackingToEdit);

    promise.then((updatedTracking) => {
      if (!updatedTracking) return;
      setTrackingToEdit(undefined);
      mutate();
    });
  };

  /**
   * Helper method to delete a timeTracking
   * @param trackingId id of the tracking to delete
   */
  const handleDeleteTimeTracking = (trackingId: string): void => {
    deleteTimeTracking(axios, trackingId).then((success) => {
      if (success) {
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.deleteTimeTracking"),
        });
        setTrackingToDelete(undefined);
        mutate();
      }
    });
  };

  return isUserAllowedToDo(user.right, Right.TIME_CREATE) ? (
    <>
      <TopBar
        title={t("pages.timekeeping.headline", {
          replace: {
            username: `${selectedSimpleUser?.firstName || user.firstName} ${
              selectedSimpleUser?.lastName || user.lastName
            }`,
          },
        })}
      >
        <div className="topbar__content hide-on-small">
          {isUserAllowedToDo(user.right, Right.TIME_EXPORT) && (
            <DownloadIcon onClick={handleDownloadTimeSheet} />
          )}
        </div>
      </TopBar>
      <Popup
        title={t("pages.timekeeping.editTrackingPopup")}
        isOpen={!!trackingToEdit}
        onClose={() => setTrackingToEdit(undefined)}
      >
        <form onSubmit={handleUpdateTracking}>
          <Input
            onChangeDate={(startDate) =>
              trackingToEdit &&
              !trackingToEdit?.id &&
              startDate &&
              setTrackingToEdit({ ...trackingToEdit, startDate })
            }
            type="date"
            value={trackingToEdit?.startDate || ""}
            disabled={!!trackingToEdit?.id}
            label={t("pages.timekeeping.date")}
          />
          <Input
            type="number"
            value={trackingToEdit?.breakTime || 0}
            label={t("pages.timekeeping.breakTimeMinutes")}
            onChangeNumber={(breakTime) => {
              if (!trackingToEdit) return;
              setTrackingToEdit({
                ...trackingToEdit,
                breakTime,
              });
            }}
          />
          <Input
            type="time"
            label={t("pages.timekeeping.start")}
            value={trackingToEdit?.startTime}
            onChangeTime={(startTime) =>
              setTrackingToEdit({ ...trackingToEdit!, startTime })
            }
          />
          <Input
            type="time"
            label={t("pages.timekeeping.end")}
            value={trackingToEdit?.endTime}
            onChangeTime={(endTime) =>
              setTrackingToEdit({ ...trackingToEdit!, endTime })
            }
          />
          <Input
            type="time"
            label={t("pages.timekeeping.trackedTime")}
            value={trackingToEdit?.totalWorkTime}
            disabled
            onChangeTime={() => {}}
          />
          <Input
            type="text"
            label={t("pages.timekeeping.comment")}
            value={trackingToEdit?.comment}
            onChange={(comment) =>
              trackingToEdit &&
              setTrackingToEdit({ ...trackingToEdit, comment })
            }
          />
          <Button
            type="submit"
            value={t("general.buttons.save")}
            disabled={
              trackingToEdit &&
              !trackingToEdit.id &&
              timeTrackings.some((tracking) =>
                dayjs(tracking.startDate).isSame(trackingToEdit?.startDate)
              )
            }
          />
          <Button
            backgroundColor="#BC2E46"
            type="button"
            value={t("general.buttons.cancel")}
            onClick={() => setTrackingToEdit(undefined)}
          />
        </form>
      </Popup>
      <div className="timetracking__container">
        <Popup
          isOpen={showWarningPopup}
          onClose={() => toggleShowWarningPopup(false)}
          title={t("pages.timekeeping.breakTimePopup")}
          buttons={[
            <Button
              value={t("general.buttons.yes")}
              onClick={() => {
                if (!activeTracking) return;
                toggleShowWarningPopup(false);
                handleEndTracking(activeTracking);
              }}
            />,
            <Button
              value={t("general.buttons.no")}
              onClick={() => toggleShowWarningPopup(false)}
            />,
          ]}
        >
          <p>{t("pages.timekeeping.popup")}</p>
        </Popup>
        {trackingToDelete && (
          <Popup
            isOpen={!!trackingToDelete}
            buttons={[
              <Button
                value={t("general.buttons.cancel")}
                onClick={() => setTrackingToDelete(undefined)}
              />,
              <Button
                value={t("general.buttons.delete")}
                type="button"
                onClick={() => handleDeleteTimeTracking(trackingToDelete)}
              />,
            ]}
          >
            <p>{t("pages.timekeeping.deletePopupText")}</p>
          </Popup>
        )}
        {user.id === selectedUserId && (
          <Box>
            <div className="worktime-display__container">
              <p className="worktime-display__headline">
                {t("pages.timekeeping.today")}
              </p>
              <div className="worktime-display__content-wrapper">
                <div className="worktime-display__worktime">
                  {activeTracking && activeTracking.endTime === 0 && (
                    <LoadingCircle
                      width={20}
                      className="worktime-display__icon"
                    />
                  )}
                  <p className="worktime-display__time">
                    {getCorrectDisplayText(activeTracking)}
                  </p>
                </div>
                <div className="worktime-display__input-wrapper">
                  <p className="worktime-display__input-wrapper__break">
                    {t("pages.timekeeping.break")}:
                  </p>
                  <Input
                    disabled={
                      !activeTracking ||
                      (!!activeTracking && activeTracking?.endTime !== 0)
                    }
                    maxWidth={100}
                    value={activeTracking?.breakTime || 0}
                    type="number"
                    onChangeNumber={(breakTime) => {
                      if (!activeTracking) return;
                      if (breakTime)
                        setActiveTracking({
                          ...activeTracking,
                          breakTime,
                        });
                    }}
                  />
                </div>
                <div className="worktime-display__input-wrapper">
                  <p className="worktime-display__input-wrapper__comment">
                    {t("pages.timekeeping.comment")}:
                  </p>
                  <Input
                    maxWidth={200}
                    type="text"
                    value={activeTracking?.comment}
                    onChange={(comment) =>
                      activeTracking &&
                      setActiveTracking({ ...activeTracking, comment })
                    }
                  />
                </div>
              </div>
              {activeTracking && activeTracking.endTime === 0 && (
                <Button
                  maxWidth={300}
                  backgroundColor="#BC2E46"
                  value={t("general.buttons.stop")}
                  onClick={() => {
                    handleStopTracking();
                  }}
                />
              )}
              {activeTracking && activeTracking.endTime !== 0 && (
                <Button
                  maxWidth={300}
                  disabled
                  backgroundColor="#545454"
                  value={t("general.buttons.done")}
                />
              )}
              {!activeTracking && (
                <Button
                  maxWidth={300}
                  value={t("general.buttons.start")}
                  onClick={() => {
                    handleStartTracking();
                  }}
                />
              )}
            </div>
          </Box>
        )}
        <Box>
          <>
            {timeTrackings && (
              <GenerateMonthlyOverviewBoxes
                selectedMonthAndYear={selectedMonthAndYear}
                setSelectedMonthAndYear={setSelectedMonthAndYear}
                timeTrackings={timeTrackings}
              />
            )}
          </>
        </Box>
        {timeTrackings && (
          <Box>
            <div className="timetracking-overview__table-wrapper">
              <p className="timetracking-overview__table-headline">
                {t(`general.month.${selectedMonthAndYear[0]}`)}
              </p>
              <PlusIcon
                title={t("general.icons.add")}
                className="timetracking-overview__plus-icon"
                onClick={() =>
                  setTrackingToEdit(generateEmptyTimeTracking(selectedUserId))
                }
              />
              <Table
                rows={rows}
                header={
                  t("pages.timekeeping.tableHeader", {
                    returnObjects: true,
                  }) as TableHeader[]
                }
              />
            </div>
          </Box>
        )}
      </div>
    </>
  ) : (
    <NotAllowed neccessaryRight={Right.TIME_CREATE} />
  );
};
export default TimeKeepingPage;
