import { Calendar } from "devduck-year-calendar";
import { CalendarProps } from "./YearlyCalendar.types";
import dayjs from "dayjs";

export const YearlyCalendar: React.FC<CalendarProps> = ({
  year,
  selectRange = false,
  showCurrentMonthOnlyOnMobile = false,
  onPickDate = () => {},
  selectedDay,
  selectedRange,
  onPickRange = () => {},
  customClasses,
}) => {
  return (
    <div className="yearly-calendar__wrapper">
      <Calendar
        selectedDay={dayjs(selectedDay)}
        year={year}
        month={new Date().getMonth()}
        selectRange={selectRange}
        showCurrentMonthOnlyOnMobile={showCurrentMonthOnlyOnMobile}
        onNextMonth={() => {}}
        onPrevMonth={() => {}}
        onPickDate={(date) => onPickDate(date.toDate())}
        onPickRange={(start, end) => onPickRange(start.toDate(), end.toDate())}
        selectedRange={selectedRange?.map((date) => dayjs(date))}
        customClasses={customClasses}
      />
    </div>
  );
};
