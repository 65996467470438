import { AxiosInstance } from "axios";
import { AbsenceReason } from "./AbsenceReason.types";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import i18n from "../../../client/src/i18n/i18n";

/**
 * API Method to get all absenceReasons
 * @param axios instance of axios
 * @returnsarray of found reasons
 */
export const getAllAbsenceReasons = async (
  axios: AxiosInstance
): Promise<AbsenceReason[]> => {
  return axios
    .get("/absence/reason/all")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllAbsenceReasons"),
      });
      console.error("Error while loading absence reasons", exc);
      return [];
    });
};

/**
 * API Method to get all active absenceReasons
 * @param axios instance of axios
 * @returnsarray of found reasons
 */
export const getAllActiveAbsenceReasons = async (
  axios: AxiosInstance
): Promise<AbsenceReason[]> => {
  return axios
    .get("/absence/reason/all/active")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllAbsenceReasons"),
      });
      console.error("Error while loading absence reasons", exc);
      return [];
    });
};

/**
 * API Method to create new AbsenceReason
 * @param axios instance of axios
 * @param absenceReason to create
 * @returns created Reason or undefined
 */
export const createNewAbsenceReason = async (
  axios: AxiosInstance,
  absenceReason: AbsenceReason
): Promise<AbsenceReason | undefined> => {
  return axios
    .post("/absence/reason/create", absenceReason)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewAbsenceReason"),
      });
      console.error("Error while creating absenceReason", exc);
      return undefined;
    });
};

/**
 * API Method to update an AbsenceReason
 * @param axios instance of axios
 * @param absenceReason updated absenceReason object
 * @returns  updated reason or undefined
 */
export const updateAbsenceReason = async (
  axios: AxiosInstance,
  absenceReason: AbsenceReason
): Promise<AbsenceReason | undefined> => {
  return axios
    .post("/absence/reason/update", absenceReason)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewAbsenceReason"),
      });
      console.error("Error while updating reason", exc);
      return undefined;
    });
};
