import { Box, Button, Input, TopBar } from "@sam/components";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Project, WorkInstruction } from "shared/src/project/Project.types";
import { generateNewWorkInstruction } from "../../utils/project/Project.utils";
import { useUser } from "../../components/UserContext";
import { updateProject } from "shared/src/project/Project.axios";
import { Order } from "shared";

export const WorkInstructionEdit: React.FC = () => {
  const { axios } = useUser();
  const location = useLocation<{
    project: Project;
    instruction?: WorkInstruction;
    order?: Order;
  }>();
  const [instruction, setInstruction] = useState<WorkInstruction>(
    location.state?.instruction ||
      generateNewWorkInstruction({
        projectId: location.state?.project?.id || "",
        index: location.state?.project?.instructions.length || 0 + 1,
      })
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  /**
   * Helper method to update an existing instruction or add a new one
   * @param evt FormEvent to prevent default behaviour
   */
  const handleSubmit = (evt: FormEvent): void => {
    evt.preventDefault();
    //If an instruction was passed we want to edit it
    if (location.state?.instruction && location.state.project)
      updateProject(axios, {
        ...location.state.project,
        instructions: location.state.project.instructions.map(
          (existingInstruction) =>
            existingInstruction.id === instruction.id
              ? instruction
              : existingInstruction
        ),
      }).then((updatedProject) => {
        if (updatedProject)
          navigate("/project/edit", {
            state: { project: updatedProject, order: location.state?.order },
          });
      });
    //if no instruction was passed we add a new one
    else if (location.state?.project)
      updateProject(axios, {
        ...location.state.project,
        instructions: [...location.state.project.instructions, instruction],
      }).then((updatedProject) => {
        if (updatedProject && location.state?.order)
          navigate("/project/edit", {
            state: {
              project: updatedProject,
              order: location.state?.order,
            },
          });
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          location.state?.instruction
            ? t("pages.workInstructions.topBarHeadlineEdit")
            : t("pages.workInstructions.topBarHeadlineCreate")
        }
      >
        <Button value={t("general.buttons.save")} type="submit" />
      </TopBar>
      <Box title={t("pages.workInstructions.content")}>
        <Input
          type="text"
          value={instruction.content}
          onChange={(content) => setInstruction({ ...instruction, content })}
        />
      </Box>
      {location.state?.instruction && (
        <Box title={"Einweisung"}>
          <div className="three-columns">
            <Input
              label={t("pages.workInstructions.instructionAt")}
              value={instruction.instructionTimeStamp || new Date()}
              type="date"
              onChangeDate={(instructionTimeStamp) =>
                instructionTimeStamp &&
                setInstruction({ ...instruction, instructionTimeStamp })
              }
            />
            <Input
              value={instruction.instructionBy || ""}
              type="text"
              label={t("pages.workInstructions.instructionBy")}
              onChange={(instructionBy) =>
                setInstruction({ ...instruction, instructionBy })
              }
            />
            <Input
              value={instruction.instructionFrom || ""}
              type="text"
              label={t("pages.workInstructions.instructionFrom")}
              onChange={(instructionFrom) =>
                setInstruction({ ...instruction, instructionFrom })
              }
            />
          </div>
        </Box>
      )}
    </form>
  );
};
