import {
  Box,
  Button,
  Checkbox,
  Input,
  Popup,
  Table,
  TextArea,
  TopBar,
} from "@sam/components";
import { TableHeader, TableRow } from "@sam/components/src/Table/Table.types";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  generateNotification,
  StandardPriceType,
  StandardPriceTypeAlreadyTakenException,
  Task,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { createNewTask, updateTask } from "shared/src/tasks/Task.axios";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { generateEmptyTask } from "../../utils/task/task.utils";
import { isPercentagePrice } from "../../utils/order/Order.utils";

export const TaskCreateOrEdit: React.FC = () => {
  const { axios } = useUser();
  const { t } = useTranslation();
  const [showStandardPricePopup, toggleStandardPricePopup] =
    useState<boolean>(false);
  const location = useLocation<{ task?: Task }>();
  const [taskToEdit, setTaskToEdit] = useState<Task>(
    location.state?.task ? location.state.task : generateEmptyTask()
  );
  const navigate = useNavigate();
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const { data: allOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const { data: allCurrencies } = useData("CURRENCY_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  /**
   * boolean if the task price is in € or %
   */
  const isPercentage: boolean = useMemo(
    (): boolean => isPercentagePrice(taskToEdit.standardType),
    [taskToEdit.standardType]
  );

  /**
   * Util method to get the correct tableEntries for a specific office
   * @param officeId id of the office to display the prices
   * @returns Array containing tableRows
   */
  const getCurrencyPriceTables = useCallback(
    (officeId: string): TableRow[] => {
      return allCurrencies
        .map((currency) => ({
          id: currency.id,
          content: [
            currency.title,
            <Input
              type="number"
              onChangeNumber={(newValue) => {
                const values: Map<string, Map<string, number>> = new Map(
                  taskToEdit.officePrices
                );
                if (values.get(officeId))
                  values.get(officeId)?.set(currency.id, newValue);
                else {
                  const newEntry: Map<string, number> = new Map<
                    string,
                    number
                  >();
                  newEntry.set(currency.id, newValue);
                  values.set(officeId, newEntry);
                }

                setTaskToEdit({ ...taskToEdit, officePrices: values });
              }}
              value={taskToEdit.officePrices?.get(officeId)?.get(currency.id)}
            />,
            isPercentage ? "%" : currency.symbol,
          ],
        }))
        .sort((a, b) =>
          a.content[0].toString().localeCompare(b.content[0].toString())
        );
    },
    [allCurrencies, taskToEdit]
  );

  /**
   * Helper method to decide if a new task should be created,
   *  or if it should update an existing task
   */
  const handleSubmit = (
    redirect: boolean,
    withoutStandardCheck: boolean = false
  ): void => {
    location.state?.task
      ? updateTask(axios, { ...taskToEdit, withoutStandardCheck })
          .then((updatedTask) => {
            if (updatedTask && redirect) navigate("/task");
            else if (updatedTask) {
              setTaskToEdit(updatedTask);
              generateNotification({
                type: NotificationType.SUCCESS,
                value: t("general.notification.success.saveSuccessfull"),
              });
            }
          })
          .catch((exc) => {
            if (exc instanceof StandardPriceTypeAlreadyTakenException)
              toggleStandardPricePopup(true);
          })
      : createNewTask(axios, { ...taskToEdit, withoutStandardCheck })
          .then((task) => {
            if (task && redirect) navigate("/task");
            else if (task) {
              setTaskToEdit(task);
              generateNotification({
                type: NotificationType.SUCCESS,
                value: t("general.notification.success.saveSuccessfull"),
              });
            }
          })
          .catch((exc) => {
            if (exc instanceof StandardPriceTypeAlreadyTakenException)
              toggleStandardPricePopup(true);
          });
  };
  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        title={
          location.state?.task
            ? t("pages.tasks.createOrEdit.headlineEdit")
            : t("pages.tasks.createOrEdit.headlineCreate")
        }
        onBackClick={() => navigate(-1)}
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <Popup
        isOpen={showStandardPricePopup}
        onClose={() => toggleStandardPricePopup(false)}
        buttons={[
          <Button
            onClick={() => toggleStandardPricePopup(false)}
            value={t(`pages.tasks.createOrEdit.standardPricePopup.cancel`)}
          />,
          <Button
            backgroundColor="#bc2e46"
            value={t(`pages.tasks.createOrEdit.standardPricePopup.confirm`)}
            onClick={() => handleSubmit(true, true)}
          />,
        ]}
      >
        <h1>{t(`pages.tasks.createOrEdit.standardPricePopup.title`)}</h1>
        <p>
          {t(`pages.tasks.createOrEdit.standardPricePopup.content`, {
            replace: {
              TYPE: t(
                `pages.tasks.createOrEdit.standardPriceTypes.${taskToEdit.standardType}`
              ),
            },
          })}
        </p>
      </Popup>

      <Box>
        <div className="three-columns">
          <Input
            required
            type="text"
            value={taskToEdit.title}
            label={t("pages.tasks.createOrEdit.taskTitle")}
            onChange={(title) => setTaskToEdit({ ...taskToEdit, title })}
          />
          <Input
            type="text"
            value={taskToEdit.description}
            label={t("pages.tasks.createOrEdit.taskDescription")}
            onChange={(description) =>
              setTaskToEdit({ ...taskToEdit, description })
            }
          />
          <Input
            type="number"
            value={taskToEdit.price}
            onChangeNumber={(price) => setTaskToEdit({ ...taskToEdit, price })}
            label={t(
              `pages.tasks.createOrEdit.${
                isPercentage ? "percentage" : "price"
              }`
            )}
          />
          <Input
            type="number"
            value={taskToEdit.amount}
            onChangeNumber={(amount) =>
              setTaskToEdit({ ...taskToEdit, amount })
            }
            label={t("pages.tasks.createOrEdit.amount")}
          />
          <Input
            type="text"
            value={taskToEdit.identifier}
            onChange={(identifier) =>
              setTaskToEdit({ ...taskToEdit, identifier })
            }
            label={t("pages.tasks.createOrEdit.identifier")}
          />
          <Input
            value={taskToEdit.unit}
            label={t("pages.tasks.createOrEdit.unit")}
            type="text"
            onChange={(unit) => setTaskToEdit({ ...taskToEdit, unit })}
          />
          <TextArea
            value={taskToEdit.annotation}
            label={t("pages.tasks.createOrEdit.annotation")}
            onChange={(annotation) =>
              setTaskToEdit({ ...taskToEdit, annotation })
            }
          />
          <Input
            type="number"
            value={taskToEdit.piecePerHour}
            label={t("pages.tasks.createOrEdit.piecePerHour")}
            onChangeNumber={(piecePerHour) =>
              setTaskToEdit({ ...taskToEdit, piecePerHour })
            }
          />
          <Checkbox
            isChecked={taskToEdit.standard}
            label={t("pages.tasks.createOrEdit.standard")}
            onCheck={(standard) => setTaskToEdit({ ...taskToEdit, standard })}
          />
        </div>
      </Box>

      <Box title={t("pages.tasks.createOrEdit.officePrice")}>
        {allOffices.map((office) => (
          <>
            <p className="task-create__table-headline">{office.name}</p>
            <Table
              rows={getCurrencyPriceTables(office.id)}
              header={
                t(
                  `pages.tasks.createOrEdit.officePriceTableHeader${
                    isPercentage ? "Percentage" : ""
                  }`,
                  {
                    returnObjects: true,
                  }
                ) as TableHeader[]
              }
            />
          </>
        ))}
      </Box>

      <Box title={t("pages.tasks.createOrEdit.standardPrice")}>
        {Object.values(StandardPriceType).map((price) => (
          <Checkbox
            key={`standard-price-${price}`}
            isChecked={price === taskToEdit.standardType}
            onCheck={() =>
              setTaskToEdit((old) => ({
                ...old,
                standardType: old.standardType === price ? undefined : price,
              }))
            }
            label={t(`pages.tasks.createOrEdit.standardPriceTypes.${price}`)}
          />
        ))}
      </Box>
    </form>
  );
};
