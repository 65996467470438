import { Box, Input, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { UserFunction, generateNotification } from "shared";
import { CustomerFeedbackEntry } from "shared/src/customerFeedbackEntry/CustomerFeedbackEntry.types";
import { NotificationType } from "shared/src/notification/notification.types";
import {
  createNewUserFunction,
  updateUserFunction,
} from "shared/src/userFunction/UserFunction.axios";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { generateTableRowForLanguageTranslation } from "../../utils/InputUtils";
import { generateEmptyUserFunction } from "../../utils/user/User.utils";

export const UserFunctionEdit: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios } = useUser();
  const location = useLocation<{ entry?: CustomerFeedbackEntry }>();
  const [entryToEdit, setEntryToEdit] = useState<UserFunction>(
    location.state?.entry ? location.state.entry : generateEmptyUserFunction()
  );
  const isEdit: boolean = useMemo(() => !!entryToEdit.id, [entryToEdit.id]);
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  /**
   * Rows to show and edit the different translations
   */
  const languageNameRows: TableRow[] = useMemo(
    (): TableRow[] =>
      generateTableRowForLanguageTranslation(
        entryToEdit,
        "names",
        setEntryToEdit
      ),
    [entryToEdit]
  );
  /**
   * Submit handler to update or create a userFunctionEntry
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    if (isEdit) {
      updateUserFunction(axios, entryToEdit).then((updatedFunction) => {
        if (updatedFunction && redirect) navigate(-1);
        else if (updatedFunction) {
          setEntryToEdit(updatedFunction);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.updateUserFunction"),
          });
        }
      });
    } else {
      createNewUserFunction(axios, entryToEdit).then((createdFunction) => {
        if (createdFunction && redirect) navigate(-1);
        else if (createdFunction) {
          setEntryToEdit(createdFunction);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.createUserFunction"),
          });
        }
      });
    }
  };
  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={t(
          `pages.userFunction.edit.topBarHeadline${isEdit ? "Edit" : "Create"}`
        )}
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <Box>
        <div className="three-columns">
          <Input
            onChange={(title) => setEntryToEdit({ ...entryToEdit, title })}
            type="text"
            value={entryToEdit.title}
            label={t("pages.userFunction.edit.title")}
            required
          />
          <Input
            disabled
            type="date"
            value={entryToEdit.createDate}
            onChangeDate={() => {}}
            label={t("pages.userFunction.edit.createDate")}
          />
          <Input
            disabled
            type="date"
            value={entryToEdit.lastUpdate}
            onChangeDate={() => {}}
            label={t("pages.userFunction.edit.lastUpdate")}
          />
        </div>
      </Box>
      <Box>
        <Table
          rows={languageNameRows}
          header={
            t("pages.userFunction.edit.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </form>
  );
};
