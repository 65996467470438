import { AxiosInstance } from "axios";
import { CustomerLocation } from "./CustomerLocation.types";
import { generateNotification, mapReplacer } from "..";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";

/**
 *  API Method to load all customerLocations
 * @param axios instance of axios
 * @returns Array of all customerLocations
 */
export const getAllCustomerLocations = async (
  axios: AxiosInstance
): Promise<CustomerLocation[]> => {
  return axios
    .get("/customer/location/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all customerLocations", exc);
      return [];
    });
};

/**
 *  API Method to load all active customerLocations
 * @param axios instance of axios
 * @returns Array of all active customerLocations
 */
export const getAllActiveCustomerLocations = async (
  axios: AxiosInstance
): Promise<CustomerLocation[]> => {
  return axios
    .get("/customer/location/all/active")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all customerLocations", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t(
          "general.notification.error.getAllActiveCustomerLocations"
        ),
      });
      return [];
    });
};

/**
 * API method to update a customerLocation
 * @param axios instance of axios
 * @param location updated customrLocation
 * @returns updated location or undefined on error
 */
export const updateCustomerLocation = async (
  axios: AxiosInstance,
  location: CustomerLocation
): Promise<CustomerLocation | undefined> => {
  return axios
    .post("/customer/location/update", JSON.stringify(location, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updating customerLocation", exc);
      return undefined;
    });
};

/**
 * API method to create a new customerLocation
 * @param axios instance of axios
 * @param location new location object
 * @returns created location or undefined on error
 */
export const createNewCustomerLocation = async (
  axios: AxiosInstance,
  location: CustomerLocation
): Promise<CustomerLocation | undefined> => {
  return axios
    .post("/customer/location/", JSON.stringify(location, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating new customerLocation", exc);
      return undefined;
    });
};

/**
 * API method to get a customerLocation by id
 * @param axios instance of axios
 * @param id id of the customerLocation to load
 * @returns Found customerLocation or undefined if nothing was found
 */
export const getCustomerLocationById = async (
  axios: AxiosInstance,
  id: string
): Promise<CustomerLocation | undefined> => {
  return axios
    .get("/customer/location/", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading customerLocation", exc);
      return undefined;
    });
};

/**
 * API method to get all locations of a customer
 * @param axios instance of axios
 * @param customerId id of the customer to load the locations for
 * @returns array of all found customers
 */
export const getAllCustomerLocationsForCustomer = async (
  axios: AxiosInstance,
  customerId: string
): Promise<CustomerLocation[]> => {
  return axios
    .get("/customer/location/customer", { params: { customerId } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading customerLocations", exc);
      return [];
    });
};
