import {
  Box,
  Checkbox,
  Dropdown,
  Input,
  Option,
  TopBar,
  Wysiwyg,
} from "@sam/components";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  DunningLevel,
  generateDropdownOptions,
  generateNotification,
  Language,
  useData,
} from "shared";
import {
  createNewDunningLevel,
  updateDunningLevel,
} from "shared/src/dunningLevel/DunningLevel.axios";
import { NotificationType } from "shared/src/notification/notification.types";
import { useUser } from "../../components/UserContext";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { generateEmptyDunningLevel } from "../../utils/dunningLevel/DunningLevel.utils";

export const DunningLevelEdit: React.FC = () => {
  const { axios } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation<{ dunningLevel?: DunningLevel }>();

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const [dunningLevelToEdit, setDunningLevelToEdit] = useState<DunningLevel>(
    location.state?.dunningLevel
      ? location.state.dunningLevel
      : generateEmptyDunningLevel()
  );

  const { data: allUserFunctions } = useData("USER_FUNCTION_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Dropdown options that are used for internalNote and internalNoteCC
   */
  const userFunctionOptions: Option[] = useMemo(
    (): Option[] => generateDropdownOptions(allUserFunctions, "title", "id"),
    [allUserFunctions]
  );

  /**
   * Submit handler to create or update a dunningLevel onSubmit
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    const isNew: boolean = !!dunningLevelToEdit.id;
    isNew
      ? updateDunningLevel(axios, dunningLevelToEdit).then(
          (updatedDunningLevel) => {
            if (redirect && updatedDunningLevel) navigate("/dunning");
            else if (updatedDunningLevel) {
              setDunningLevelToEdit(updatedDunningLevel);
              generateNotification({
                type: NotificationType.SUCCESS,
                value: t("general.notification.success.updateNewDunningLevel"),
              });
            }
          }
        )
      : createNewDunningLevel(axios, dunningLevelToEdit).then(
          (createdDunningLevel) => {
            if (createdDunningLevel && redirect) navigate("/dunning");
            else if (createdDunningLevel) {
              setDunningLevelToEdit(createdDunningLevel);
              generateNotification({
                type: NotificationType.SUCCESS,
                value: t("general.notification.success.createNewDunningLevel"),
              });
            }
          }
        );
  };

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          dunningLevelToEdit.id
            ? t("pages.dunning.edit.topBarHeadlineEdit")
            : t("pages.dunning.edit.topBarHeadlineCreate")
        }
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <Box>
        <div className="three-columns">
          <Input
            type="text"
            onChange={(title) =>
              setDunningLevelToEdit({ ...dunningLevelToEdit, title })
            }
            value={dunningLevelToEdit.title}
            label={t("pages.dunning.edit.title")}
          />
          <Input
            type="date"
            value={dunningLevelToEdit.createDate}
            disabled
            label={t("pages.dunning.edit.createDate")}
          />
          <Input
            type="date"
            value={dunningLevelToEdit.lastUpdate}
            disabled
            label={t("pages.dunning.edit.lastUpdate")}
          />
          <Input
            value={dunningLevelToEdit.level}
            type="number"
            onChangeNumber={(level) =>
              setDunningLevelToEdit({ ...dunningLevelToEdit, level })
            }
            label={t("pages.dunning.edit.level")}
          />
          <Input
            type="number"
            onChangeNumber={(fee) =>
              setDunningLevelToEdit({ ...dunningLevelToEdit, fee })
            }
            value={dunningLevelToEdit.fee}
            label={t("pages.dunning.edit.fee")}
          />
          <Input
            type="number"
            value={dunningLevelToEdit.days}
            onChangeNumber={(days) =>
              setDunningLevelToEdit({ ...dunningLevelToEdit, days })
            }
            label={t("pages.dunning.edit.days")}
          />
          <Dropdown
            options={userFunctionOptions}
            multi
            onChangeMultiple={(internalNote) =>
              setDunningLevelToEdit({ ...dunningLevelToEdit, internalNote })
            }
            label={t("pages.dunning.edit.internalNote")}
          />
          <Dropdown
            options={userFunctionOptions}
            multi
            onChangeMultiple={(internalNoteCC) =>
              setDunningLevelToEdit({ ...dunningLevelToEdit, internalNoteCC })
            }
            label={t("pages.dunning.edit.internalNoteCC")}
          />
        </div>
        <Checkbox
          isChecked={dunningLevelToEdit.blockCustomer}
          onCheck={(blockCustomer) =>
            setDunningLevelToEdit({ ...dunningLevelToEdit, blockCustomer })
          }
          label={t("pages.dunning.edit.blockCustomer")}
        />
        <Checkbox
          isChecked={dunningLevelToEdit.mailToCustomer}
          onCheck={(mailToCustomer) =>
            setDunningLevelToEdit({ ...dunningLevelToEdit, mailToCustomer })
          }
          label={t("pages.dunning.edit.mailToCustomer")}
        />
        <Checkbox
          isChecked={dunningLevelToEdit.stopWorking}
          onCheck={(stopWorking) =>
            setDunningLevelToEdit({ ...dunningLevelToEdit, stopWorking })
          }
          label={t("pages.dunning.edit.stopWorking")}
        />
      </Box>
      <Box title={t("pages.dunning.edit.documentText")}>
        {Object.values(Language).map((language) => {
          return (
            <>
              <p>{t(`general.languages.${language}`)}</p>
              <Wysiwyg
                initialContent={dunningLevelToEdit.documentInfoTexts.get(
                  language
                )}
                onChange={(value) => {
                  const values: Map<Language, string> =
                    dunningLevelToEdit.documentInfoTexts;
                  values.set(language, value);
                  setDunningLevelToEdit({
                    ...dunningLevelToEdit,
                    documentInfoTexts: values,
                  });
                }}
              />
            </>
          );
        })}
      </Box>
    </form>
  );
};
