import axios, { AxiosInstance } from "axios";
import {
  CountryCode,
  HolidayResponse,
  OfficialHoliday,
} from "./OfficialHoliday.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";

/**
 * API method to load public holidays for given area and time from the openHolidays api
 * @param countryCode of the country to load holidays for
 * @param languageCode ISO-639-1 code of a language
 * @param validFrom startDate to load holidays from
 * @param validTo endDate to load holidays until
 * @returns array of holidayResponses or void
 */
export const getOfficialHoliday = async (
  countryCode: CountryCode,
  languageCode: string,
  validFrom: Date,
  validTo: Date
): Promise<HolidayResponse[]> => {
  return axios
    .get("https://openholidaysapi.org/publicHolidays/", {
      params: {
        countryIsoCode: countryCode,
        languageIsoCode: languageCode,
        validFrom: validFrom.toISOString().split("T")[0],
        validTo: validTo.toISOString().split("T")[0],
      },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading public holidays", exc);
      return [];
    });
};

/**
 * API Method to load all officialHolidays
 * @param axios instance of axios
 * @returns Array of OfficialHolidays
 */
export const getAllOfficialHolidays = async (
  axios: AxiosInstance
): Promise<OfficialHoliday[]> => {
  return axios
    .get("/officialholidays/")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading official Holidays", exc);
      return [];
    });
};

/**
 * API method to save multiple officialHolidays at the same time
 * @param axios instance of axios
 * @param holidays to save
 * @param UserId of the active user
 * @returns array of created holidays or empty array
 */
export const createOrUpdateMultipleOfficialHoliday = async (
  axios: AxiosInstance,
  holidays: OfficialHoliday[]
): Promise<OfficialHoliday[]> => {
  return axios
    .post("officialholidays/multiple", holidays)
    .then((res) => {
      generateNotification({
        type: NotificationType.SUCCESS,
        value: i18n.t("general.notification.success.updateOfficialHoliday"),
      });
      return res.data;
    })
    .catch((exc) => {
      console.error("Error while saving multiple official holidays", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateOfficialHoliday"),
      });
      return [];
    });
};
