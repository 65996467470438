import { AxiosInstance } from "axios";
import { Pdl } from "./Pdl.types";
import { generateNotification } from "../notification/notification.util";
import i18n from "../../../client/src/i18n/i18n";
import { NotificationType } from "../notification/notification.types";
import { PdlUser } from "../user/User.types";

/**
 * API method to load all PDLs from the backend
 *
 * @param axios The axios instance
 * @returns List of all PDL
 */
export const getAllPdls = async (axios: AxiosInstance): Promise<Pdl[]> => {
  return axios
    .get("/pdl/all")
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during load of all pdls", exc);
      generateNotification({
        value: i18n.t("general.notification.error.loadAllPdl"),
        type: NotificationType.ERROR,
      });
      return [];
    });
};

/**
 * API method to save a new PDL instance on the backend
 *
 * @param newPdl The new PDL instance to save
 * @param axios  The axios instance
 * @returns  The saved PDL instance
 */
export const createNewPdl = async (
  newPdl: Pdl,
  axios: AxiosInstance
): Promise<Pdl> => {
  return axios
    .post("/pdl/", newPdl)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during PDL creation", exc);
      generateNotification({
        value: i18n.t("general.notification.error.createPdl"),
        type: NotificationType.ERROR,
      });
    });
};

/**
 * API method to update a PDL instance on the backend
 *
 * @param updatedPdl The  PDL instance to update
 * @param axios  The axios instance
 * @returns  The saved PDL instance
 */
export const updatePdl = async (
  updatedPdl: Pdl,
  axios: AxiosInstance
): Promise<Pdl> => {
  return axios
    .post("/pdl/update", updatedPdl)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during PDL update", exc);
      generateNotification({
        value: i18n.t("general.notification.error.updatePdl"),
        type: NotificationType.ERROR,
      });
    });
};

/**
 * API method to load the user for the PDL with the given ID
 *
 * @param pdlId id of the PDL
 * @param axios The axios instance
 * @returns List of all PDL user
 */
export const getUserForPdl = async (
  pdlId: string,
  axios: AxiosInstance
): Promise<PdlUser[]> => {
  return axios
    .get("/pdl/all/user", { params: { pdlId } })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during load for PDL user", exc);
      generateNotification({
        value: i18n.t("general.notification.error.getPdlUser"),
        type: NotificationType.ERROR,
      });
      return [];
    });
};

/**
 * API method to update a PDL instance on the backend
 *
 * @param updatedPdlUser The  PDL instance to update
 * @param axios  The axios instance
 */
export const updatePdlUser = async (
  updatedPdlUser: PdlUser,
  axios: AxiosInstance
): Promise<PdlUser> => {
  return axios
    .post("/pdl/update/user", updatedPdlUser)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during PDL user update", exc);
      generateNotification({
        value: i18n.t("general.notification.error.updatePdlUser"),
        type: NotificationType.ERROR,
      });
    });
  };
  
  /**
   * API method to save a new PDL user on the backend 
   * 
   * @param newUser The instance to save
   * @param axios The axios instanc e
   * @returns The newly created user 
   */
  export const createPdlUser = async (
    newUser: PdlUser,
    axios: AxiosInstance
  ): Promise<PdlUser> => {
    return axios
    .post("/pdl/user", newUser)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during pdl user createio", exc);
      generateNotification({
        value: i18n.t("general.notification.error.createPdlUser"),
        type: NotificationType.ERROR,
      });
    });
};
