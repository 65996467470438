import { Button, Dropdown, Input } from "@sam/components";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import {
  AppointmentState,
  generateDropdownOptions,
  Schedule,
  useData,
} from "shared";

interface ScheduleEditProps {
  schedule: Schedule;
  updateSchedule: Dispatch<SetStateAction<Schedule | undefined>>;
  handleScheduleCreate: (schedule: Schedule) => void;
  handleScheduleDelete: (id: string) => void;
}

export const ScheduleEdit: React.FC<ScheduleEditProps> = ({
  schedule,
  updateSchedule,
  handleScheduleCreate,
  handleScheduleDelete,
}) => {
  const { t } = useTranslation();

  const { data: allAbsenceReasons } = useData("ABSENCE_REASONS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  return (
    <>
      <div className="three-columns">
        <Input
          label={t("pages.user.create.schedule.title")}
          value={schedule.appointmentDetail?.title}
          onChange={(title) =>
            updateSchedule((old) => ({
              ...old!,
              appointmentDetail: { ...old!.appointmentDetail!, title },
            }))
          }
          type="text"
        />
        <Input
          label={t("pages.user.create.schedule.location")}
          value={schedule.appointmentDetail?.location}
          onChange={(location) =>
            updateSchedule((old) => ({
              ...old!,
              appointmentDetail: { ...old!.appointmentDetail!, location },
            }))
          }
          type="text"
        />

        <>
          <Input
            label={t("pages.user.create.schedule.startDate")}
            type="date"
            onChangeDate={(startDate) =>
              updateSchedule({ ...schedule, startDate })
            }
            value={schedule.startDate}
          />

          <Input
            label={t("pages.user.create.schedule.endDate")}
            type="date"
            onChangeDate={(endDate) => updateSchedule({ ...schedule, endDate })}
            value={schedule.endDate}
          />
        </>

        <Input
          label={t("pages.user.create.schedule.startTime")}
          value={schedule.appointmentDetail?.startTime}
          onChangeTime={(startTime) =>
            updateSchedule((old) => ({
              ...old!,
              appointmentDetail: {
                ...old!.appointmentDetail!,
                startTime,
              },
            }))
          }
          type="time"
        />
        <Input
          label={t("pages.user.create.schedule.endTime")}
          value={schedule.appointmentDetail?.endTime}
          onChangeTime={(endTime) =>
            updateSchedule((old) => ({
              ...old!,
              appointmentDetail: {
                ...old!.appointmentDetail!,
                endTime,
              },
            }))
          }
          type="time"
        />

        <Dropdown
          label={t("pages.user.create.schedule.reasonId")}
          options={generateDropdownOptions(allAbsenceReasons, "name", "id")}
          selectedOption={schedule.appointmentDetail?.reasonId}
          onChange={(reasonId) =>
            updateSchedule((old) => ({
              ...old!,
              appointmentDetail: {
                ...old!.appointmentDetail!,
                reasonId,
              },
            }))
          }
        />
        <Dropdown
          options={Object.keys(AppointmentState).map((state) => ({
            label: t(`pages.user.create.schedule.states.${state}`),
            value: state,
          }))}
          selectedOption={schedule.appointmentDetail?.state}
          onChange={(state: AppointmentState) =>
            updateSchedule((old) => ({
              ...old!,
              appointmentDetail: {
                ...old!.appointmentDetail!,
                state,
              },
            }))
          }
          label={t("pages.user.create.schedule.state")}
        />
        <div className="schedule-button-wrapper">
          <Button
            value={t(
              `pages.user.create.schedule.${
                schedule.id ? "editButton" : "createButton"
              }`
            )}
            onClick={() => handleScheduleCreate(schedule)}
            type="button"
          />
          {schedule.id && (
            <Button
              value={t(`pages.user.create.schedule.deleteButton`)}
              onClick={() => handleScheduleDelete(schedule.id)}
              type="button"
              backgroundColor="#bc2e46"
            />
          )}
        </div>
      </div>
    </>
  );
};
