import { Checkbox, Input, TableRow } from "@sam/components";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import {
  addOrRemove,
  Country,
  Customer,
  InvoicePayment,
  InvoicePosition,
  Offer,
  Order,
  PaymentCondition,
  ProjectProtocol,
  Receipt,
} from "shared";
import { CustomerLocation } from "shared/src/customerLocation/CustomerLocation.types";
import { Project } from "shared/src/project/Project.types";
import dayjs from "shared/src/tools/Dayjs";
import { uid } from "uid";
import {
  ReactComponent as DeleteIcon,
  ReactComponent as DisableIcon,
} from "../../assets/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";

/**
 * Util method to convert paymentConditions into TableRows
 * @param conditions to convert
 * @param navigate to navigate to edit
 * @returns  Array of TableRows
 */
export const convertPaymentConditionsIntoTableEntries = (
  conditions: PaymentCondition[],
  navigate: NavigateFunction,
  handleDisable: (id: string) => void
): TableRow[] => {
  return conditions.map((condition) => ({
    id: condition.id,
    onClick: () =>
      navigate("/payment/edit", {
        state: { paymentCondition: condition },
      }),
    content: [
      condition.identifier,
      condition.description,
      i18n.t(`general.country.${condition.country}`),
      <div className="table-action__icon-wrapper">
        <EditIcon
          title={i18n.t("general.icons.edit")}
          className="edit"
          onClick={(evt) => {
            evt.stopPropagation();
            navigate("/payment/edit", {
              state: { paymentCondition: condition },
            });
          }}
          width={30}
        />
        <DisableIcon
          className="delete"
          width={30}
          onClick={(evt) => {
            evt.stopPropagation();
            handleDisable(condition.id);
          }}
        />
      </div>,
    ],
  }));
};

/**
 * Util method to generate an empty paymentCondition
 * @param override partial PaymentCondition to adjust the gernerated condition
 * @returns created PaymentCondition
 */
export const generateEmptyPaymentCondition = (
  override?: Partial<PaymentCondition>
): PaymentCondition => ({
  country: Country.GERMANY,
  createDate: new Date(),
  createdBy: "",
  daysToPay: 0,
  daysToPayDiscount: 0,
  deactivated: false,
  description: "",
  discountPercentage: 1,
  id: undefined!,
  identifier: "",
  lastUpdated: new Date(),
  updatedBy: "",
  ...override,
});

/**
 * Util method to convertInvoices into TableRows
 * @param invoices to convert
 * @returns Array of TableRows
 */
export const convertInvoicesIntoTableRows = (
  invoices: Receipt[],
  orders: Order[],
  projects: Project[],
  customers: Customer[],
  locations: CustomerLocation[],
  navigate: NavigateFunction
): TableRow[] => {
  return invoices.map((invoice) => {
    const invoiceSum: number = getTotalInvoiceSum(invoice);

    const order: Order | undefined = orders?.find(
      (order) => order.id === invoice.orderId
    );
    const project: Project | undefined = projects.find(
      (project) => project.orderId === order?.id
    );

    const invoiceRecipient: string =
      customers.find(
        (customer) => customer.id === order?.invoiceRecipient.customerId
      )?.name || "-";

    const customer: string =
      customers.find((customer) => customer.id === order?.customerId)?.name ||
      "-";

    const workLocation: string =
      locations.find(
        (customer) => customer.id === order?.workingLocation.customerLocationId
      )?.name || "-";

    return {
      id: invoice.id,
      content: [
        project?.numberRangeNumber || "-",
        invoice.invoiceNumber,
        invoiceRecipient,
        customer,
        workLocation,
        invoice.deliveryDate.toLocaleDateString("DE-de"),
        i18n.t(`general.invoiceState.${invoice.invoiceState}`),
        "TODO",
        invoiceSum.toFixed(2),
        (invoiceSum * 0.19).toFixed(2),
        (invoiceSum * 1.19).toFixed(2),
      ],
      onClick: () => navigate("/invoice/edit", { state: { invoice } }),
    };
  });
};

/**
 * Util method to convert InvoicePositions into TableEntries
 * @param positions to convert
 * @param updatePosition util method to update the positions onChange
 * @param readOnly decides if the inputs are disabled or not
 * @returns Array of TableRows
 */
export const convertInvoicePositionIntoTableEntries = (
  positions: InvoicePosition[],
  updatePosition: (
    id: string,
    key: keyof InvoicePosition,
    value: string | number | Date
  ) => void,
  handleDeletePosition: (id: string) => void,
  readOnly: boolean
): TableRow[] => {
  return positions
    .sort((a, b) => a.index - b.index)
    .map((position) => ({
      id: position.id,
      content: [
        <Input
          type="number"
          value={position.index}
          onChangeNumber={(value) =>
            updatePosition(position.id, "index", value)
          }
        />,
        <Input
          type="text"
          value={position.articleNumber}
          disabled={readOnly}
          onChange={(articleNumber) =>
            updatePosition(position.id, "articleNumber", articleNumber)
          }
        />,
        <Input
          type="number"
          value={position.amount.toFixed(2)}
          onChangeNumber={(amount) =>
            updatePosition(position.id, "amount", amount)
          }
          disabled={readOnly}
        />,
        <Input
          type="text"
          value={position.description}
          onChange={(description) =>
            updatePosition(position.id, "description", description)
          }
          disabled={readOnly}
        />,
        <Input
          type="number"
          onChangeNumber={(discount) =>
            updatePosition(position.id, "discount", discount)
          }
          value={position.discount}
          disabled={readOnly}
        />,
        <Input
          value={position.price.toFixed(2)}
          type="number"
          onChangeNumber={(price) =>
            updatePosition(position.id, "price", price)
          }
          disabled={readOnly}
        />,
        <Input
          type="number"
          onChangeNumber={() => {}}
          value={(position.amount * position.price - position.discount).toFixed(
            2
          )}
          disabled
        />,
        <div className="invoice-edit__table-icon-wrapper">
          {!readOnly && (
            <DeleteIcon
              title={i18n.t("general.icons.delete")}
              className="invoice-edit__table-icon-wrapper__icon"
              onClick={(evt) => {
                evt.stopPropagation();
                handleDeletePosition(position.id);
              }}
            />
          )}
        </div>,
      ],
    }));
};

/**
 * Util method to convert projects into TableEntries to be displayed
 * @param projects Array of projects that should be displayed
 * @param orders to display additional Data
 * @param customers to display additional Data
 * @param customerLocations to display additional Data
 * @param protocols to display additional Data
 * @param offers to display additional Data
 * @param onCheckboxClick handler function to add or remove the projects onClick
 * @param selectedProjects  all checked projects
 * @returns  Array of TableRows
 */
export const convertProjectsIntoInvoiceTableEntries = (
  projects: Project[],
  orders: Order[],
  customers: Customer[],
  customerLocations: CustomerLocation[],
  protocols: ProjectProtocol[],
  offers: Offer[],
  onCheckboxClick: (orderId: string) => void,
  selectedProjects: string[]
): TableRow[] => {
  return projects.map((project) => {
    const order: Order | undefined = orders?.find(
      (order) => order.id === project.orderId
    );
    const customer: Customer | undefined = customers.find(
      (customer) => customer.id === order?.customerId
    );
    const customerLocation: CustomerLocation | undefined =
      customerLocations.find((location) => location.id === order?.locationId);

    const offer: Offer | undefined = offers.find(
      (offer) => offer.id === project.acceptedOfferId
    );

    const invoiceRecipient: string =
      customers.find(
        (customer) => customer.id === order?.invoiceRecipient.customerId
      )?.name || "-";
    return {
      id: project.id,
      content: [
        <Checkbox
          isChecked={selectedProjects.includes(project.id)}
          onCheck={() => onCheckboxClick(project.id)}
        />,
        project.numberRangeNumber || "-",
        customer?.name || "-",
        customerLocation?.name || "-",
        invoiceRecipient,
        offer?.annotation || "-",
        order?.id ? getLastProtocolDate(protocols, order.id) : "-",
        order?.lastInvoiced?.toLocaleDateString("de-DE") || "-",
      ],
    };
  });
};

/**
 * Util method to get the newest protocolDate for an order out of an array of protocols
 * @param protocols to check
 * @param orderId to get the last protocolDate for
 * @returns last protocolDate or "-" if no protocol for the order was found
 */
export const getLastProtocolDate = (
  protocols: ProjectProtocol[],
  orderId: string
): string =>
  protocols
    .filter((protocol) => protocol.orderId === orderId)
    .sort((a, b) =>
      dayjs(a.protocolDate).isBefore(b.protocolDate) ? 1 : -1
    )[0]
    ?.protocolDate.toLocaleDateString("de-DE") || "-";

/**
 * Util method to generate an empty invoicePosition
 * @param override partial invoicePosition to adjust the document
 * @returns  created invoicePosition
 */
export const generateEmptyInvoicePosition = (
  override?: Partial<InvoicePosition>
): InvoicePosition => ({
  amount: 0,
  articleNumber: "",
  description: "",
  discount: 0,
  id: uid(),
  index: 0,
  price: 0,
  ...override,
});

/**
 * Util method to display invoices for accounting
 * @param invoices list of invoices to display
 * @param orders array of orders to get information about the invoice
 * @param customers array of customers to display the customerName
 * @param offices array of offices to get the executing office
 * @returns Array of TableRows
 */
export const convertInvoicesIntoAccountingTableEntries = (
  invoices: Receipt[],
  orders: Order[],
  customers: Customer[],
  navigate: NavigateFunction,
  projects: Project[],
  locations: CustomerLocation[]
): TableRow[] => {
  return invoices.map((invoice) => {
    const order: Order | undefined = orders.find(
      (order) => order.id === invoice.orderId
    );

    const customer: Customer | undefined = customers.find(
      (customer) => customer.id === order?.customerId
    );
    const project: Project | undefined = projects.find(
      (project) => project.id === invoice.projectId
    );
    const invoiceRecipient: string =
      customers.find(
        (customer) => customer.id === order?.invoiceRecipient.customerId
      )?.name || "-";

    const workLocation: string =
      locations.find(
        (customer) => customer.id === order?.workingLocation.customerLocationId
      )?.name || "-";

    const invoiceSum: number = getTotalInvoiceSum(invoice);

    return {
      id: invoice.id,
      onClick: () =>
        navigate("/accounting/invoice", { state: { invoice, order } }),
      content: [
        project?.numberRangeNumber || "-",
        invoice.invoiceNumber,
        invoiceRecipient,
        customer?.name || "-",
        workLocation,
        invoice.deliveryDate.toLocaleDateString("DE-de"),
        i18n.t(`general.invoiceState.${invoice.invoiceState}`),
        "TODO",
        invoiceSum,
        (invoiceSum * 0.19).toFixed(2),
        (invoiceSum * 1.19).toFixed(2),
      ],
    };
  });
};

/**
 * Util method to generate an empty InvoicePayment
 * @param override partial InvoicePayment to adjust the created object
 * @returns  generated InvoicePayment object
 */
export const generateEmptyInvoicePayment = (
  override?: Partial<InvoicePayment>
): InvoicePayment => ({
  amount: 0,
  completePayment: false,
  createDate: undefined!,
  createdBy: "",
  customerId: "",
  id: undefined!,
  invoiceId: "",
  orderId: "",
  paymentDate: new Date(),
  ...override,
});

/**
 * Util method to get the total invoiceSum of an invoice
 * @param invoice to calculate the sum for
 * @returns  sum of the positions on the invoice
 * @tested
 */
export const getTotalInvoiceSum = (invoice: Receipt): number => {
  let sum: number = 0;
  invoice.invoicePositions.forEach(
    (position) => (sum += position.amount * position.price)
  );
  return sum;
};

/**
 * Util method to convert due invoices into tableEntries to create dunnings
 * @param receipts receipts to display
 * @param selectedReceipts decides if the checkboxes is checked
 * @param setSelectedReceipts update method to keep track of the selected receipts
 * @param orders to get the customer data
 * @param customers  to get the customer and invoice recipient
 * @returns Array of TableRows
 */
export const convertDueInvoicesIntoTableEntries = (
  receipts: Receipt[],
  selectedReceipts: string[],
  setSelectedReceipts: Dispatch<SetStateAction<string[]>>,
  orders: Order[],
  customers: Customer[],
  maxDunningLevel: number
): TableRow[] =>
  receipts.map((receipt) => {
    const order: Order | undefined = orders.find(
      (orderEntry) => orderEntry.id === receipt.orderId
    );
    const customer: Customer | undefined = customers.find(
      (customerEntry) => customerEntry.id === order?.customerId
    );
    const invoiceRecipient: Customer | undefined = customers.find(
      (customerEntry) => customerEntry.id === order?.invoiceRecipient.customerId
    );

    const dueDate: dayjs.Dayjs = dayjs(receipt.invoiceDate);
    dueDate.add(receipt.paymentCondition.daysToPay, "days");

    return {
      id: receipt.id,
      content: [
        <Checkbox
          onCheck={() =>
            setSelectedReceipts(addOrRemove(selectedReceipts, receipt.id))
          }
          disabled={receipt.dunningLevel >= maxDunningLevel}
          isChecked={selectedReceipts.includes(receipt.id)}
        />,
        receipt?.dunningLevel || "-",
        customer?.name || "-",
        invoiceRecipient?.name || "-",
        getTotalInvoiceSum(receipt).toFixed(2),
        dueDate.toDate().toLocaleDateString("DE-de"),
      ],
    };
  });

/**
 * API Method to dispaly existing payments for an invoice
 * @param payments to display
 * @returns TableRows for the payments
 */
export const convertExistingPaymentsIntoTableEntries = (
  payments: InvoicePayment[]
): TableRow[] =>
  payments.map((payment) => ({
    id: payment.id,
    content: [payment.paymentDate.toLocaleDateString("DE-de"), payment.amount],
  }));
