import { ProjectPlanningProps } from "./ProjectPlanning.types";
import { DateHeader } from "./subcomponents/PlanningHeader.tsx";

import "./ProjectPlanning.scss";
import { AvailableUsers } from "./subcomponents/AvailableUsers.tsx";
import { CarsAvailable } from "./subcomponents/CarsAvailable.tsx";
import { MainTable } from "./subcomponents/MainTable.tsx";

export const ProjectPlanning: React.FC<ProjectPlanningProps> = ({
  cars,
  projects,
  schedules,
  startDate,
  users,
  customers,
  translations,
  isEdit,
  onScheduleUpdate,
  customerLocations,
  orders,
  checkWorkerAmount,
}) => {
  return (
    <div className="project-planning__wrapper">
      <DateHeader
        users={users}
        startDate={startDate}
        projectTitle={translations.projectTitle}
        schedules={schedules}
        translations={translations}
      />
      <MainTable
        customerLocations={customerLocations}
        cars={cars}
        customers={customers}
        projects={projects}
        schedules={schedules}
        startDate={startDate}
        users={users}
        translations={translations}
        isEdit={isEdit}
        onScheduleUpdate={onScheduleUpdate}
        orders={orders}
        checkWorkerAmount={checkWorkerAmount}
      />
      <AvailableUsers
        schedules={schedules}
        startDate={startDate}
        users={users}
        translations={translations}
      />
      <CarsAvailable
        cars={cars}
        schedules={schedules}
        startDate={startDate}
        carsHeader={translations.availableCarsHeader}
      />
    </div>
  );
};
