import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useData } from "shared";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { convertCustomerUserIntoTableEntries } from "../../utils/customer/Customer.utils";

export const CustomerUserOverview: React.FC = () => {
  const { data: loadedCustomers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerUsers } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });
  const [filterValue, setFilterValue] = useState<string>("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  //Hook to convert customerUsers into TableEntries after loading
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertCustomerUserIntoTableEntries(
        loadedCustomerUsers,
        navigate,
        loadedCustomers
      ),
    [loadedCustomerUsers, navigate, loadedCustomers]
  );

  return (
    <>
      <TopBar
        title={t("pages.customerUser.overview.topBarHeadline")}
        onSearch={setFilterValue}
      >
        <AddIcon
          title={t("general.icons.add")}
          onClick={() => navigate("/customer/user/create")}
        />
      </TopBar>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.customerUser.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
