import { TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import { DunningLevel, Language } from "shared";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";

/**
 * Util method to convert dunningLevels into TableRows
 * @param levels to convert into TableEntries
 * @param navigate navigateFunction to navigate to edit page
 * @returns Array of TableRows
 */
export const convertDunningLevelsIntoTableEntries = (
  levels: DunningLevel[],
  navigate: NavigateFunction
): TableRow[] =>
  levels.map((level) => ({
    id: level.id,
    onClick: () =>
      navigate("/dunning/edit", { state: { dunningLevel: level } }),
    content: [
      level.title,
      level.level,
      level.days,
      level.blockCustomer
        ? i18n.t("pages.dunning.overview.yes")
        : i18n.t("pages.dunning.overview.no"),
      level.disabled
        ? i18n.t("pages.dunning.overview.yes")
        : i18n.t("pages.dunning.overview.no"),
      <div>
        <EditIcon
          title={i18n.t("general.icons.edit")}
          width={30}
          onClick={(evt) => {
            evt.stopPropagation();
            navigate("/dunning/edit", { state: { dunningLevel: level } });
          }}
        />
      </div>,
    ],
  }));

/**
 * Util method to generate an empty dunningLevel
 * @param override partial dunningLevel to create an empty dunningLevel
 * @returns created dunningLevel
 */
export const generateEmptyDunningLevel = (
  override?: Partial<DunningLevel>
): DunningLevel => ({
  id: undefined!,
  createDate: new Date(),
  createdBy: "",
  blockCustomer: false,
  days: 0,
  disabled: false,
  fee: 0,
  internalNote: [],
  internalNoteCC: [],
  level: 0,
  mailToCustomer: true,
  stopWorking: false,
  title: "",
  documentInfoTexts: new Map<Language, string>(),
  ...override,
});
