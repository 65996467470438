import React, { useState } from "react";
import { ButtonComponentProps } from "./Button.types";
import "./Button.scss";
import { Loader } from "..";

export const Button: React.FC<ButtonComponentProps> = ({
  loading,
  disabled,
  type = "button",
  value,
  icon,
  onClick,
  backgroundColor,
  maxWidth,
  active,
  name,
}) => {
  const [isLoading, toggleLoading] = useState<boolean>(false);
  /**
   * Handler for button click with onClick promise support
   */
  const handleClick = (): void => {
    // skip if no onClick handler is provided
    if (!onClick) return;

    // call the onClick handler
    const onClickResult = onClick();

    // skip if onClick does not return a promise or if loading is already set
    if (!(onClickResult instanceof Promise) || loading !== undefined) return;

    // toggle loading state
    toggleLoading(true);

    // reset loading state when promise finished
    onClickResult.finally(() => toggleLoading(false));
  };
  return (
    <div data-testid="Button-wrapper" className="button-component__wrapper">
      <button
        name={name}
        style={{
          backgroundColor: backgroundColor ? backgroundColor : "",
          maxWidth: maxWidth ? maxWidth : "",
        }}
        className={["button-component__button", active ? "active" : ""].join(
          " "
        )}
        data-testid="button-component-button"
        disabled={disabled || loading || isLoading}
        type={type}
        onClick={handleClick}
      >
        <div className="button-component__value">
          {loading || isLoading ? (
            <div className="loader-wrapper">
              <Loader />
            </div>
          ) : icon ? (
            <div>{`${icon} ${value}`}</div>
          ) : (
            value
          )}
        </div>
      </button>
    </div>
  );
};
