import { Button } from "@sam/components";

import { ReactComponent as ArrowLeft } from "../../assets/chevron-left.svg";
import { ReactComponent as SaveIcon } from "../../assets/save.svg";

interface SaveButtonProps {
  handleSubmit: (redirect: boolean) => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
  disabled?: boolean;
}
export const SaveButtons: React.FC<SaveButtonProps> = ({
  handleSubmit,
  buttonRef,
  disabled = false,
}) => {
  return (
    <>
      <button
        type="submit"
        disabled={disabled}
        value={""}
        ref={buttonRef}
        className="hidden"
      />
      <Button
        value={
          <div className="save-and-navigate__wrapper">
            <ArrowLeft className="save-and-navigate__arrow" />
            <SaveIcon className="save-and-navigate__save" />
          </div>
        }
        type="button"
        disabled={disabled}
        onClick={() => handleSubmit(true)}
      />
      <Button
        value={<SaveIcon className="save-icon" />}
        type="button"
        disabled={disabled}
        onClick={() => handleSubmit(false)}
      />
    </>
  );
};
