import { NavigateFunction } from "react-router-dom";
import { AbsenceReason, SimpleUser } from "shared";
import { TableRow } from "@sam/components";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";
import { Right, UserRight } from "shared/src/userRole/UserRole.types";
import {
  getUserNameForSimpleUser,
  isUserAllowedToDo,
} from "../user/User.utils";

/**
 * Helper Method to convert AbsenceReasons into TableRows
 * @param reasons to convert
 * @param navigate navigate method to implement in entries
 * @returns  Array of TableRows
 */
export const convertAbsenceReasonsIntoTableEntries = (
  userRights: UserRight,
  reasons: AbsenceReason[],
  simpleUsers: SimpleUser[],
  navigate: NavigateFunction
): TableRow[] => {
  return reasons.map((reason) => ({
    id: reason.id,
    onClick: () => navigate(`/absence/reason/edit`, { state: { reason } }),
    content: [
      reason.name,
      reason.lastUpdate?.toLocaleDateString("de-DE") || "",
      getUserNameForSimpleUser(reason.updatedBy, simpleUsers),
      reason.creatingTimeTracking
        ? i18n.t("general.buttons.yes")
        : i18n.t("general.buttons.no"),
      reason.disabled
        ? i18n.t("pages.absenceReasons.overview.deactivated")
        : i18n.t("pages.absenceReasons.overview.active"),
      <div>
        {isUserAllowedToDo(userRights, Right.ABSENCE_REASON_EDIT) && (
          <EditIcon
            title={i18n.t("general.icons.edit")}
            width={40}
            onClick={(evt) => {
              evt.stopPropagation();
              navigate(`/absence/reason/edit`, { state: { reason } });
            }}
          />
        )}
      </div>,
    ],
  }));
};
