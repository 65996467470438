import { Box, Button, Input, Table, TopBar } from "@sam/components";
import { TableHeader, TableRow } from "@sam/components/src/Table/Table.types";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { generateNotification, useData } from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import {
  createNewUserLanguage,
  deleteUserLanguage,
  updateUserLanguage,
} from "shared/src/userLanguage/UserLanguage.axios";
import { UserLanguage } from "shared/src/userLanguage/UserLanguage.types";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { generateTableRowForLanguageTranslation } from "../../utils/InputUtils";
import { getUserNameForSimpleUser } from "../../utils/user/User.utils";
import { generateEmptyUserLanguage } from "../../utils/userLanguage/UserLanguage.utils";

export const UserLanguageEdit: React.FC = () => {
  const { axios } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation<{ language?: UserLanguage }>();
  const [languageToEdit, setLanguageToEdit] = useState<UserLanguage>(
    location.state?.language
      ? location.state.language
      : generateEmptyUserLanguage()
  );

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const { data: loadedSimpleUsers } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Submit handler to create or edit the language
   * @param redirect decides if the method should navigate back after success

   */
  const handleSubmit = (redirect: boolean): void => {
    languageToEdit.id
      ? updateUserLanguage(axios, languageToEdit).then((updatedLanguage) => {
          if (updatedLanguage && redirect) navigate(-1);
          else if (updatedLanguage) {
            setLanguageToEdit(updatedLanguage);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.saveSuccessfull"),
            });
          }
        })
      : createNewUserLanguage(axios, languageToEdit).then((createdLanguage) => {
          if (createdLanguage && redirect) navigate(-1);
          else if (createdLanguage) {
            setLanguageToEdit(createdLanguage);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.saveSuccessfull"),
            });
          }
        });
  };

  /**
   * Rows to show and edit the different translations
   */
  const languageNameRows: TableRow[] = useMemo(
    (): TableRow[] =>
      generateTableRowForLanguageTranslation(
        languageToEdit,
        "names",
        setLanguageToEdit
      ),
    [languageToEdit]
  );

  /**
   * Helper method to delete a language
   */
  const handleDeleteLanguage = (): void => {
    deleteUserLanguage(axios, languageToEdit.id).then((editedUsers) => {
      if (editedUsers >= 0) {
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.deleteUserLanguage", {
            replace: { editedUsers: editedUsers },
          }),
        });
        navigate(-1);
      }
    });
  };
  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={t(
          `pages.userLanguage.edit.topBarHeadline${
            languageToEdit.id ? "Edit" : "Create"
          }`
        )}
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />

        {languageToEdit.id && (
          <Button
            value={t("general.buttons.delete")}
            onClick={handleDeleteLanguage}
          />
        )}
      </TopBar>
      <Box title={t("pages.userLanguage.edit.boxTitle")}>
        <div className="three-columns">
          <Input
            value={languageToEdit?.name}
            onChange={(name) => setLanguageToEdit({ ...languageToEdit, name })}
            type="text"
            label={t("pages.userLanguage.edit.name")}
          />
          <Input
            value={languageToEdit?.createDate}
            disabled
            type="date"
            label={t("pages.userLanguage.edit.createDate")}
          />
          <Input
            value={getUserNameForSimpleUser(
              languageToEdit?.createdBy,
              loadedSimpleUsers
            )}
            type="text"
            label={t("pages.userLanguage.edit.createdBy")}
            disabled
          />
          <Input
            value={languageToEdit?.lastUpdate}
            disabled
            type="date"
            label={t("pages.userLanguage.edit.lastUpdate")}
          />
          <Input
            value={getUserNameForSimpleUser(
              languageToEdit?.updatedBy || "",
              loadedSimpleUsers
            )}
            type="text"
            label={t("pages.userLanguage.edit.updatedBy")}
            disabled
          />
        </div>
      </Box>
      <Box>
        <Table
          rows={languageNameRows}
          header={
            t("pages.userLanguage.edit.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </form>
  );
};
