import { Box, Button, Dropdown, Input, TopBar } from "@sam/components";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Country,
  Gender,
  PdlUser,
  createPdlUser,
  generateDropdownOptions,
  updatePdlUser,
  useData,
} from "shared";
import { useUser } from "../../components/UserContext";

export const PdlUserEdit: React.FC = () => {
  const { axios } = useUser();
  const location = useLocation<{ user?: PdlUser; pdlId: string }>();
  const { data: allDepartments } = useData("DEPARTMENT_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const [pdlUserToEdit, setPdlUserToEdit] = useState<PdlUser>(
    location.state!.user!
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**
   * Util method to create or update a pdl user
   * @param evt FormEvent to prevent default behaviour
   */
  const handleSubmit = (evt: FormEvent): void => {
    evt.preventDefault();
    if (!pdlUserToEdit) return;
    const backendCall: Promise<PdlUser> = pdlUserToEdit.id
      ? updatePdlUser(pdlUserToEdit, axios)
      : createPdlUser(pdlUserToEdit, axios);
    backendCall.then(() => navigate(-1));
  };
  return (
    <form onSubmit={handleSubmit}>
      <TopBar
        title={t(
          `pages.pdl.user.topbarHeadline${
            pdlUserToEdit.id ? "Update" : "Create"
          }`
        )}
        onBackClick={() => navigate(-1)}
      >
        <Button value={t("general.buttons.save")} type="submit" />
      </TopBar>
      <Box>
        <>
          <div className="three-columns">
            <Dropdown
              onChange={(gender) =>
                setPdlUserToEdit({
                  ...pdlUserToEdit,
                  gender: gender as Gender,
                })
              }
              label={t("pages.pdl.user.gender")}
              selectedOption={pdlUserToEdit.gender}
              options={Object.values(Gender).map((gender) => ({
                value: gender,
                label: t(`general.gender.${gender}`),
              }))}
            />
            <Input
              type="text"
              required
              value={pdlUserToEdit.firstName}
              onChange={(firstName) =>
                setPdlUserToEdit({ ...pdlUserToEdit, firstName })
              }
              label={t("pages.pdl.user.firstName")}
            />
            <Input
              type="text"
              value={pdlUserToEdit.title}
              onChange={(title) =>
                setPdlUserToEdit({ ...pdlUserToEdit, title })
              }
              label={t("pages.pdl.user.title")}
            />
            <Input
              required
              type="text"
              value={pdlUserToEdit.lastName}
              onChange={(lastName) =>
                setPdlUserToEdit({ ...pdlUserToEdit, lastName })
              }
              label={t("pages.pdl.user.lastName")}
            />
            <Dropdown
              options={generateDropdownOptions(allDepartments, "title", "id")}
              selectedOption={pdlUserToEdit.departmentId}
              onChange={(departmentId) =>
                setPdlUserToEdit({ ...pdlUserToEdit, departmentId })
              }
              label={t("pages.pdl.user.departmentId")}
            />
            <Dropdown
              options={generateDropdownOptions(allOffices, "name", "id")}
              selectedOption={pdlUserToEdit.mainOffice}
              onChange={(mainOffice) =>
                setPdlUserToEdit({ ...pdlUserToEdit, mainOffice })
              }
              label={t("pages.pdl.user.mainOffice")}
            />
          </div>
        </>
      </Box>
      <Box title={t("pages.pdl.user.contact")}>
        <div className="three-columns">
          <Input
            label={t("pages.pdl.user.phone")}
            type="text"
            value={pdlUserToEdit.contact.phone}
            onChange={(phone) =>
              setPdlUserToEdit({
                ...pdlUserToEdit,
                contact: { ...pdlUserToEdit.contact, phone },
              })
            }
          />
          <Input
            label={t("pages.pdl.user.phonePrivate")}
            type="text"
            value={pdlUserToEdit.contact.phonePrivate}
            onChange={(phonePrivate) =>
              setPdlUserToEdit({
                ...pdlUserToEdit,
                contact: { ...pdlUserToEdit.contact, phonePrivate },
              })
            }
          />
          <Input
            type="text"
            value={pdlUserToEdit.contact.mobile}
            onChange={(mobile) =>
              setPdlUserToEdit({
                ...pdlUserToEdit,
                contact: { ...pdlUserToEdit.contact, mobile },
              })
            }
            label={t("pages.pdl.user.mobile")}
          />
          <Input
            type="text"
            value={pdlUserToEdit.contact.mobilePrivate || ""}
            onChange={(mobilePrivate) =>
              setPdlUserToEdit({
                ...pdlUserToEdit,
                contact: { ...pdlUserToEdit.contact, mobilePrivate },
              })
            }
            label={t("pages.pdl.user.mobilePrivate")}
          />

          <Input
            type="email"
            value={pdlUserToEdit.contact.mail}
            onChange={(mail) =>
              setPdlUserToEdit({
                ...pdlUserToEdit,
                contact: { ...pdlUserToEdit.contact, mail },
              })
            }
            label={t("pages.pdl.user.mail")}
          />
          <Input
            type="email"
            value={pdlUserToEdit.contact.mailPrivate || ""}
            onChange={(mailPrivate) =>
              setPdlUserToEdit({
                ...pdlUserToEdit,
                contact: { ...pdlUserToEdit.contact, mailPrivate },
              })
            }
            label={t("pages.pdl.user.mailPrivate")}
          />
          <Input
            type="text"
            value={pdlUserToEdit.contact.street}
            onChange={(street) =>
              setPdlUserToEdit({
                ...pdlUserToEdit,
                contact: { ...pdlUserToEdit.contact, street },
              })
            }
            label={t("pages.pdl.user.street")}
          />

          <Input
            type="text"
            value={pdlUserToEdit.contact.streetNumber}
            onChange={(streetNumber) =>
              setPdlUserToEdit({
                ...pdlUserToEdit,
                contact: { ...pdlUserToEdit.contact, streetNumber },
              })
            }
            label={t("pages.pdl.user.streetNumber")}
          />
          <Input
            type="text"
            value={pdlUserToEdit.contact.city}
            onChange={(city) =>
              setPdlUserToEdit({
                ...pdlUserToEdit,
                contact: { ...pdlUserToEdit.contact, city },
              })
            }
            label={t("pages.pdl.user.city")}
          />
          <Input
            type="text"
            value={pdlUserToEdit.contact.zip}
            onChange={(zip) =>
              setPdlUserToEdit({
                ...pdlUserToEdit,
                contact: { ...pdlUserToEdit.contact, zip },
              })
            }
            label={t("pages.pdl.user.zip")}
          />
          <Dropdown
            selectedOption={pdlUserToEdit.contact.country}
            label={t("pages.pdl.user.country")}
            onChange={(country) =>
              setPdlUserToEdit({
                ...pdlUserToEdit,
                contact: {
                  ...pdlUserToEdit.contact,
                  country: country as Country,
                },
              })
            }
            options={Object.keys(Country).map((country) => ({
              label: t(`pages.pdl.user.countries.${country}`),
              value: country as Country,
            }))}
          />
        </div>
      </Box>
    </form>
  );
};
