import { AxiosInstance } from "axios";

import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import {
  EmployeeUtilization,
  EmployeeOverview,
  NewCustomerOverview,
  RevenueOverview,
  TopCustomer,
  CustomerFeedback,
} from "./kpi.types";

/**
 * API Method to get the customer feedback entries
 * @param axios instance of axios
 * @returns List containing all created entries
 */
export const getCustomerFeedbackEntries = async (
  axios: AxiosInstance
): Promise<CustomerFeedback[]> => {
  return axios
    .get("/kpi/feedback")
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during customer feedback overview fetch", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getCustomerFeedbackEntries"),
      });
      return [];
    });
};

/**
 * API Method to get the top 10 customer entries
 * @param axios instance of axios
 * @returns List containing all created entries
 */
export const getTop10CustomerOverview = async (
  axios: AxiosInstance,
  year: number
): Promise<TopCustomer[]> => {
  return axios
    .get("/kpi/customer", { params: { year } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getTopCustomerEntries"),
      });
      console.error("Error while loading top 10 customers", exc);
      return [];
    });
};

/**
 * API Method to get the new customer entries
 * @param axios instance of axios
 * @returns List containing all created entries
 */
export const getNewCustomerOverview = async (
  axios: AxiosInstance
): Promise<NewCustomerOverview[]> => {
  return axios
    .get("/kpi/customer/new")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getNewCustomerEntries"),
      });
      console.error("Error while loading top 10 customers", exc);
      return [];
    });
};

/**
 * API Method to get the employeeOverview entries
 * @param axios instance of axios
 * @returns List containing all created entries
 */
export const getEmploymentOverview = async (
  axios: AxiosInstance
): Promise<EmployeeOverview[]> => {
  return axios
    .get("/kpi/employee")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getEmploymentEntries"),
      });
      console.error("Error while loading customerOverview", exc);
      return [];
    });
};

/**
 * API Method to get the revenueOverview entries
 * @param axios instance of axios
 * @returns List containing all created entries
 */
export const getRevenueOverview = async (
  axios: AxiosInstance
): Promise<RevenueOverview[]> => {
  return axios
    .get("/kpi/revenue")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getRevenueEntries"),
      });
      console.error("Error while loading revenue overviews", exc);
      return [];
    });
};

/**
 * API Method to get the customer feedback entries
 * @param axios instance of axios
 * @returns List containing all created entries
 */
export const getEmployeeUtilizationOverview = async (
  axios: AxiosInstance
): Promise<EmployeeUtilization[]> => {
  return axios
    .get("/kpi/employee/productivity")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t(
          "general.notification.error.getEmployeeUtilizationEntries"
        ),
      });
      console.error("Error while loading productivity data", exc);
      return [];
    });
};
