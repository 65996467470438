import { Agenda, Box, Button, Input, Table, TopBar } from "@sam/components";
import { AgendaEntryGroup } from "@sam/components/src/Agenda/Agenda.types";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Comment, Holiday, updateHoliday, useData } from "shared";
import { ReactComponent as PendingIcon } from "../../assets/pending.svg";
import { useUser } from "../../components/UserContext";
import { createComment } from "../../utils/comment/Comment.utils";
import { generateAgendaEntries } from "../../utils/holiday/Holiday.utils";
import { getUserNameForSimpleUser } from "../../utils/user/User.utils";

export const HolidayRequestDecide: React.FC = () => {
  const { t } = useTranslation();
  const { user, axios } = useUser();
  const location = useLocation<{ request: Holiday }>();
  const navigate = useNavigate();

  const [agendaEntryGroups, setAgendaEntryGroups] = useState<
    AgendaEntryGroup[]
  >([]);
  const [activeRequest] = useState<Holiday>(location.state!.request!);
  const [decisionComment, setDecisionComment] = useState<Comment>(
    activeRequest.decisionComment || createComment(user.id, "")
  );

  const { data: loadedUsers } = useData("SIMPLEUSERS_ALL", {
    config: { userId: user.id, fallbackData: [] },
  });
  const { data: loadedAbsences } = useData("ABSENCE_ALL", {
    config: { userId: user.id, fallbackData: [] },
  });
  const { data: loadedHolidays } = useData("HOLIDAYS_ALL", {
    config: { userId: user.id, fallbackData: [] },
  });

  // Hook to set the EntryGroups of the Agenda, after all informations are loaded
  useEffect(() => {
    setAgendaEntryGroups(
      generateAgendaEntries(loadedUsers, loadedAbsences, loadedHolidays)
    );
  }, [loadedUsers, loadedAbsences, loadedHolidays]);

  /**
   * Helper method to update the holidays on decisions
   * @param approved boolean if the holiday was approved or denied
   */
  const handleDecideHoliday = (approved: boolean): void => {
    updateHoliday(axios, {
      ...activeRequest,
      decisionComment,
      approved,
      denied: !approved,
    }).then(() => navigate("/holiday/edit"));
  };

  return (
    <>
      <TopBar
        onBackClick={() => navigate("/holiday/edit")}
        title={t("pages.holiday.requestDecision.topBarHeadline")}
      />
      <Box>
        <Agenda entryGroups={agendaEntryGroups} />
      </Box>
      <Box>
        <>
          <Table
            header={
              t("pages.holiday.requestDecision.tableHeader", {
                returnObjects: true,
              }) as TableHeader[]
            }
            rows={[
              {
                id: activeRequest.id,
                content: [
                  getUserNameForSimpleUser(activeRequest.userId, loadedUsers),
                  activeRequest.createDate.toLocaleDateString("DE-de"),
                  activeRequest.startDate.toLocaleDateString("DE-de"),
                  activeRequest.endDate.toLocaleDateString("DE-de"),
                  activeRequest.totalAmount,
                  <PendingIcon width={30} />,
                ],
              },
            ]}
          />
          <div className="holiday-decide__request-comment__wrapper">
            <p className="holiday-decide__request-comment__title">
              {t("pages.holiday.requestDecision.message")}
            </p>
            <p className="holiday-decide__request-comment__content">
              {activeRequest.requestComment?.content}
            </p>
          </div>
          <Input
            type="text"
            value={decisionComment.content}
            label={t("pages.holiday.requestDecision.comment")}
            onChange={(content) =>
              setDecisionComment({ ...decisionComment, content })
            }
          />
          <div className="holiday-decide__button-wrapper">
            <Button
              onClick={() => handleDecideHoliday(true)}
              value={t("general.buttons.holidayApprove")}
              type="button"
              maxWidth={300}
            />
            <Button
              onClick={() => handleDecideHoliday(false)}
              value={t("general.buttons.holidayDeny")}
              type="button"
              backgroundColor="#BC2E46"
              maxWidth={300}
            />
          </div>
        </>
      </Box>
    </>
  );
};
