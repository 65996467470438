import { Option, TableRow } from "@sam/components";
import {
  AdditionalInfoType,
  Office,
  OfficeType,
  SmtpConfiguration,
} from "shared";
import { Right, UserRight } from "shared/src/userRole/UserRole.types";
import { uid } from "uid";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";
import {
  generateEmptyContactInformation,
  isUserAllowedToDo,
} from "../user/User.utils";
import { NavigateFunction } from "react-router-dom";

/**
 * Helper method to generate empty Office
 * @param override to adjust the generated object
 * @returns generated office object
 */
export const generateEmptyOffice = (override?: Partial<Office>): Office => ({
  contact: generateEmptyContactInformation(),
  createDate: new Date(),
  createdBy: "",
  disabled: false,
  id: undefined!,
  name: "",
  companyId: "",
  numberIdentifier: 0,
  officeType: OfficeType.MAIN_OFFICE,
  officeId: "",
  accountingMailConfiguration: generateEmptySmtpConfig(),
  generalMailConfiguration: generateEmptySmtpConfig(),
  additionalInfos: new Map<AdditionalInfoType, string>(),
  hrRoleId: "",
  receiptSignature: "",
  mailSignature: "",
  ...override,
});

/**
 * Util method to create an empty smtpConfiguration
 * @param override partail mailConfig to adjust the created config
 * @returns generated smtpConfig
 */
export const generateEmptySmtpConfig = (
  override?: Partial<SmtpConfiguration>
): SmtpConfiguration => ({
  id: uid(),
  description: "",
  name: "",
  sendingAddress: "",
  sendingName: "",
  smtpServer: "",
  smtpPassword: "",
  smtpPort: "",
  tlsEnabled: true,
  createDate: new Date(),
  createdBy: "",
  ...override,
});
/**
 * Helper to transform given Offices to Tablerows
 * @param offices to create rows from
 * @param navigate to create Edit icon
 * @returns Tablerows of Offices
 */
export const convertOfficesIntoTableEntries = (
  userRight: UserRight,
  offices: Office[],
  navigate: NavigateFunction
): TableRow[] => {
  return offices.map((office) => ({
    id: office.id,
    onClick: () => navigate(`/office/edit`, { state: { office } }),
    content: [
      office.name,
      office.disabled
        ? i18n.t("pages.office.overview.deactivated")
        : i18n.t("pages.office.overview.active"),
      office.contact.city,
      <div>
        {isUserAllowedToDo(userRight, Right.OFFICE_READ) && (
          <EditIcon
            title={i18n.t("general.icons.edit")}
            width={40}
            onClick={(evt) => {
              evt.stopPropagation();
              navigate(`/office/edit`, { state: { office } });
            }}
          />
        )}
      </div>,
    ],
  }));
};

/**
 * Util method to generate DropdownOptions for OfficeType enum
 * @returns generated Options
 */
export const generateDropdownOptionsForOfficeType = (): Option[] =>
  Object.values(OfficeType).map((officeType) => ({
    label: i18n.t(`general.officeType.${officeType}`),
    value: officeType,
  }));

/**
 * Util method to generate dropdownOptions for a given array of offices
 * @param offices to create the options from
 * @returns Array of options
 */
export const generateDropdownOptionsForOffice = (offices: Office[]): Option[] =>
  offices.map((office) => ({ label: office.name, value: office.id }));
