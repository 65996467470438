import { AxiosInstance } from "axios";
import { UserLanguage } from "./UserLanguage.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import { mapReplacer } from "..";

/**
 * API method to get all UserLanguages
 * @param axios instance of axios
 * @returns Promise containing all UserLanguages
 */
export const getAllUserLanguages = async (
  axios: AxiosInstance
): Promise<UserLanguage[]> => {
  return axios
    .get("/language/all")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllUserLanguages"),
      });
      console.error("Error while loading userLanguages", exc);
      return [];
    });
};

/**
 * API method to create a new userLanguage
 * @param axios instacne aof axios
 * @param language to create
 * @returns Promise with the created UserLanguage or undefined on error
 */
export const createNewUserLanguage = async (
  axios: AxiosInstance,
  language: UserLanguage
): Promise<UserLanguage | undefined> => {
  return axios
    .post("/language/", JSON.stringify(language, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewUserLanguage"),
      });
      console.error("Error while creating new userLanguage", exc);
      return undefined;
    });
};

/**
 * API method to update a userLanguage
 * @param axios instacne of axios
 * @param language updated UserLanguage
 * @returns Promise containing the created language or undefined on error
 */
export const updateUserLanguage = async (
  axios: AxiosInstance,
  language: UserLanguage
): Promise<UserLanguage | undefined> => {
  return axios
    .post("/language/update", JSON.stringify(language, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateUserLanguage"),
      });
      console.error("Error while updating userLanguage", exc);
      return undefined;
    });
};

/**
 * API Method to delete a userLanguage and remove it from all users
 * @param axios instance of axios
 * @param id of the UserLanguage to delete
 * @returns Promise containing the amount of users that where edited, -1 on error
 */
export const deleteUserLanguage = async (
  axios: AxiosInstance,
  id: string
): Promise<number> => {
  return axios
    .post("/language/delete", id, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.deleteUserLanguage"),
      });
      console.error("Error while deleting userLanguage", exc);
      return -1;
    });
};
