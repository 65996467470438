import { Box, Input, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { generateNotification } from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import {
  createNewWorkEquipment,
  updateWorkEquipment,
} from "shared/src/workEquipment/WorkEquipment.axios";
import { WorkEquipment } from "shared/src/workEquipment/WorkEquipment.types";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { generateTableRowForLanguageTranslation } from "../../utils/InputUtils";
import { generateEmptyWorkEquipment } from "../../utils/workEquipment/WorkEquipment.utils";

export const WorkEquipmentEdit: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios } = useUser();
  const location = useLocation<{ entry?: WorkEquipment }>();
  const [entryToEdit, setEntryToEdit] = useState<WorkEquipment>(
    location.state?.entry ? location.state.entry : generateEmptyWorkEquipment()
  );
  const isEdit: boolean = useMemo(() => !!entryToEdit.id, [entryToEdit.id]);
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  /**
   * Rows to show and edit the different translations
   */
  const languageNameRows: TableRow[] = useMemo(
    (): TableRow[] =>
      generateTableRowForLanguageTranslation(
        entryToEdit,
        "names",
        setEntryToEdit
      ),
    [entryToEdit]
  );
  /**
   * Submit handler to update or create a workEquipment
    @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    if (isEdit) {
      updateWorkEquipment(axios, entryToEdit).then((updatedEquipment) => {
        if (updatedEquipment && redirect) navigate(-1);
        else if (updatedEquipment) {
          setEntryToEdit(updatedEquipment);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.updateWorkEquipment"),
          });
        }
      });
    } else {
      createNewWorkEquipment(axios, entryToEdit).then((createdEquipment) => {
        if (createdEquipment && redirect) navigate(-1);
        else if (createdEquipment) {
          setEntryToEdit(createdEquipment);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.createWorkEquipment"),
          });
        }
      });
    }
  };
  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={t(
          `pages.workEquipment.edit.topBarHeadline${isEdit ? "Edit" : "Create"}`
        )}
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <Box>
        <div className="three-columns">
          <Input
            onChange={(title) => setEntryToEdit({ ...entryToEdit, title })}
            type="text"
            value={entryToEdit.title}
            label={t("pages.workEquipment.edit.title")}
          />
          <Input
            disabled
            type="date"
            value={entryToEdit.createDate}
            onChangeDate={() => {}}
            label={t("pages.workEquipment.edit.createDate")}
          />
          <Input
            disabled
            type="date"
            value={entryToEdit.lastUpdate}
            onChangeDate={() => {}}
            label={t("pages.workEquipment.edit.lastUpdate")}
          />
        </div>
      </Box>
      <Box>
        <Table
          rows={languageNameRows}
          header={
            t("pages.workEquipment.edit.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </form>
  );
};
