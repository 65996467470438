import { Option } from "@sam/components";
import type { Dispatch, SetStateAction } from "react";
import i18n from "../../client/src/i18n/i18n";
import { Language } from "./Language/Language.types";
import dayjs from "dayjs";

/**
 * Shortcut for creating a promise
 *
 * @param func The function to wrap in a promise
 * @returns The promise which resolves or rejects with the result of the function
 */
export const promised = <T>(func: () => Promise<T>): Promise<T> =>
  new Promise((resolve, reject) => {
    func()
      .then((value) => {
        resolve(value);
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * Helper function to simply update a field in a given object.
 *
 * @param typedObject The object to update a field
 * @param key the field key to update
 * @param value the new value for the field or a function returning the new value
 * @returns the updated object (mutates the original object!)
 */
export const updateField = <Type extends object, Key extends keyof Type>(
  typedObject: Type,
  key: Key,
  value: Type[Key] | ((prevValue: Type[Key]) => Type[Key])
): Type => {
  typedObject[key] =
    value instanceof Function ? value(typedObject[key]) : value;
  return typedObject;
};

/**
 * Internal helper type to infer the state type of a useState setter.
 */
type InferStateSetterType<T> = T extends Dispatch<SetStateAction<infer I>>
  ? I
  : never;

/**
 * A convenience function which wraps the `updateField` function around a useState setter.
 *
 * @param stateSetter The useState setter
 * @param key The key of the field to update
 * @param value The new value for the field or a function returning the new value
 * @returns void
 */
export const updateStateField = <
  Setter extends Dispatch<SetStateAction<object>>,
  Type extends object = InferStateSetterType<Setter>,
  Key extends keyof Type = keyof Type
>(
  stateSetter: Setter,
  key: Key,
  value: Type[Key] | ((prevValue: Type[Key]) => Type[Key])
): void =>
  stateSetter((prevState: Type) => {
    const newState = { ...prevState };
    newState[key] = value instanceof Function ? value(prevState[key]) : value;
    return newState;
  });

/**
 * Util method to generate DropdownOptions for languages
 * @returns array of Options
 */
export const generateDropdownOptionsForLanguage = (): Option[] => {
  return Object.values(Language).map((language) => ({
    label: i18n.t(`general.languages.${language}`),
    value: language,
  }));
};
/**
 * Util method to add or remove a value from an array.
 * Works with every object where the "!==" and "includes" operation is possible like strings or enums
 * @param array array to add or remove a value from
 * @param value to add if it is not included or remove if it is already present in the array
 * @returns updated array with removed or added value
 */
export const addOrRemove = <T>(array: T[], value: T): T[] =>
  array.includes(value)
    ? array.filter((arrayValue) => arrayValue !== value)
    : [...array, value];

/**
 * helper to correctly transform a map or set object into a corresponding JS object to handle
 * POST requests in axios
 */
export const mapReplacer = (_key: string, value: unknown): unknown => {
  if (value instanceof Map) {
    const obj: Record<string, unknown> = {};
    for (const [key, entry] of value) {
      if (key instanceof Date)
        obj[dayjs(key).format("YYYY-MM-DD")] = mapReplacer(
          dayjs(key).format("YYYY-MM-DD"),
          entry
        );
      // recursive call in case of a map inside of a map
      else obj[key as string] = mapReplacer(key, entry);
    }
    return obj;
  }
  return value;
};

/**
 * Util method to generate dropdown Options from an array of objects
 * @param entries Array containing all entries
 * @param label the key of the field to be used as label
 * or an Array of keys if multiple keys should be used
 * @param value the value key that should be used for the option
 * @returns array of dropdownOptions
 */
export const generateDropdownOptions = <T>(
  entries: T[],
  label: keyof T | Array<keyof T>,
  value: keyof T
): Option[] => {
  if (typeof label === "string") {
    return entries.map((entry) => ({
      label: String(entry[label]),
      value: String(entry[value]),
    }));
  } else if (Array.isArray(label)) {
    return entries.map((entry) => {
      let labelString: string = "";
      label.forEach(
        (key) => (labelString = `${labelString} ${String(entry[key])}`)
      );

      return {
        label: labelString,
        value: String(entry[value]),
      };
    });
  } else return [];
};

/**
 * Util to convert the given date and optional time into a date
 * ISO string. If no time is given the implicit time from the
 * original date is used.
 *
 * @param date The date of the target object
 * @param time Optional time of the date
 * @returns A string in ISO Date format
 */
export const generateIsoDateForDateAndTime = (
  date: Date,
  time?: number
): string => {
  const targetDate: Date = new Date(date);
  if (time) {
    targetDate.setHours(+(time / 60).toFixed(0));
    targetDate.setMinutes(time % 60);
  }
  return targetDate.toISOString();
};
