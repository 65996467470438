import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { CrmManageType, SchambeckUser } from "shared";
import { UserProvider } from "./components/UserContext";
import Dashboard from "./pages/Dashboard";
import AbsenceReasonCreate from "./pages/absenceReason/AbsenceReasonCreate";
import AbsenceReasonOverview from "./pages/absenceReason/AbsenceReasonOverview";
import BikeCreateOrEdit from "./pages/bike/BikeCreateOrEdit";
import BikeOverview from "./pages/bike/BikeOverview";
import { BranchesEdit } from "./pages/branches/BranchesEdit";
import { BranchesOverview } from "./pages/branches/BranchesOverview";
import CarCreateOrEdit from "./pages/car/CarCreateOrEdit";
import CarOverview from "./pages/car/CarOverview";
import { ChangesOverview } from "./pages/changeHistory/ChangesOverview";
import { CompanyCreateOrEdit } from "./pages/company/CompanyCreateOrEdit";
import { CompanyOverview } from "./pages/company/CompanyOverview";
import { CurrencyEdit } from "./pages/currency/CurrencyEdit";
import { CurrencyOverview } from "./pages/currency/CurrencyOverview";
import { CustomerCreateOrEdit } from "./pages/customer/CustomerCreateOrEdit";
import { CustomerOverview } from "./pages/customer/CustomerOverview";
import { CustomerFeedbackEntryEdit } from "./pages/customerFeedbackEntry/CustomerFeedbackEntryEdit";
import { CustomerFeedbackEntryOverview } from "./pages/customerFeedbackEntry/CustomerFeedbackEntryOverview";
import { CreateOrEditCustomerLocation } from "./pages/customerLocation/CreateOrEditCustomerLocation";
import { CustomerLocationOverview } from "./pages/customerLocation/CustomerLocationOverview";
import { CreateOrEditCustomerUsers } from "./pages/customerUser/CreateOrEditCustomerUser";
import { CustomerUserOverview } from "./pages/customerUser/CustomerUserOverview";
import HolidayCreateOrEdit from "./pages/holiday/HolidayCreate";
import HolidayOverview from "./pages/holiday/HolidayOverview";
import { HolidayRequestDecide } from "./pages/holiday/HolidayRequestDecide";
import HolidayRequestOverview from "./pages/holiday/HolidayRequestOverview";
import InventoryCreateOrEdit from "./pages/inventory/InventoryCreateOrEdit";
import InventoryOverview from "./pages/inventory/InventoryOverview";
import { InvoiceCreate } from "./pages/invoice/InvoiceCreate";
import { InvoiceEdit } from "./pages/invoice/InvoiceEdit";
import { InvoiceOverview } from "./pages/invoice/InvoiceOverview";
import { AccountingOverview } from "./pages/invoicePayment/AccountingOverview";
import { InvoicePaymentCreate } from "./pages/invoicePayment/InvoicePaymentCreate";
import { NumberRangeOverview } from "./pages/numberRanges/NumberRangesOverview";
import CreateOrEditOffice from "./pages/office/CreateOrEditOffice";
import OfficeOverview from "./pages/office/OfficeOverview";
import OfficialHolidayOverview from "./pages/officialHoliday/OfficialHolidayOverview";
import { OfferCreate } from "./pages/order/OfferCreate";
import { OfferOverview } from "./pages/order/OfferOverview";
import { PaymentConditionEdit } from "./pages/paymentCondition/PaymentConditionEdit";
import { PaymentConditionOverview } from "./pages/paymentCondition/PaymentConditionOverview";
import { PlanningBoard } from "./pages/planningBoard/PlanningBoard";
import { ProjectEdit } from "./pages/project/ProjectEdit";
import { ProjectOverview } from "./pages/project/ProjectOverview";
import { ProjectPlanningPage } from "./pages/project/ProjectPlanning";
import { ProjectProtocolCreate } from "./pages/projectProtocol/ProjectProtocol";
import MonthlySummary from "./pages/summary/MonthlySummary";
import { TaskCreateOrEdit } from "./pages/task/TaskCreateOrEdit";
import { TaskOverview } from "./pages/task/TaskOverview";
import TimeKeepingPage from "./pages/timeKeeping/TimeKeeping";
import TimeKeepingTrigger from "./pages/timeKeeping/TimeKeepingTrigger";
import UserCreateOrEditPage from "./pages/user/UserCreateOrEdit";
import UserOverviewPage from "./pages/user/UserOverview";
import UserRolesCreateOrEdit from "./pages/userRoles/UserRolesCreateOrEdit";
import UserRoleOverview from "./pages/userRoles/UserRolesOverview";
import { WorkInstructionEdit } from "./pages/workInstruction/WorkIntructionEdit";
import { WorkQualificationEdit } from "./pages/workQualification/WorkQualificationEdit";
import { WorkQualificationOverview } from "./pages/workQualification/WorkQualificationOverview";
import { DunningLevelOverview } from "./pages/dunningLevel/DunningLevelOverview";
import { DunningLevelEdit } from "./pages/dunningLevel/DunningLevelEdit";
import { BusinessAreaOverview } from "./pages/businessArea/BusinessAreaOverview";
import { BusinessAreaEdit } from "./pages/businessArea/BusinessAreaEdit";
import { UserLanguageOverview } from "./pages/userLanguages/UserLanguagesOverview";
import { UserLanguageEdit } from "./pages/userLanguages/UserLanguagesEdit";
import { WorkEquipmentOverview } from "./pages/workEquipment/WorkEquipmentOverview";
import { WorkEquipmentEdit } from "./pages/workEquipment/WorkEquipmentEdit";
import { ProtocolOverview } from "./pages/protocol/ProtocolOverview";
import { UserFunctionOverview } from "./pages/userFunction/UserFunctionOverview";
import { UserFunctionEdit } from "./pages/userFunction/UserFunctionEdit";
import { DepartmentOverview } from "./pages/department/DepartmentOverview";
import { DepartmentEdit } from "./pages/department/DepartmentEdit";
import { MailConfigOverview } from "./pages/mailConfig/MailConfigOverview";
import { MailConfigEdit } from "./pages/mailConfig/MailConfigEdit";
import PdlOverviewPage from "./pages/pdl/PdlOverview";
import { PdlEdit } from "./pages/pdl/PdlEdit";
import { PdlUserEdit } from "./pages/pdl/PdlUserEdit";
import { CrmManageOverview } from "./pages/crm/CrmManageOverview";
import { CrmManageEdit } from "./pages/crm/CrmManageEdit";
import { CrmEntryOverview } from "./pages/crm/CrmEntryOverview";
import { CrmEntryEdit } from "./pages/crm/CrmEntryEdit";
import { DeliveryNoteCreate } from "./pages/deliveryNote/DeliveryNoteCreate";
import { ActivityProofCreate } from "./pages/activityProof/ActivityProofCreate";
import CustomerArticleOverview from "./pages/customerArticle/CustomerArticleOverview";
import CustomerArticleCreateOrEdit from "./pages/customerArticle/CustomerArticleCreateOrEdit";
import { LockDatePage } from "./pages/lockdate/LockDate";
import { CustomerProjectOverview } from "./pages/customerProject/CustomerProjectOverview";
import { CustomerProjectCreateOrEdit } from "./pages/customerProject/CustomerProjectCreateOrEdit";
import { DunningOverview } from "./pages/dunning/DunningOverview";

// NOTE: we use the new react router syntax and lazy loading (for speed and code splitting)
const router = createBrowserRouter([
  {
    lazy: () => import("./components/PrivateLayout"),
    children: [{ path: "/", element: <Dashboard /> }],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/user",
    children: [
      { index: true, element: <UserOverviewPage /> },
      { path: "create", element: <UserCreateOrEditPage /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/pdl",
    children: [
      { index: true, element: <PdlOverviewPage /> },
      { path: "edit", element: <PdlEdit /> },
      { path: "user/edit", element: <PdlUserEdit /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/holiday",
    children: [
      { index: true, element: <HolidayOverview /> },
      { path: "create", element: <HolidayCreateOrEdit readOnly={false} /> },
      {
        path: "read",
        element: <HolidayCreateOrEdit readOnly />,
      },
      { path: "edit", element: <HolidayRequestOverview /> },
      { path: "/holiday/edit/decide", element: <HolidayRequestDecide /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/officialholiday",
    children: [
      {
        index: true,
        element: <OfficialHolidayOverview />,
      },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/timekeeping",
    children: [
      {
        index: true,
        element: <TimeKeepingPage />,
      },
      { path: "nfc", element: <TimeKeepingTrigger /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/office",
    children: [
      {
        index: true,
        element: <OfficeOverview />,
      },
      { path: "edit", element: <CreateOrEditOffice /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/absence/reason",
    children: [
      {
        index: true,
        element: <AbsenceReasonOverview />,
      },
      { path: "create", element: <AbsenceReasonCreate /> },
      { path: "edit", element: <AbsenceReasonCreate /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/absence",
    children: [
      {
        index: true,
        element: <PlanningBoard />,
      },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/roles",
    children: [
      {
        index: true,
        element: <UserRoleOverview />,
      },
      { path: "create", element: <UserRolesCreateOrEdit /> },
      { path: "edit", element: <UserRolesCreateOrEdit /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/cars",
    children: [
      {
        index: true,
        element: <CarOverview />,
      },
      { path: "create", element: <CarCreateOrEdit /> },
      { path: "edit", element: <CarCreateOrEdit /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/customer/articles",
    children: [
      {
        index: true,
        element: <CustomerArticleOverview />,
      },
      { path: "create", element: <CustomerArticleCreateOrEdit /> },
      { path: "edit", element: <CustomerArticleCreateOrEdit /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/bikes",
    children: [
      {
        index: true,
        element: <BikeOverview />,
      },
      { path: "create", element: <BikeCreateOrEdit /> },
      { path: "edit", element: <BikeCreateOrEdit /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/summary",
    children: [
      {
        index: true,
        element: <MonthlySummary />,
      },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/task",
    children: [
      {
        index: true,
        element: <TaskOverview />,
      },
      { path: "create", element: <TaskCreateOrEdit /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/customer",
    children: [
      {
        index: true,
        element: <CustomerOverview />,
      },
      { path: "create", element: <CustomerCreateOrEdit /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/customer/user",
    children: [
      {
        index: true,
        element: <CustomerUserOverview />,
      },
      { path: "create", element: <CreateOrEditCustomerUsers /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "customer/location",
    children: [
      { index: true, element: <CustomerLocationOverview /> },
      { path: "create", element: <CreateOrEditCustomerLocation /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/offer",
    children: [
      { index: true, element: <OfferOverview /> },
      { path: "create", element: <OfferCreate /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/numberranges",
    children: [{ index: true, element: <NumberRangeOverview /> }],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/project",
    children: [
      { index: true, element: <ProjectOverview /> },
      { path: "edit", element: <ProjectEdit /> },
      { path: "planning", element: <ProjectPlanningPage /> },
      { path: "instruction", element: <WorkInstructionEdit /> },
      { path: "protocol", element: <ProjectProtocolCreate /> },
      { path: "create", element: <OfferCreate createProject /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/qualifications",
    children: [
      { index: true, element: <WorkQualificationOverview /> },
      { path: "edit", element: <WorkQualificationEdit /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/company",
    children: [
      { index: true, element: <CompanyOverview /> },
      { path: "edit", element: <CompanyCreateOrEdit /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/payment",
    children: [
      { index: true, element: <PaymentConditionOverview /> },
      { path: "edit", element: <PaymentConditionEdit /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/branche",
    children: [
      { index: true, element: <BranchesOverview /> },
      { path: "edit", element: <BranchesEdit /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/invoice",
    children: [
      { index: true, element: <InvoiceOverview /> },
      { path: "create", element: <InvoiceCreate /> },
      { path: "edit", element: <InvoiceEdit /> },
      { path: "due", element: <DunningOverview /> },
    ],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/changes",
    children: [{ index: true, element: <ChangesOverview /> }],
  },
  {
    lazy: () => import("./components/PrivateLayout"),
    path: "/inventory",
    children: [
      { index: true, element: <InventoryOverview /> },
      { path: "new", element: <InventoryCreateOrEdit /> },
      { path: "edit", element: <InventoryCreateOrEdit /> },
    ],
  },
  {
    path: "/currency",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      { index: true, element: <CurrencyOverview /> },
      { path: "edit", element: <CurrencyEdit /> },
    ],
  },
  {
    path: "/customer/feedback",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      { index: true, element: <CustomerFeedbackEntryOverview /> },
      { path: "edit", element: <CustomerFeedbackEntryEdit /> },
    ],
  },
  {
    path: "/crm/manage",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      {
        path: "types",
        element: <CrmManageOverview type={CrmManageType.TYPE} />,
      },
      {
        path: "types/edit",
        element: <CrmManageEdit type={CrmManageType.TYPE} />,
      },
      {
        path: "actions",
        element: <CrmManageOverview type={CrmManageType.ACTION} />,
      },
      {
        path: "actions/edit",
        element: <CrmManageEdit type={CrmManageType.ACTION} />,
      },
    ],
  },
  {
    path: "/crm",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      { index: true, element: <CrmEntryOverview /> },
      { path: "edit", element: <CrmEntryEdit /> },
    ],
  },
  {
    path: "/workequipment",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      { index: true, element: <WorkEquipmentOverview /> },
      { path: "edit", element: <WorkEquipmentEdit /> },
    ],
  },
  {
    path: "/department",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      { index: true, element: <DepartmentOverview /> },
      { path: "edit", element: <DepartmentEdit /> },
    ],
  },
  {
    path: "/userfunction",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      { index: true, element: <UserFunctionOverview /> },
      { path: "edit", element: <UserFunctionEdit /> },
    ],
  },
  {
    path: "/accounting",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      { index: true, element: <AccountingOverview /> },
      { path: "invoice", element: <InvoicePaymentCreate /> },
    ],
  },
  {
    path: "/dunning",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      { index: true, element: <DunningLevelOverview /> },
      { path: "edit", element: <DunningLevelEdit /> },
    ],
  },
  {
    path: "/businessarea",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      { index: true, element: <BusinessAreaOverview /> },
      { path: "edit", element: <BusinessAreaEdit /> },
    ],
  },
  {
    path: "/language",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      { index: true, element: <UserLanguageOverview /> },
      { path: "edit", element: <UserLanguageEdit /> },
    ],
  },
  {
    path: "/protocol/overview",
    lazy: () => import("./components/PrivateLayout"),
    children: [{ index: true, element: <ProtocolOverview /> }],
  },
  {
    path: "/mail",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      { index: true, element: <MailConfigOverview /> },
      { path: "edit", element: <MailConfigEdit /> },
    ],
  },
  {
    path: "/delivery",
    lazy: () => import("./components/PrivateLayout"),
    children: [{ index: true, element: <DeliveryNoteCreate /> }],
  },
  {
    path: "/user/activity",
    lazy: () => import("./components/PrivateLayout"),
    children: [{ index: true, element: <ActivityProofCreate /> }],
  },
  {
    path: "/lockdate",
    lazy: () => import("./components/PrivateLayout"),
    children: [{ index: true, element: <LockDatePage /> }],
  },
  {
    path: "/customer/project",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      { index: true, element: <CustomerProjectOverview /> },
      { path: "edit", element: <CustomerProjectCreateOrEdit /> },
    ],
  },
  {
    path: "/kpi",
    lazy: () => import("./components/PrivateLayout"),
    children: [
      {
        index: true,
        lazy: () => import("./pages/kpi/KpiOverview"),
      },
    ],
  },
]);

const Router: React.FC<{ user: SchambeckUser | null }> = () => {
  return <RouterProvider router={router} />;
};

export const App: React.FC = () => {
  const { keycloak, initialized } = useKeycloak();
  /**
   * Helper variable to check if the user is logged in. Needed so that the page is not
   * shortly shown if the user is not logged in
   */
  const [isLoggedIn, toggleLoggedIn] = useState<boolean>(false);

  // listens on keycloak updates and redirects to the login page if necessary
  useEffect(() => {
    if (isLoggedIn) return;
    if (initialized && !keycloak.authenticated) keycloak.login();
    if (initialized && keycloak.authenticated) toggleLoggedIn(true);
  }, [keycloak, initialized, isLoggedIn]);

  return isLoggedIn ? (
    <UserProvider
      fallback={<Router user={null} />}
      loading={<h2>TODO LOADER</h2>}
    >
      {(user) => <Router user={user} />}
    </UserProvider>
  ) : (
    <h2>TODO LOADER</h2>
  );
};
