import { ToggleProps } from "./Toggle.types";
import "./Toggle.scss";

export const Toggle: React.FC<ToggleProps> = ({
  active,
  onToggle,
  label = "",
  disabled = false,
}) => {
  return (
    <div className="toggle">
      <input
        data-testId="toggle-input"
        disabled={disabled}
        type="checkbox"
        checked={active}
        onChange={(evt) => onToggle(evt.target.checked)}
      />
      <label>{label}</label>
    </div>
  );
};
