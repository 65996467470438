/**
 * This error is used during creation or update of a task in case the
 * desired price type is already in use
 */
export class StandardPriceTypeAlreadyTakenException extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, StandardPriceTypeAlreadyTakenException.prototype);
  }
}
