import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Right, useData } from "shared";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { generateCustomerArticleTableEntries } from "../../utils/CustomerArticle.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

const CustomerArticleOverview: React.FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<string>("");

  const { data: allArticles } = useData("CUSTOMER_ARTICLE_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCustomer } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allCurrencies } = useData("CURRENCY_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  //Holds TableRows for the customer articles
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      generateCustomerArticleTableEntries(
        allArticles,
        allCustomer,
        allOffices,
        allCurrencies,
        navigate
      ),
    [allArticles, allCurrencies, allCustomer, allOffices, navigate]
  );

  return (
    <>
      <TopBar
        title={t("pages.customerArticleOverview.headline")}
        onSearch={setFilterValue}
      >
        {isUserAllowedToDo(user.right, Right.CUSTOMER_ARTICLE_CREATE) && (
          <AddIcon
            title={t("general.icons.add")}
            onClick={() => navigate("create")}
          />
        )}
      </TopBar>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.customerArticleOverview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
export default CustomerArticleOverview;
