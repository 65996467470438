import { AxiosInstance } from "axios";
import { BusinessArea } from "./BusinessArea.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";

/**
 * API Method to create a new businessArea
 * @param axios instance of axios
 * @param businessArea  to create on the database
 * @returns Promise with created area or undefined on error
 */
export const createNewBusinessArea = async (
  axios: AxiosInstance,
  businessArea: BusinessArea
): Promise<BusinessArea | undefined> => {
  return axios
    .post("/businessarea/", businessArea)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewBusinessArea"),
      });
      console.error("Error while saving businessArea", exc);
      return undefined;
    });
};

/**
 * API method to update a businessArea
 * @param axios instance of axios
 * @param businessArea updated businessArea
 * @returns Promise with the created area or undefined on error
 */
export const updateBusinessArea = async (
  axios: AxiosInstance,
  businessArea: BusinessArea
): Promise<BusinessArea | undefined> => {
  return axios
    .post("/businessarea/update", businessArea)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateBusinessArea"),
      });
      console.error("Error while updating businessArea", exc);
      return undefined;
    });
};

/**
 * API method to get all businessAreas
 * @param axios  instance of axios
 * @returns Promise containing an array of businessAreas
 */
export const getAllBusinessAreas = async (
  axios: AxiosInstance
): Promise<BusinessArea[]> => {
  return axios
    .get("/businessarea/all")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllBusinessAreas"),
      });
      console.error("Error while loading businessAreas", exc);
      return [];
    });
};

/**
 * API method to get all active businessAreas
 * @param axios  instance of axios
 * @returns Promise containing an array of businessAreas
 */
export const getAllActiveBusinessAreas = async (
  axios: AxiosInstance
): Promise<BusinessArea[]> => {
  return axios
    .get("/businessarea/all/active")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllBusinessAreas"),
      });
      console.error("Error while loading businessAreas", exc);
      return [];
    });
};

/**
 * API method to get a businessArea by id
 * @param axios instance of axios
 * @param id of the businessArea to load
 * @returns Promise containing the found area or undefined
 */
export const getBusinessAreaById = async (
  axios: AxiosInstance,
  id: string
): Promise<BusinessArea | undefined> => {
  return axios
    .get("/businessarea/", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getBusinessAreaById"),
      });
      console.error("Error while loading businessArea", exc);
      return undefined;
    });
};

/**
 * API method to deactivate a businessArea
 * @param axios instance of axios
 * @param id of the area to deactivate
 * @returns  Promise containing the updated area or undefined
 */
export const deactivateBusinessArea = async (
  axios: AxiosInstance,
  id: string
): Promise<BusinessArea | undefined> => {
  return axios
    .post("/businessarea/deactivate", id, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.deactivateusinessArea"),
      });
      console.error("Error while deactivating businessArea", exc);
      return undefined;
    });
};
