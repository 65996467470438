import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useData } from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { convertCustomerLocationsIntoOverviewTableEntries } from "../../utils/customerLocation/CustomerLocation.utils";

export const CustomerLocationOverview: React.FC = () => {
  const [filterValue, setFilterValue] = useState<string>("");
  const { data: loadedCustomerLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const navigate = useNavigate();
  const { t } = useTranslation();

  //Hook to set tableRows after customers and locations are loaded
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertCustomerLocationsIntoOverviewTableEntries(
        loadedCustomerLocations,
        navigate,
        loadedCustomers
      ),
    [loadedCustomerLocations, loadedCustomers, navigate]
  );

  return (
    <>
      <TopBar
        title={t("pages.customerLocation.overview.topBarHeadline")}
        onSearch={setFilterValue}
      >
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("/customer/location/create")}
        />
      </TopBar>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.customerLocation.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
