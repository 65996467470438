import { Button, Popup } from "@sam/components";
import { useTranslation } from "react-i18next";
import { Customer } from "shared";

/**
 * Used for showing an informational popup that the given user needs
 * a order number to use
 */
export const SoftLockedPopup: React.FC<{
  customer?: Customer;
  closePopup(): void;
}> = ({ customer, closePopup }) => {
  const { t } = useTranslation();

  return (
    <Popup
      isOpen={!!customer}
      onClose={closePopup}
      buttons={[
        <Button
          value={t("general.softlockedCustomer.button")}
          onClick={closePopup}
        />,
      ]}
    >
      <h1 className="soft-locked__popup__title">
        {t("general.softlockedCustomer.title")}
      </h1>
      <p className="soft-locked__popup__text">
        {t("general.softlockedCustomer.content")}
      </p>
    </Popup>
  );
};
