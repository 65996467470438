import { Box, Table, TableRow, TopBar } from "@sam/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useData } from "shared";
import { convertChangeHistoryIntoTableEntries } from "../../utils/ChangeHistory/ChangeHistory.util";
import { TableHeader } from "@sam/components/src/Table/Table.types";

export const ChangesOverview: React.FC = () => {
  const { t } = useTranslation();
  const { data: loadedHistory } = useData("CHANGE_HISTORY_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Rows to be displayed containing one row for each changeHistory object
   */
  const entryRows: TableRow[] = useMemo(
    () => convertChangeHistoryIntoTableEntries(loadedHistory),
    [loadedHistory]
  );
  return (
    <>
      <TopBar title={t("pages.changes.overview.topBarHeadline")} />
      <Box>
        <Table
          rows={entryRows}
          header={
            t("pages.changes.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
