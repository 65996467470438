import { Box, Button, Checkbox, Input, Popup, TopBar } from "@sam/components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Right,
  SimpleUser,
  UserRole,
  createNewUserRole,
  generateNotification,
  loadSimpleUsersWithRole,
  toggleDisableUserRole,
  updateUserRoles,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { NotAllowed } from "../../components/NotAllowed";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import {
  generateEmptyUserRole,
  isUserAllowedToDo,
  updateRoleRights,
} from "../../utils/user/User.utils";

const UserRolesCreateOrEdit = () => {
  const { user, axios } = useUser();
  const location = useLocation<{ historyRole: UserRole }>();
  const [role, setRole] = useState<UserRole>(
    location.state?.historyRole
      ? location.state?.historyRole
      : generateEmptyUserRole(user.id)
  );
  const [showDisablePopup, setShowDisablePopup] = useState<boolean>(false);
  const [usersWithRole, setUsersWithRole] = useState<SimpleUser[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  //useEffect hook to load all simpleUsers with the role to display
  useEffect(() => {
    if (!role.id) return;
    loadSimpleUsersWithRole(axios, role.id).then((loadedUsers) =>
      setUsersWithRole(loadedUsers)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);
  /**
   * Submit methood that creates or updates an UserRole
   * @param redirect decides if the method should navigate back after success

   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    if (location.state?.historyRole)
      updateUserRoles(axios, role).then((updatedRole) => {
        if (updatedRole && redirect) navigate("/roles");
        else if (updatedRole)
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
      });
    else
      createNewUserRole(axios, role).then((createdRole) => {
        if (createdRole && redirect) navigate("/roles");
        else if (createdRole)
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
      });
  };

  return isUserAllowedToDo(user.right, Right.USERROLE_CREATE) ? (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        title={
          location.state?.historyRole
            ? t("pages.userRole.createOrEdit.editHeadline")
            : t("pages.userRole.createOrEdit.createHeadline")
        }
        onBackClick={() => navigate(-1)}
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <Box>
        <>
          <Popup
            isOpen={showDisablePopup}
            title={t("pages.userRole.createOrEdit.deactivatePopup")}
            buttons={[
              <Button
                type="button"
                value={t("general.buttons.yes")}
                onClick={() =>
                  toggleDisableUserRole(axios, role.id).then(() =>
                    navigate("/roles")
                  )
                }
              />,
              <Button
                type="button"
                value={t("general.buttons.no")}
                onClick={() => setShowDisablePopup(false)}
              />,
            ]}
            onClose={() => setShowDisablePopup(false)}
          >
            <p>
              {role.disabled
                ? t("general.popup.activateUserRole")
                : usersWithRole.length > 0
                ? t("general.popup.toggleDisableUserRole")
                : t("general.popup.deactivateEmptyUserRole")}
            </p>
            {!role.disabled && usersWithRole.length > 0 && (
              <ul>
                {usersWithRole.map((user, index) => (
                  <li key={`users-with-role-${index}`}>
                    {user.firstName} {user.lastName}
                  </li>
                ))}
              </ul>
            )}
          </Popup>
          <p>
            {location.state?.historyRole
              ? t("pages.userRole.createOrEdit.editRole")
              : t("pages.userRole.createOrEdit.createRole")}
          </p>
          <Input
            type="text"
            value={role.name}
            onChange={(name) => setRole({ ...role, name })}
          />
          <div className="three-columns">
            {Object.values(Right).map((right) => (
              <Checkbox
                label={t(`general.rights.${right}`)}
                isChecked={role.rights.includes(right)}
                onCheck={() => setRole(updateRoleRights(role, right))}
              />
            ))}
            {isUserAllowedToDo(user.right, Right.USERROLE_EDIT) && (
              <Button
                type="button"
                value={
                  role.disabled
                    ? t("pages.userRole.createOrEdit.enable")
                    : t("pages.userRole.createOrEdit.disable")
                }
                onClick={() => setShowDisablePopup(true)}
              />
            )}
          </div>
        </>
      </Box>
    </form>
  ) : (
    <NotAllowed neccessaryRight={Right.USERROLE_CREATE} />
  );
};

export default UserRolesCreateOrEdit;
