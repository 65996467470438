import { Input, TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import { Branche, Language } from "shared";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import i18n from "../../i18n/i18n";
import { Dispatch, SetStateAction } from "react";

/**
 * Util method to convert branches into TableRows
 * @param branches to convert
 * @returns array of tableRows
 */
export const convertBranchesIntoTableEntries = (
  branches: Branche[],
  navigate: NavigateFunction,
  toggleDelete: (brancheId: Branche) => void
): TableRow[] => {
  return branches.map((branche) => ({
    id: branche.id,
    onClick: () => navigate("/branche/edit", { state: { branche } }),
    content: [
      branche.name,
      <div>
        <EditIcon
          title={i18n.t("general.icons.edit")}
          width={30}
          onClick={(evt) => {
            evt.stopPropagation();
            navigate("/branche/edit", { state: { branche } });
          }}
        />
        <DeleteIcon
          title={i18n.t("general.icons.delete")}
          width={30}
          onClick={(evt) => {
            evt.stopPropagation();
            toggleDelete(branche);
          }}
        />
      </div>,
    ],
  }));
};

/**
 * Util method to create an empty branche
 * @param override partial branche to adjust created object
 * @returns generated branche
 */
export const generateEmptyBranche = (override?: Partial<Branche>): Branche => ({
  id: undefined!,
  name: "",
  translations: new Map<Language, string>(),
  ...override,
});

/**
 * Util method to generate tableRows for the languages of branches
 * @param branche to display
 * @param updateBranche set state action to update the branche
 * @returns Array of TableRows
 */
export const generateLanguageRowsForBranche = (
  branche: Branche,
  updateBranche: Dispatch<SetStateAction<Branche>>
): TableRow[] =>
  Object.values(Language).map((language) => ({
    id: language,
    content: [
      i18n.t(`general.languages.${language}`),
      <Input
        type="text"
        value={branche.translations.get(language)}
        onChange={(value) => {
          if (branche?.translations) {
            const mapToEdit: Map<Language, string> = new Map<Language, string>(
              branche?.translations
            );
            mapToEdit.set(language, value);
            updateBranche({ ...branche, translations: mapToEdit });
          } else {
            const createdMap: Map<Language, string> = new Map<
              Language,
              string
            >();
            createdMap.set(language, value);
            updateBranche({ ...branche, translations: createdMap });
          }
        }}
      />,
    ],
  }));
