import { MailConfiguration, MailTemplate, MailType } from "shared";
import { generateEmptySmtpConfig } from "../office/Office.utils";
import { Language } from "shared/src/Language/Language.types";

/**
 * Util method to generate an empty mailConfiguration
 * @param override  partial configuration to adjust the createdObject
 * @returns generated MailConfiguration
 */
export const generateEmptyMailConfiguration = (
  override?: Partial<MailConfiguration>
): MailConfiguration => ({
  content: "",
  receiver: "",
  receiverCC: [],
  smtpConfig: generateEmptySmtpConfig(),
  subject: "",
  type: MailType.CONNECTION_TEST,
  ...override,
});

/**
 * Util method to generate a new MailTemplate
 * @param override Partial MailTemplate to adjust created object
 * @returns created MailTemplate
 */
export const generateNewMailTemplate = (
  override?: Partial<MailTemplate>
): MailTemplate => ({
  id: undefined!,
  mailContent: new Map<Language, string>(),
  type: undefined!,
  createDate: new Date(),
  createdBy: "",
  subject: new Map<Language, string>(),
  ...override,
});

/**
 * Util method to filter mailTypes that cant be edited
 * @param types List of mailTypes to filter
 * @returns  array of editable mailTypes
 */
export const filterFixedMails = (types: MailType[]): MailType[] => {
  const fixedTypes: MailType[] = [
    MailType.PROPATION_REMINDER,
    MailType.FEEDBACK_REMINDER,
    MailType.INTERNAL_CRM,
  ];
  return types.filter((type) => !fixedTypes.includes(type));
};
