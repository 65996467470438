import {
  Box,
  Checkbox,
  Dropdown,
  Input,
  Table,
  TableRow,
  TopBar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  generateDropdownOptions,
  generateNotification,
  useData,
  WorkQualification,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import {
  createNewWorkQualification,
  updateWorkQualification,
} from "shared/src/workQualification/WorkQualification.axios";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { generateTableRowForLanguageTranslation } from "../../utils/InputUtils";
import { createNewQualification } from "../../utils/workQualification/WorkQualification.utils";

export const WorkQualificationEdit: React.FC = () => {
  const { axios } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation<{ qualification?: WorkQualification }>();
  const { data: allUserFunctions } = useData("USER_FUNCTION_ALL", {
    config: { fallbackData: [] },
  });
  const [qualiToEdit, setQualiToEdit] = useState<WorkQualification>(
    location.state?.qualification
      ? location.state.qualification
      : createNewQualification()
  );

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  /**
   * Rows to show and edit the different translations
   */
  const languageNameRows: TableRow[] = useMemo(
    (): TableRow[] =>
      generateTableRowForLanguageTranslation(
        qualiToEdit,
        "names",
        setQualiToEdit
      ),
    [qualiToEdit]
  );

  /**
   * Util method to update or create a qualification on submit
  @param redirect decides if the method should navigate back after success
  */

  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;
    qualiToEdit.id
      ? updateWorkQualification(axios, qualiToEdit).then(
          (updatedQualification) => {
            if (redirect && updatedQualification) navigate("/qualifications");
            else if (updatedQualification) {
              setQualiToEdit(updatedQualification);
              generateNotification({
                type: NotificationType.SUCCESS,
                value: t("general.notification.success.saveSuccessfull"),
              });
            }
          }
        )
      : createNewWorkQualification(axios, qualiToEdit).then(
          (createdQualification) => {
            if (redirect && createdQualification) navigate("/qualifications");
            else if (createdQualification) {
              setQualiToEdit(createdQualification);
              generateNotification({
                type: NotificationType.SUCCESS,
                value: t("general.notification.success.saveSuccessfull"),
              });
            }
          }
        );
  };

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          qualiToEdit.id
            ? t("pages.qualifications.edit.topBarHeadlineEdit")
            : t("pages.qualifications.edit.topBarHeadlineCreate")
        }
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <Box title={t("pages.qualifications.edit.qualification")}>
        <Input
          maxWidth={350}
          required
          type="text"
          value={qualiToEdit.name}
          label={t("pages.qualifications.edit.name")}
          onChange={(name) => setQualiToEdit({ ...qualiToEdit, name })}
        />
        <Table
          rows={languageNameRows}
          header={
            t("pages.qualifications.edit.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
        <Dropdown
          multi={true}
          required
          onChangeMultiple={(userFunctionIds) =>
            setQualiToEdit({
              ...qualiToEdit,
              userFunctionIds,
            })
          }
          options={generateDropdownOptions(allUserFunctions, "title", "id")}
          label={t("pages.qualifications.edit.type")}
          selectedMultiOptions={qualiToEdit.userFunctionIds}
        />
        <Checkbox
          isChecked={!qualiToEdit.disabled}
          onCheck={() =>
            setQualiToEdit({
              ...qualiToEdit,
              disabled: !qualiToEdit.disabled,
            })
          }
          label={t("pages.qualifications.edit.active")}
        />
      </Box>
    </form>
  );
};
