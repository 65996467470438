import { TableRow } from "@sam/components";
import { ChangeHistory } from "shared";
import i18n from "../../i18n/i18n";

/**
 * Util method to convert ChangeHistory entries into TableRows
 * @param history Array of ChangeHistory objects
 * @returns Array of TableRows
 */
export const convertChangeHistoryIntoTableEntries = (
  history: ChangeHistory[]
): TableRow[] => {
  return history.map((changeEvent) => ({
    id: changeEvent.id,
    content: [
      changeEvent.createDate.toLocaleDateString("DE-de"),
      changeEvent.entity,
      changeEvent.entityId,
      changeEvent.details,
      i18n.t(`general.changeType.${changeEvent.action}`),
    ],
  }));
};
