import { Checkbox, Dropdown, Input, Option } from "@sam/components";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ContractInformation,
  DayOfWeek,
  WorkScheduleDistribution,
  generateEmptyContractInformation,
} from "shared";

const WorkScheduleEdit: React.FC<{
  updateUser(contractInformation: ContractInformation): void;
  selectedDays: DayOfWeek[];
  setSelectedDays: Dispatch<SetStateAction<DayOfWeek[]>>;
  createdPreciseEntries: Map<DayOfWeek, number>;
  setPreciseEntries: Dispatch<SetStateAction<Map<DayOfWeek, number>>>;
  contractInformation?: ContractInformation;
}> = ({
  updateUser,
  selectedDays,
  setSelectedDays,
  createdPreciseEntries,
  setPreciseEntries,
  contractInformation = generateEmptyContractInformation(""),
}) => {
  const { t } = useTranslation();

  /**
   * Helper to update the current user object
   * @param key The key of the value to update
   * @param newValue The user entered value
   */
  const updateField = useCallback(
    (
      key: keyof ContractInformation,
      newValue: Date | string | number | boolean | undefined
    ): void => updateUser({ ...contractInformation, [key]: newValue }),
    [contractInformation, updateUser]
  );

  /**
   * Helper to generate the dropdown options for the workschedule distribution enum
   * @returns The dropdown options for the distribution schedule
   */
  const generateScheduleDistribution = (): Option[] =>
    Object.keys(WorkScheduleDistribution).map((dist) => ({
      label: t(`pages.user.create.contract.worktimeDistribution.${dist}`),
      value: dist,
    }));

  /**
   * Displays only the fields which are needed for the selected distribution
   */
  const fieldsToRender = useMemo(() => {
    switch (contractInformation.workScheduleDistribution) {
      // in this case the weekly work time is given for the MO-FR week
      case WorkScheduleDistribution.WEEKLY:
        return (
          <Input
            maxWidth={"200px"}
            type="number"
            onChangeNumber={(newValue) =>
              updateUser({
                ...contractInformation,
                weeklyWorkingTime: newValue || 0,
              })
            }
            value={contractInformation.weeklyWorkingTime}
            label={t(
              "pages.user.create.contract.worktimeDistribution.weeklyWorkingTime"
            )}
          />
        );
      // in this case the work time is given for specific days
      case WorkScheduleDistribution.DAILY:
        return (
          <>
            <Input
              type="number"
              onChangeNumber={(newValue) =>
                updateField("weeklyWorkingTime", newValue)
              }
              value={contractInformation.weeklyWorkingTime}
              label={t(
                "pages.user.create.contract.worktimeDistribution.weeklyWorkingTime"
              )}
            />
            {Object.values(DayOfWeek).map((day) => (
              <Checkbox
                isChecked={selectedDays.includes(day)}
                label={t(`general.daysOfWeek.${day}`)}
                onCheck={() => {
                  if (selectedDays.includes(day))
                    return setSelectedDays((old) =>
                      old.filter((filterDay) => day !== filterDay)
                    );
                  setSelectedDays((old) => old.concat([day]));
                }}
              />
            ))}
          </>
        );
      // in this case the worktime is entered precisely for each day
      case WorkScheduleDistribution.PRECISE:
        return (
          <div className="user-create__working-schedule__precise-wrapper">
            {Object.values(DayOfWeek).map((day) => (
              <div>
                <Checkbox
                  isChecked={createdPreciseEntries.has(day)}
                  label={t(`general.daysOfWeek.${day}`)}
                  onCheck={() => {
                    if (createdPreciseEntries.has(day))
                      return setPreciseEntries((old) => {
                        old.delete(day);
                        return new Map(old);
                      });
                    setPreciseEntries((old) => new Map(old.set(day, 0)));
                  }}
                />
                {createdPreciseEntries.has(day) && (
                  <Input
                    maxWidth="150px"
                    type="number"
                    onChangeNumber={(newValue) =>
                      setPreciseEntries(
                        (old) => new Map(old.set(day, newValue || 0))
                      )
                    }
                    value={createdPreciseEntries.get(day)!}
                    label={t(
                      "pages.user.create.contract.worktimeDistribution.dailyWorkingTime"
                    )}
                  />
                )}
              </div>
            ))}
          </div>
        );
    }
  }, [
    contractInformation,
    createdPreciseEntries,
    selectedDays,
    setPreciseEntries,
    setSelectedDays,
    t,
    updateField,
    updateUser,
  ]);

  return (
    <div className="user-create__working-schedule">
      <Dropdown
        options={generateScheduleDistribution()}
        label="Wochenverteilung"
        onChange={(selectedDist) =>
          updateField("workScheduleDistribution", selectedDist)
        }
        selectedOption={contractInformation.workScheduleDistribution}
      />
      {fieldsToRender}
    </div>
  );
};

export default WorkScheduleEdit;
