import {
  Box,
  Button,
  Checkbox,
  CustomClasses,
  Dropdown,
  Table,
  TableRow,
  TopBar,
  YearlyCalendar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Country,
  CountryCode,
  County,
  OfficialHoliday,
  createOrUpdateMultipleOfficialHoliday,
  getOfficialHoliday,
  useData,
} from "shared";
import { Right } from "shared/src/userRole/UserRole.types";
import { NotAllowed } from "../../components/NotAllowed";
import { useUser } from "../../components/UserContext";
import {
  convertOfficialHolidaysIntoTableEntries,
  convertResponseToOfficialHoliday,
  filterOfficialHolidays,
} from "../../utils/officialHoliday/OfficialHoliday.Utils";
import {
  generateDropdownOptionsForCountry,
  generateDropdownOptionsForCounty,
  generateDropdownOptionsForYear,
} from "../../utils/timeTracking/TimeTracking.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";
import { ReactComponent as SaveIcon } from "../../assets/save.svg";

const OfficialHolidayOverview: React.FC = () => {
  const { user, axios } = useUser();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [customClasses, setCustomClasses] = useState<CustomClasses>();
  const { data: officialHolidays } = useData("OFFICIAL_HOLIDAYS_ALL", {
    config: { fallbackData: [] },
  });
  const [selectedYear, setSelectedYear] = useState<string>(
    new Date().getFullYear().toString()
  );
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [selectedCounty, setSelectedCounty] = useState<County>();
  const [officialHolidaysToEdit, setOfficialHolidaysToEdit] = useState<
    OfficialHoliday[]
  >([]);
  const [countriesToDisplay, setCountriesToDisplay] = useState<Country[]>([
    Country.CZECH,
    Country.GERMANY,
    Country.SLOWAKIA,
  ]);

  //useEffect hook to filter officialHolidays for given params
  useEffect(() => {
    if (!selectedYear || !selectedCountry) return;
    const filteredHolidays: OfficialHoliday[] = filterOfficialHolidays(
      officialHolidays,
      selectedYear,
      selectedCountry,
      selectedCounty
    );
    if (filteredHolidays.length > 0)
      setOfficialHolidaysToEdit(filteredHolidays);
    else {
      getOfficialHoliday(
        selectedCountry === Country.GERMANY
          ? CountryCode.DE
          : selectedCountry === Country.CZECH
          ? CountryCode.CZ
          : CountryCode.SK,
        "DE",
        new Date(`${selectedYear}-01-01`),
        new Date(`${selectedYear}-12-31`)
      ).then((loadedHolidays) => {
        const officialHolidays: OfficialHoliday[] =
          convertResponseToOfficialHoliday(
            loadedHolidays,
            selectedCountry,
            user.id
          );
        const filteredLoadedHolidays: OfficialHoliday[] =
          filterOfficialHolidays(
            officialHolidays,
            selectedYear,
            selectedCountry,
            selectedCounty
          );
        createOrUpdateMultipleOfficialHoliday(
          axios,
          filteredLoadedHolidays
        ).then(setOfficialHolidaysToEdit);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    officialHolidays,
    selectedYear,
    selectedCountry,
    selectedCounty,
    user.id,
  ]);

  /**
   * Hook to set customClasses for officialHolidays
   * keys are getting used as classes for dates in values
   */
  useEffect(() => {
    if (!officialHolidays) setCustomClasses({ weekend: "Sat,Sun" });
    setCustomClasses({
      officialHolidaysGermany: countriesToDisplay.includes(Country.GERMANY)
        ? filterOfficialHolidays(
            officialHolidays,
            selectedYear,
            Country.GERMANY
          ).map((holiday) => holiday.date.toISOString().split("T")[0])
        : "",
      officialHolidaysCzech: countriesToDisplay.includes(Country.CZECH)
        ? filterOfficialHolidays(
            officialHolidays,
            selectedYear,
            Country.CZECH
          ).map((holiday) => holiday.date.toISOString().split("T")[0])
        : "",
      officialHolidaysSlovakia: countriesToDisplay.includes(Country.SLOWAKIA)
        ? filterOfficialHolidays(
            officialHolidays,
            selectedYear,
            Country.SLOWAKIA
          ).map((holiday) => holiday.date.toISOString().split("T")[0])
        : "",
      weekend: "Sat,Sun",
    });
  }, [
    officialHolidays,
    selectedCountry,
    selectedYear,
    selectedCounty,
    countriesToDisplay,
  ]);

  //useEffect hook to generate TableEntries everytime the holidaysToEdit changes
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertOfficialHolidaysIntoTableEntries(
        officialHolidaysToEdit,
        setOfficialHolidaysToEdit
      ),
    [officialHolidaysToEdit]
  );

  /**
   * Helper method to update the selected Countries onClick
   * @param country to add or remove
   */
  const handleCountryCheck = (country: Country): void =>
    countriesToDisplay.includes(country)
      ? setCountriesToDisplay(
          countriesToDisplay.filter(
            (selectedCountry) => selectedCountry !== country
          )
        )
      : setCountriesToDisplay([...countriesToDisplay, country]);

  return isUserAllowedToDo(user.right, Right.OFFICIAL_HOLIDAY_OVERVIEW) ? (
    <>
      <TopBar title={t("pages.officialHoliday.headline")}>
        {isUserAllowedToDo(user.right, Right.OFFICIAL_HOLIDAY_CREATE) && (
          <Button
            type="button"
            disabled={!selectedCountry}
            value={<SaveIcon className="save-icon" />}
            onClick={() =>
              createOrUpdateMultipleOfficialHoliday(
                axios,
                officialHolidaysToEdit
              )
            }
          />
        )}
      </TopBar>
      <Box title={t("pages.officialHoliday.filterHeadline")}>
        <div className="three-columns">
          {Object.values(Country).map((country) => (
            <Checkbox
              onCheck={() => handleCountryCheck(country)}
              isChecked={countriesToDisplay.includes(country)}
              label={
                <div className={`official-holiday-overview__country-color`}>
                  <p>{t(`general.country.${country}`)}</p>
                  {countriesToDisplay.includes(country) && (
                    <div
                      className={`official-holiday-overview__country-color__${country.toLowerCase()}`}
                    />
                  )}
                </div>
              }
            />
          ))}
        </div>
      </Box>
      <div className="official-holiday-overview__calendar-wrapper">
        <YearlyCalendar
          year={selectedDate.getFullYear()}
          customClasses={customClasses}
          selectedDay={selectedDate}
          onPickDate={setSelectedDate}
        />
      </div>
      <Box>
        <div>
          <p className="official-holiday-overview__edit-headline">
            {t("pages.officialHoliday.headlineEdit")}
          </p>
          <div className="official-holiday-overview__edit-content">
            <div className="official-holiday-overview__edit-content__selection">
              <Dropdown
                selectedOption={selectedYear || ""}
                label={t("pages.officialHoliday.year")}
                options={generateDropdownOptionsForYear()}
                onChange={setSelectedYear}
              />
              {selectedYear && (
                <Dropdown
                  selectedOption={selectedCountry || ""}
                  label={t("pages.officialHoliday.countryLabel")}
                  onChange={(country) => {
                    setSelectedCountry(country as Country);
                    setSelectedCounty(undefined);
                  }}
                  options={generateDropdownOptionsForCountry()}
                  clearable={!!selectedCountry}
                />
              )}
              {selectedCountry === Country.GERMANY && (
                <Dropdown
                  onChange={(county) => setSelectedCounty(county as County)}
                  selectedOption={selectedCounty || ""}
                  options={generateDropdownOptionsForCounty()}
                  label={t("pages.officialHoliday.county")}
                />
              )}
            </div>
            <div className="official-holiday-overview__edit-content__preview-wrapper">
              {rows.length > 0 && (
                <>
                  <p className="official-holiday-overview__edit-content__table-headline">
                    {t("pages.officialHoliday.preview")}
                  </p>
                  <div className="official-holiday-overview__edit-content__table-wrapper">
                    <Table
                      minWidth="50vw"
                      rows={rows}
                      header={
                        t("pages.officialHoliday.tableHeader", {
                          returnObjects: true,
                        }) as TableHeader[]
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Box>
    </>
  ) : (
    <NotAllowed neccessaryRight={Right.OFFICIAL_HOLIDAY_OVERVIEW} />
  );
};
export default OfficialHolidayOverview;
