import {
  Box,
  Button,
  CustomClasses,
  Dropdown,
  Input,
  TopBar,
  YearlyCalendar,
} from "@sam/components";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Holiday,
  calculateVacationDaysForDateRange,
  createHoliday,
  generateNotification,
  useData,
} from "shared";
import dayjs from "shared/src/tools/Dayjs";
import { Right } from "shared/src/userRole/UserRole.types";
import { NotAllowed } from "../../components/NotAllowed";
import { useUser } from "../../components/UserContext";
import { createComment } from "../../utils/comment/Comment.utils";
import {
  checkIfDayIsInRange,
  generateEmptyHoliday,
} from "../../utils/holiday/Holiday.utils";
import { generateDropdownOptionsForYear } from "../../utils/timeTracking/TimeTracking.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";
import { NotificationType } from "shared/src/notification/notification.types";
import { ReactComponent as CloseIcon } from "../../assets/cancel.svg";

const HolidayOverview: React.FC = () => {
  const { axios, user } = useUser();
  const { t } = useTranslation();
  const [vacationDaysLeft, setVacationDaysLeft] = useState<number>(0);
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [customClasses, setCustomClasses] = useState<CustomClasses>({});
  const [newHolidayRequest, setNewHolidayRequest] = useState<Holiday>({
    ...generateEmptyHoliday({ userId: user.id }),
  });
  const [officialHolidays, setOfficialHolidays] = useState<Dayjs[]>([]);
  const [holidayToDisplay, setHolidayToDisplay] = useState<Holiday>();
  const { data: loadedHolidays, mutate: mutateHolidays } = useData(
    "HOLIDAYS_ALL_USER",
    {
      params: { userId: user.id },
      config: { fallbackData: [] },
    }
  );

  const { data: loadedOfficialHolidays } = useData("OFFICIAL_HOLIDAYS_ALL", {
    config: { userId: user.id, fallbackData: [] },
  });

  const userNoHasWorkSchedules: boolean =
    user.contractInformation.workSchedules.length === 0;

  //useEffect hook to setOfficialHolidays after they are loaded
  useEffect(() => {
    const dayJsOfficialHoliday: Dayjs[] = loadedOfficialHolidays.map(
      (holiday) => dayjs(holiday.date)
    );
    setOfficialHolidays(dayJsOfficialHoliday);
  }, [loadedOfficialHolidays]);

  /**
   * useEffect hook to set customClasses for the yearly calendar
   * object keys are used as css classnames for the specific dates
   */
  useEffect(() => {
    const config: CustomClasses = {
      holidayApproved: (day: Dayjs) =>
        loadedHolidays.some(
          (holiday) =>
            checkIfDayIsInRange(day, holiday.startDate, holiday.endDate) &&
            holiday.approved
        ),
      holidayNotApproved: (day: Dayjs) =>
        loadedHolidays.some(
          (holiday) =>
            checkIfDayIsInRange(day, holiday.startDate, holiday.endDate) &&
            !holiday.approved &&
            !holiday.denied
        ),
      officialHolidays:
        loadedOfficialHolidays.map(
          (holiday) => holiday.date.toISOString().split("T")[0]
        ) || [],
      weekend: "Sat,Sun",
    };
    setCustomClasses(config);
    let usedHolidays: number = 0;
    loadedHolidays.forEach((holiday) => (usedHolidays += holiday.totalAmount));
    setVacationDaysLeft(user.contractInformation.holidayAmount - usedHolidays);
  }, [
    loadedHolidays,
    loadedOfficialHolidays,
    user.contractInformation.holidayAmount,
  ]);

  /**
   * Helper function to handle the creation of a new holidayRequest
   */
  const handleHolidayRequest = (): void => {
    createHoliday(newHolidayRequest, axios).then((createdHoliday) => {
      generateNotification({
        type: NotificationType.SUCCESS,
        value: t("general.notification.success.holidayRequest"),
      });
      mutateHolidays([...loadedHolidays, createdHoliday]);
    });
  };

  /**
   * Helper method to update a holidayRequests endDate or startDate
   * @param date  new start or endDate
   * @param isStart boolean to decide if the method updates the start or endDate
   */
  const handleUpdateHolidayRequest = (date: Date, isStart: boolean) => {
    if (isStart)
      setNewHolidayRequest({
        ...newHolidayRequest,
        startDate: date,
        endDate: dayjs(date).isAfter(newHolidayRequest.endDate)
          ? date
          : newHolidayRequest.endDate,
        totalAmount:
          calculateVacationDaysForDateRange(
            date,
            newHolidayRequest.endDate,
            user.contractInformation.workSchedules,
            officialHolidays
          ) || 0,
      });
    else
      setNewHolidayRequest({
        ...newHolidayRequest,
        endDate: date,
        startDate: dayjs(date).isBefore(newHolidayRequest.startDate)
          ? date
          : newHolidayRequest.startDate,
        totalAmount:
          calculateVacationDaysForDateRange(
            date,
            newHolidayRequest.endDate,
            user.contractInformation.workSchedules,
            officialHolidays
          ) || 0,
      });
  };

  return isUserAllowedToDo(user.right, Right.HOLIDAY_OVERVIEW) ? (
    <div className="holiday-overview__wrapper">
      <TopBar title={t("pages.holiday.overview.headline")} />
      {holidayToDisplay && (
        <div className="holiday-overview__detail-wrapper">
          <div className="holiday-overview__detail-wrapper__title-wrapper">
            <p className="holiday-overview__detail-wrapper__description">
              {t("pages.holiday.overview.range", {
                replace: {
                  start: holidayToDisplay.startDate.toLocaleDateString("DE-de"),
                  end: holidayToDisplay.endDate.toLocaleDateString("DE-de"),
                },
              })}
            </p>
            <CloseIcon
              width={20}
              onClick={() => setHolidayToDisplay(undefined)}
            />
          </div>
          <p className="holiday-overview__detail-wrapper__description">
            {t("pages.holiday.overview.comment")}
          </p>
          <p className="holiday-overview__detail-wrapper__content">
            {holidayToDisplay.requestComment?.content}
          </p>
          <p className="holiday-overview__detail-wrapper__description">
            {t("pages.holiday.overview.message")}
          </p>
          <p className="holiday-overview__detail-wrapper__content">
            {holidayToDisplay.decisionComment?.content}
          </p>
        </div>
      )}
      <div className="holiday-overview__head">
        <Dropdown
          selectedOption={selectedYear.toString()}
          onChange={(year) => setSelectedYear(Number(year))}
          options={generateDropdownOptionsForYear()}
        />
        <p>
          {t("pages.holiday.overview.daysLeft")}: {vacationDaysLeft}
        </p>
      </div>
      <div className="holiday-overview__calendar-wrapper">
        <YearlyCalendar
          onPickDate={(date) =>
            setHolidayToDisplay(
              loadedHolidays.find((holiday) =>
                checkIfDayIsInRange(
                  dayjs(date),
                  holiday.startDate,
                  holiday.endDate
                )
              )
            )
          }
          year={selectedYear}
          customClasses={customClasses}
        />
      </div>
      <Box>
        <div className="holiday-overview__new-request__wrapper">
          <p className="holiday-overview__new-request__headline">
            {t("pages.holiday.overview.newRequest")}
          </p>
          {userNoHasWorkSchedules && (
            <p className="holiday-overview__new-request__warning">
              {t("pages.holiday.overview.noWorkSchedules")}
            </p>
          )}
          <div className="holiday-overview__new-request__input-wrapper">
            <Input
              label={t("pages.holiday.overview.newRequestStart")}
              type="date"
              value={newHolidayRequest.startDate}
              onChangeDate={(startDate) => {
                if (startDate) handleUpdateHolidayRequest(startDate, true);
              }}
              disabled={userNoHasWorkSchedules}
            />
            <Input
              label={t("pages.holiday.overview.newRequestEnd")}
              disabled={userNoHasWorkSchedules}
              type="date"
              value={newHolidayRequest.endDate}
              onChangeDate={(endDate) => {
                if (endDate) handleUpdateHolidayRequest(endDate, false);
              }}
            />
            <Input
              label={t("pages.holiday.overview.newRequestMessage")}
              type="text"
              value={newHolidayRequest.requestComment?.content || ""}
              onChange={(content) =>
                setNewHolidayRequest({
                  ...newHolidayRequest,
                  requestComment: { ...createComment(user.id, content) },
                })
              }
              disabled={userNoHasWorkSchedules}
            />
          </div>
          <p>
            {t("pages.holiday.overview.usedHolidays")}
            {calculateVacationDaysForDateRange(
              newHolidayRequest.startDate,
              newHolidayRequest.endDate,
              user.contractInformation.workSchedules,
              officialHolidays
            )}
          </p>
          <p>
            {t("pages.holiday.overview.remainingHolidays")}
            {vacationDaysLeft - newHolidayRequest.totalAmount}
          </p>
          <Button
            maxWidth={300}
            type="button"
            value={t("general.buttons.request")}
            onClick={handleHolidayRequest}
            disabled={userNoHasWorkSchedules}
          />
        </div>
      </Box>
    </div>
  ) : (
    <NotAllowed neccessaryRight={Right.HOLIDAY_OVERVIEW} />
  );
};
export default HolidayOverview;
