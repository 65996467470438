import { Country } from "../officialHoliday/OfficialHoliday.types";

/**
 * Interface for AbsenceReasons with the name, countries that it is valid in
 * and boolean if a timeTracking should be created,
 * for an absence with this reason
 */
export interface AbsenceReason {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdate: Date;
  updatedBy: string;
  name: string;
  validCountries: Country[];
  creatingTimeTracking: boolean;
  disabled: boolean;
  color: string;
}
/**
 * Helper method to generate an empty absenceReason
 * @param userId of the user that created the reason
 * @returns empty absenceReason
 */
export const generateEmptyAbsenceReason = (
  override?: Partial<AbsenceReason>
): AbsenceReason => ({
  id: undefined!,
  createDate: new Date(),
  createdBy: undefined!,
  creatingTimeTracking: false,
  lastUpdate: new Date(),
  name: "",
  updatedBy: undefined!,
  validCountries: [],
  disabled: false,
  color: "red",
  ...override,
});
