import { BoxProps } from "./Box.types";
import "./Box.scss";
export const Box: React.FC<BoxProps> = ({ children, title, id }) => {
  return (
    <div className="box" id={id}>
      {title && typeof title === "string" && (
        <p className="box__title">{title}</p>
      )}
      {typeof title !== "string" && title}
      {children}
    </div>
  );
};
