import { TopBarProps } from "./TopBar.types";
import { ReactComponent as ChevronLeft } from "../../assets/chevron-left.svg";
import "./TopBar.scss";
import { Input } from "../Input/Input";
import { useState } from "react";
export const TopBar: React.FC<TopBarProps> = ({
  children,
  onBackClick,
  title,
  onSearch,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  return (
    <div className="top-bar__wrapper">
      {onBackClick && (
        <div className="top-bar__chevron" onClick={() => onBackClick()}>
          <ChevronLeft className="top-bar__chevron__icon" />
        </div>
      )}
      {title && <h1 className="top-bar__wrapper__title">{title}</h1>}

      {(children || onSearch) && (
        <div className="top-bar__children">
          {children}
          {onSearch && (
            <Input
              type="text"
              onChange={(value) => {
                setSearchValue(value);
                onSearch(value);
              }}
              value={searchValue}
              maxWidth={350}
            />
          )}
        </div>
      )}
    </div>
  );
};
