// This file initializes dayjs with plugins and exports it.
import dayjs from "dayjs";

// plugins
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

export default dayjs;
