import { AxiosInstance } from "axios";
import { Car, MilageHistory } from "./Car.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import { Schedule } from "../project/Project.types";

/**
 * API method to get a car by id
 * @param axios instance of axios
 * @param id of the car to load
 * @returns loaded car
 */
export const getCarById = async (
  axios: AxiosInstance,
  id: string
): Promise<Car | undefined> => {
  return axios
    .get("/car/", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading car", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getCarById"),
      });
      return undefined;
    });
};

/**
 * Api method to load all cars
 * @param axios instance of axios
 * @returns Array of all cars
 */
export const getAllCars = async (axios: AxiosInstance): Promise<Car[]> => {
  return axios
    .get("/car/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all cars", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllCars"),
      });
      return [];
    });
};
/**
 * Api method to load all cars
 * @param axios instance of axios
 * @returns Array of all cars
 */
export const getAllActiveCars = async (
  axios: AxiosInstance
): Promise<Car[]> => {
  return axios
    .get("/car/all/active")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all cars", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllCars"),
      });
      return [];
    });
};

/**
 * Api method to load all cars that are available (which means that they are not
 * assigned to a user)
 * @param axios instance of axios
 * @returns Array of all available cars
 */
export const getAllAvailableCars = async (
  axios: AxiosInstance
): Promise<Car[]> => {
  return axios
    .get("/car/all/available")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all available cars", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllCars"),
      });
      return [];
    });
};

/**
 * Api method to load all cars that are available (which means that they are not
 * assigned to a user) AND the car that is assigned to the user itself
 * @param axios instance of axios
 * @returns Array of all available cars
 */
export const getAllAvailableCarsForUser = async (
  axios: AxiosInstance,
  carId: string
): Promise<Car[]> => {
  return axios
    .get("/car/all/available/user", { params: { carId } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all available cars", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllCars"),
      });
      return [];
    });
};

/**
 * API method to create a new Car
 * @param axios instance of axios
 * @param car car to create
 * @returns created Car
 */
export const createNewCar = async (
  axios: AxiosInstance,
  car: Car
): Promise<Car> => {
  return axios
    .post("/car/", car)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating Car", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewCar"),
      });
      return undefined;
    });
};

/**
 * API method to update a car
 * @param axios instance of axios
 * @param car updated Car object
 * @returns updated car
 */
export const updateCar = async (
  axios: AxiosInstance,
  car: Car
): Promise<Car | undefined> => {
  return axios
    .post("/car/update", car)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateCar"),
      });
      console.error("Error while updating car", exc);
      return undefined;
    });
};

/**
 * API method to load the milage history for the given car id
 *
 * @param carId The id of the car
 * @param axios The axios instance
 * @returns Loaded milage history entries
 */
export const loadMilageHistoryForCar = async (
  carId: string,
  axios: AxiosInstance
): Promise<MilageHistory[]> => {
  return axios
    .get("/car/milages", { params: { carId } })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during milage loading", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.milageLoad"),
      });
      return [];
    });
};

/**
 * API method to load ALL schedule entries for the given car id
 *
 * @param userId The id of the car to load the schedules for
 * @param axios The axios instance
 * @returns List of all schedules for the given car
 */
export const getAllSchedulesForCar = async (
  carId: string,
  axios: AxiosInstance
): Promise<Schedule[]> => {
  if (!carId) return [];
  return axios
    .get("/schedule/car/all", {
      params: { carId },
    })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during schedule load for car", exc);
      generateNotification({
        value: i18n.t("general.notification.error.scheduleLoad"),
        type: NotificationType.ERROR,
      });
      return [];
    });
};

/**
 * API Method to get a csv file containing an overview about all cars
 * @param axios instance of axios
 * @returns Promise containing the svg file or undefined on error
 */
export const getCarOverviewCsv = async (
  axios: AxiosInstance
): Promise<Blob | undefined> => {
  return axios
    .get("/export/csv/cars")
    .then((res) => new Blob([res.data], { type: "text/csv" }))
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getOverviewCsv"),
      });
      console.error("Error while loading csv for cars", exc);
      return undefined;
    });
};
