import { Box, Button, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { addOrRemove, Order, ProjectState, useData } from "shared";
import { getProjectDescriptionPdf } from "shared/src/project/Project.axios";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { downloadFile } from "../../utils/files/Files.utils";
import { convertProjectsIntoTableEntries } from "../../utils/project/Project.utils";

export const ProjectOverview: React.FC = () => {
  const { axios } = useUser();
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<string>("");
  const [selectedProjectStates, setSelectedProjectStates] = useState<
    ProjectState[]
  >([]);
  const [selectedBusinessAreas, setSelectedBusinessAreas] = useState<string[]>(
    []
  );

  const { data: loadedProjects } = useData("PROJECT_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerUsers } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedBusinessAreas } = useData("BUSINESSAREA_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const navigate = useNavigate();

  /**
   * Helper method to download a preojectDescription
   */
  const downloadDescription = useCallback((projectId: string): void => {
    getProjectDescriptionPdf(axios, projectId).then(
      (loadedFile) =>
        loadedFile &&
        downloadFile(loadedFile, t("pages.project.overview.descriptionFileNae"))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Hook to convert projects into tableRows after all projects and customers are loaded
  const rows: TableRow[] = useMemo((): TableRow[] => {
    if (
      selectedBusinessAreas.length === 0 &&
      selectedProjectStates.length === 0
    )
      return convertProjectsIntoTableEntries(
        loadedProjects,
        loadedCustomers,
        loadedCustomerLocations,
        loadedCustomerUsers,
        loadedOrders,
        navigate,
        downloadDescription
      );

    let filteredProjects = [...loadedProjects];
    if (selectedProjectStates.length > 0) {
      filteredProjects = loadedProjects.filter((project) =>
        selectedProjectStates.includes(project.projectState)
      );
    }
    if (selectedBusinessAreas.length > 0) {
      filteredProjects = filteredProjects.filter((project) => {
        const order: Order | undefined = loadedOrders.find(
          (order) => order.id === project.orderId
        );
        if (!order) return false;
        else return selectedBusinessAreas.includes(order.businessAreaId);
      });
    }
    return convertProjectsIntoTableEntries(
      filteredProjects,
      loadedCustomers,
      loadedCustomerLocations,
      loadedCustomerUsers,
      loadedOrders,
      navigate,
      downloadDescription
    );
  }, [
    downloadDescription,
    loadedCustomerLocations,
    loadedCustomerUsers,
    loadedCustomers,
    loadedOrders,
    loadedProjects,
    navigate,
    selectedBusinessAreas,
    selectedProjectStates,
  ]);
  return (
    <>
      <TopBar
        title={t("pages.project.overview.topBarHeadline")}
        onSearch={setFilterValue}
      >
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("/project/create")}
        />
      </TopBar>
      <Box>
        <div className="project-overview__filter-wrapper">
          <p>{t("pages.project.overview.state")}</p>
          <div className="three-columns">
            {Object.values(ProjectState).map((state) => (
              <Button
                value={t(`pages.project.overview.projectState.${state}`)}
                active={selectedProjectStates.includes(state)}
                onClick={() =>
                  setSelectedProjectStates(
                    addOrRemove(selectedProjectStates, state)
                  )
                }
              />
            ))}
          </div>
          <p>{t("pages.project.overview.businessArea")}</p>
          <div className="three-columns">
            {loadedBusinessAreas.map((area) => (
              <Button
                value={area.name}
                active={selectedBusinessAreas.includes(area.id)}
                onClick={() =>
                  setSelectedBusinessAreas(
                    addOrRemove(selectedBusinessAreas, area.id)
                  )
                }
              />
            ))}
          </div>
        </div>
      </Box>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.project.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
