import { AxiosInstance } from "axios";
import { Bike } from "./Bike.types";

/**
 * API method to create a new bike
 * @param axios instance of axios
 * @param bike bike to create
 * @returns created bike or undefined
 */
export const createNewBike = async (
  axios: AxiosInstance,
  bike: Bike
): Promise<Bike | undefined> => {
  return axios
    .post("/bike/", bike)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating bike", exc);
      return undefined;
    });
};

/**
 * API method to update a bike
 * @param axios instance of axios
 * @param bike updated bike object
 * @returns updated bike or undefined
 */
export const updateBike = async (
  axios: AxiosInstance,
  bike: Bike
): Promise<Bike | undefined> => {
  return axios
    .post("/bike/update", bike)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updating bike", exc);
      return undefined;
    });
};

/**
 * API Method to load all bikes
 * @param axios instance of axios
 * @returns Array of found bikes
 */
export const getAllBikes = async (axios: AxiosInstance): Promise<Bike[]> => {
  return axios
    .get("/bike/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all bikes", exc);
      return [];
    });
};

/**
 * API Method to load all bikes
 * @param axios instance of axios
 * @returns Array of found bikes
 */
export const getAllActiveBikes = async (
  axios: AxiosInstance
): Promise<Bike[]> => {
  return axios
    .get("/bike/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all bikes", exc);
      return [];
    });
};
