import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useData } from "shared";
import { Right } from "shared/src/userRole/UserRole.types";
import { NotAllowed } from "../../components/NotAllowed";
import { useUser } from "../../components/UserContext";
import { convertHolidayRequestsToTableEntries } from "../../utils/holiday/Holiday.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

const HolidayRequestOverview: React.FC = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState<string>("");

  const { data: loadedHolidayRequests } = useData("HOLIDAYS_ALL_SUBS", {
    params: { userId: user.id },
    config: { fallbackData: [] },
  });
  const { data: loadedSimpleUsers } = useData("SIMPLEUSERS_ALL", {
    params: { userId: user.id },
    config: { fallbackData: [] },
  });

  //Holds tableRows for holidayRequests
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertHolidayRequestsToTableEntries(
        loadedHolidayRequests,
        loadedSimpleUsers,
        navigate
      ),
    [loadedHolidayRequests, loadedSimpleUsers, navigate]
  );

  return isUserAllowedToDo(user.right, Right.HOLIDAY_EDIT) ? (
    <div className="holiday-requests__overview__wrapper">
      <TopBar
        onSearch={setFilterValue}
        title={t("pages.holiday.createOrEdit.headline")}
        onBackClick={() => navigate(-1)}
      />
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.holiday.requestOverview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </div>
  ) : (
    <NotAllowed neccessaryRight={Right.HOLIDAY_EDIT} />
  );
};
export default HolidayRequestOverview;
