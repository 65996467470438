import { TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import {
  Currency,
  CurrencyConversion,
} from "shared/src/currency/Currency.types";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";

/**
 * Util method to convert Currencies into TableEntries
 * @param currencies to convert
 * @param navigate navigateFunction to navigate to edit
 * @returns  Array of TableRows
 */
export const convertCurrencyIntoTableEntries = (
  currencies: Currency[],
  navigate: NavigateFunction
): TableRow[] => {
  return currencies.map((currency) => ({
    id: currency.id,
    onClick: () => navigate("/currency/edit", { state: { currency } }),
    content: [
      currency.symbol,
      currency.title,
      currency.intlCode,
      currency.disabled
        ? i18n.t("pages.currency.overview.deactivated")
        : i18n.t("pages.currency.overview.active"),
      <div>
        <EditIcon
          title={i18n.t("general.icons.edit")}
          width={30}
          onClick={(evt) => {
            evt.stopPropagation();
            navigate("/currency/edit", { state: { currency } });
          }}
        />
      </div>,
    ],
  }));
};

/**
 * Util method to create a new currency object
 * @param override partial of currency to adjust created object
 * @returns generated currency object
 */
export const generateEmptyCurrency = (
  override?: Partial<Currency>
): Currency => ({
  id: undefined!,
  createDate: new Date(),
  createdBy: "",
  disabled: false,
  intlCode: "",
  lastUpdate: new Date(),
  symbol: "",
  title: "",
  updatedBy: "",
  ...override,
});

/**
 * Util method to convert currencyConversions into TableEntries
 * @param conversions Array of currencyConversion
 * @returns Array of TableRows
 */
export const convertConversionsIntoTableEntries = (
  conversions: CurrencyConversion[]
): TableRow[] => {
  return conversions.map((conversion) => ({
    id: conversion.id,
    content: [
      conversion.active
        ? i18n.t("pages.currency.edit.active")
        : i18n.t("pages.currency.edit.deactivated"),
      conversion.createDate.toLocaleDateString("DE-de"),
      conversion.factor,
    ],
  }));
};
