import { ToastOptions, toast } from "react-toastify";
import {
  NotificationParams,
  NotificationPosition,
  NotificationType,
} from "./notification.types";
import "react-toastify/dist/ReactToastify.css";

/**
 * Helper to generateNotifications
 * @param props {@link NotificationParams} to configurate the notification
 */
export const generateNotification = (props: NotificationParams): void => {
  const notificationConfig: ToastOptions = {
    autoClose: props.autoClose ? props.autoClose : 3000,
    className: props.className ? props.className : "",
    position: props.position || NotificationPosition.TOP_RIGHT,
    bodyClassName: props.className ? props.className : "",
    closeButton: props.closeButton,
    type: props.type,
    style: {
      width: props.width ? props.width : "",
      height: props.height ? props.height : "",
    },
    icon: false,
  };
  switch (props.type) {
    case NotificationType.WARNING:
      toast.warn(props.value, notificationConfig);
      break;
    case NotificationType.SUCCESS:
      toast.success(props.value, notificationConfig);
      break;
    case NotificationType.ERROR:
      toast.error(props.value, notificationConfig);
      break;
    case NotificationType.DEFAULT:
      toast(props.value, notificationConfig);
      break;
    case NotificationType.INFO:
      toast.info(props.value, notificationConfig);
      break;
    default:
      toast(props.value, notificationConfig);
      break;
  }
  return;
};
