import { TimeWidget } from "../components/widgets/TimeWidget";

const Dashboard: React.FC = () => {
  return (
    <div className="dashboard-page">
      <TimeWidget />
    </div>
  );
};

export default Dashboard;
