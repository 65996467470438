import { ShiftType } from "../customerLocation/CustomerLocation.types";
import { OfferedTask } from "../offer/Offer.types";

/**
 * Interface for a project containing customerData, limits and necessary tools
 */
export interface Project {
  id: string;
  updatedBy: string;
  lastUpdate: Date;
  createdBy: string;
  createDate: Date;
  customerId: string;
  orderId: string;
  acceptedOfferId: string;
  equipments: string[];
  additionalEquipment: string;
  toolings: Tooling[];
  additionalTooling: string;
  amountLimit: number;
  timeLimit: number;
  moneyLimit: number;
  calculatingBreaks: boolean;
  deactivated: boolean;
  locationId: string;
  instructions: WorkInstruction[];
  errorPatterns: ErrorPattern[];
  numberRangeNumber: string;
  endDate?: Date;
  endTime?: number;
  startDate: Date;
  startTime: number;
  projectState: ProjectState;
  annotation: string;
  protocolMailing?: ProtocolMailingSettings;
}

/**
 * enum to track a project from the start till the end
 */
export enum ProjectState {
  PROJECT_START = "PROJECT_START",
  PROJECT_RUNNING = "PROJECT_RUNNING",
  PROJECT_COMPLETED = "PROJECT_COMPLETED",
}

/**
 * enum for different tools thatis used in projects
 */
export enum Tooling {
  MEASURE_STICK = "MEASURE_STICK",
  MAG_GLASS = "MAG_GLASS",
  TWEEZERS = "TWEEZERS",
  VISUAL = "VISUAL",
  MEASURE_EQUIPMENT = "MEASURE_EQUIPMENT",
  TESTING_EQUIPMENT = "TESTING_EQUIPMENT",
}

/**
 * interface for projectSchedules that includes the users and cars that are planned for a day
 */
export interface Schedule {
  id: string;
  createDate?: Date;
  createdBy?: string;
  lastUpdate?: Date;
  updatedBy?: string;
  referenceId: string;
  orderId: string;
  scheduleDate?: Date;
  startDate?: Date;
  endDate?: Date;
  scheduledUsers: Map<ShiftType, string[]>;
  scheduledCars: Map<ShiftType, string[]>;
  appointmentDetail?: ScheduleAppointmentDetail;
  type: ScheduleType;
}

/**
 * Config what parts of the protocol pdf should be included while sending and who receives the protocol
 */
export interface ProtocolMailingSettings {
  time: boolean;
  parts: boolean;
  receivers: string[];
}

/**
 * Enum to differentiate the types of a schedule Entry
 */
export enum ScheduleType {
  PROJECT_SCHEDULE = "PROJECT_SCHEDULE",
  USER_ABSENCE = "USER_ABSENCE",
  VEHICLE_SCHEDULE = "VEHICLE_SCHEDULE",
}
/**
 * Defines the details of a non project related schedule
 */
export interface ScheduleAppointmentDetail {
  id: string;
  title: string;
  description: string;
  startTime: number;
  endTime: number;
  entireDay: boolean;
  location: string;
  reasonId: string;
  state: AppointmentState;
}

/**
 * Defines the availability of a user when booked in an appointment
 */
export enum AppointmentState {
  AVAILABLE = "AVAILABLE",
  OPTIONAL = "OPTIONAL",
  BOOKED = "BOOKED",
  ABSENT = "ABSENT",
  WORK_OTHER_PLACE = "WORK_OTHER_PLACE",
}

/**
 * Model for workInstructions that are attached to projects and describe
 * different instructions about what should be done
 */
export interface WorkInstruction {
  id: string;
  createdBy: string;
  createDate: Date;
  updatedBy?: string;
  lastUpdate?: Date;
  content: string;
  index: number;
  projectId: string;
  disabled: boolean;
  instructionFrom?: string;
  instructionBy?: string;
  instructionTimeStamp?: Date;
}

/**
 * Interface for ErrorPatterns that are connected to a project and describe
 * different types of defects or errors that could be found throughout the check
 */
export interface ErrorPattern {
  id: string;
  createdBy: string;
  createDate: Date;
  updatedBy?: string;
  lastUpdate?: Date;
  index: number;
  content: string;
  projectId: string;
  disabled: boolean;
}

/**
 * Config to specify which tasks of a customerProject should be converted to a
 * project
 */
export interface customerProjectConfig {
  offerId: string;
  tasks: OfferedTask[];
  plannedWorkers: number;
  customerOrderNumber: string;
}
