import { useEffect } from "react";
import { useUser } from "../../components/UserContext";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { Loader } from "@sam/components";

const TimeKeepingTrigger = () => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const { user } = useUser();

  //UseEffect to check if a user is logged in and redirects him directly
  //or after the login to start/end a timeTracking
  useEffect(() => {
    if (!user && !keycloak.authenticated)
      keycloak
        .login()
        .then(() => navigate("/timekeeping", { state: { autostart: true } }));
    else navigate("/timekeeping", { state: { autostart: true } });
  }, [user, keycloak, navigate]);

  return <Loader />;
};
export default TimeKeepingTrigger;
