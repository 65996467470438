import { Language } from "../Language/Language.types";
/**
 * The main CRM entry
 */
export interface CrmEntry {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdated?: Date;
  updatedBy?: string;
  officeId: string;
  typeId: string
  // Either the customer/contact id OR the names
  // are defined, the other on is null
  customerId?: string;
  contactId?: string;
  customerName?: string;
  contactName?: string;
}

/**
 * The actual "Todo" of a crm entry
 */
export interface CrmActionEntry {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdated?: Date;
  updatedBy?: string;
  crmEntryId: string;
  internalContact: string[];
  actionId: string;
  dueDate?: Date;
  done: boolean;
  note?: string; 
}

/**
 * A schambeck managed model to define which actions can be taken in a crm entry
 */
export interface CrmAction {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdated?: Date;
  updatedBy?: string;
  title: string;
  names: Map<Language, string>;
}

/**
 * A schambeck managed model to define which types a crm entry can be
 */
export interface CrmType {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdated?: Date;
  updatedBy?: string;
  title: string;
  names: Map<Language, string>;
}

/**
 * This helper model is used during the creation of a new CrmEntry
 */
export interface CrmEntryCreateRequest {
  newEntry: CrmEntry;
  initialAction: CrmActionEntry;
}

/**
 * This enum is used locally only to differ between the two main manage types for crm
 */
export enum CrmManageType {
  ACTION = "ACTION",
  TYPE = "TYPE",
}
