import {
  Box,
  Button,
  Dropdown,
  Table,
  TableRow,
  TopBar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MonthlyReceipt,
  getLohnlisteCSVForMonth,
  getOrCreateMonthlyReceiptsForMonth,
  getOverviewForMonth,
  updateMultipleMonthlyReceipts,
  useData,
} from "shared";
import { Language } from "shared/src/Language/Language.types";
import { ReactComponent as FileDownload } from "../../assets/file_save.svg";
import { useUser } from "../../components/UserContext";
import { convertMonthlyReceiptIntoTableEntry } from "../../utils/monthlyReceipt/MonthlyReceipt.utils";
import {
  generateDropdownOptionsForMonth,
  generateDropdownOptionsForYear,
} from "../../utils/timeTracking/TimeTracking.utils";
import { ReactComponent as SaveIcon } from "../../assets/save.svg";
import { downloadFile } from "../../utils/files/Files.utils";

/**
 * Summary page to display the monthlyOverviews of workingTimes for different months and years
 * @returns page to display the overview
 */
const MonthlySummary: React.FC = () => {
  const { user, axios } = useUser();
  const { t } = useTranslation();
  const dateNow: Date = new Date();
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState<
    [number, number]
  >([dateNow.getMonth(), dateNow.getFullYear()]);
  const [monthlyOverviews, setMonthlyOverviews] = useState<MonthlyReceipt[]>(
    []
  );
  const { data: users } = useData("SCHAMBECK_USERS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  /**
   * Helper method to load all monthlyReceipts
   * for seleceted year and month
   */
  const loadOverviewsForMonth = (): void => {
    getOrCreateMonthlyReceiptsForMonth(
      axios,
      selectedMonthAndYear[0],
      selectedMonthAndYear[1],
      user.id
    ).then(setMonthlyOverviews);
  };

  //TableRows containing the monthly reciepts
  const rows: TableRow[] = useMemo(
    () =>
      convertMonthlyReceiptIntoTableEntry(
        monthlyOverviews,
        users,
        selectedMonthAndYear[1],
        setMonthlyOverviews
      ),
    [monthlyOverviews, selectedMonthAndYear, users]
  );

  /**
   * Helper method to handle the download of monthlyOverviews
   */
  const handlePdfDownload: () => Promise<void> = async () => {
    const overview: Blob | undefined = await getOverviewForMonth(
      axios,
      selectedMonthAndYear[0] + 1,
      selectedMonthAndYear[1],
      Language.DE
    );
    if (overview)
      downloadFile(
        overview,
        t("pages.monthlySummary.fileNamePDF", {
          replace: {
            month: selectedMonthAndYear[0] + 1,
            year: selectedMonthAndYear[1],
          },
        })
      );
  };

  /**
   * Helper method to handle the download of the lonliste csv file
   */
  const handleCSVDownload: () => Promise<void> = async () => {
    const csvFile: Blob | undefined = await getLohnlisteCSVForMonth(
      axios,
      selectedMonthAndYear[0] + 1,
      selectedMonthAndYear[1],
      Language.DE
    );
    if (csvFile) {
      downloadFile(
        csvFile,
        t("pages.monthlySummary.fileNameCSV", {
          replace: {
            month: selectedMonthAndYear[0] + 1,
            year: selectedMonthAndYear[1],
          },
        })
      );
    }
  };

  return (
    <>
      <TopBar title={t("pages.monthlySummary.children.topBarHeadline")}>
        <div className="topbar__content">
          <Button
            value={<SaveIcon className="save-icon" />}
            disabled={monthlyOverviews.length === 0}
            onClick={() =>
              updateMultipleMonthlyReceipts(axios, monthlyOverviews)
            }
          />
          <FileDownload
            onClick={() => {
              if (monthlyOverviews.length > 0) handlePdfDownload();
            }}
          />
        </div>
      </TopBar>
      <Box>
        <div className="monthly-summary__filter-wrapper">
          <p className="monthly-summary__filter-headline">
            {t("pages.monthlySummary.filter.title")}
          </p>
          <div className="monthly-summary__filter-dropdown">
            <Dropdown
              options={generateDropdownOptionsForMonth()}
              selectedOption={selectedMonthAndYear[0].toString()}
              onChange={(month) =>
                setSelectedMonthAndYear([
                  Number(month),
                  selectedMonthAndYear[1],
                ])
              }
            />
            <Dropdown
              options={generateDropdownOptionsForYear()}
              selectedOption={selectedMonthAndYear[1].toString()}
              onChange={(year) =>
                setSelectedMonthAndYear([selectedMonthAndYear[0], Number(year)])
              }
            />
          </div>
          <Button
            maxWidth={200}
            value={t("general.buttons.load")}
            onClick={loadOverviewsForMonth}
          />
        </div>
      </Box>
      <Box>
        <form id="receipt-edit-form">
          <div className="monthly-summary__table-head">
            <p className="monthly-summary__table-headline">
              {t(`general.monthAndYear.${selectedMonthAndYear[0]}`, {
                replace: { year: selectedMonthAndYear[1] },
              })}
            </p>
            <div className="monthly-summary__table-head__buttons">
              <Button
                value={t("pages.monthlySummary.children.lock")}
                backgroundColor="#BC2E46"
                disabled={monthlyOverviews.length === 0}
              />
              <FileDownload
                width={30}
                onClick={() => {
                  if (monthlyOverviews.length > 0) handleCSVDownload();
                }}
              />
            </div>
          </div>
          <Table
            header={
              t("pages.monthlySummary.tableHeader", {
                returnObjects: true,
              }) as TableHeader[]
            }
            expandable
            rows={rows}
          />
        </form>
      </Box>
    </>
  );
};

export default MonthlySummary;
