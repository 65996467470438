import { Dexie, Table } from "dexie";
import { IndexedTimeTracking } from "./db.types";

export class TimeKeepingDexie extends Dexie {
  timeTracking!: Table<IndexedTimeTracking>;
  constructor() {
    super("sami");
    this.version(1).stores({
      timeTracking: "++id, serverId, startTime, startDate",
    });
  }
}
export const db = new TimeKeepingDexie();
