import { AxiosInstance } from "axios";
import { Office } from "./Office.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import { mapReplacer } from "..";

/**
 * API method to load all Offices from db
 * @param axios instance of axios
 * @returns Array of found offices
 */
export const getAllOffices = async (
  axios: AxiosInstance
): Promise<Office[]> => {
  return axios
    .get("/office/all")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllOffices"),
      });
      console.error("Error while loading all Offices", exc);
      return [];
    });
};

/**
 * API method to load all active Offices from db
 * @param axios instance of axios
 * @returns Array of found offices
 */
export const getAllActiveOffices = async (
  axios: AxiosInstance
): Promise<Office[]> => {
  return axios
    .get("/office/all/active")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllOffices"),
      });
      console.error("Error while loading all Offices", exc);
      return [];
    });
};

/**
 * API method to create an new office on the db
 * @param axios instance of axios
 * @param office to create
 * @returns boolean if saving was successful
 */
export const createNewOffice = async (
  axios: AxiosInstance,
  office: Office
): Promise<Office | undefined> => {
  return axios
    .post("/office/", JSON.stringify(office, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createOffice"),
      });
      console.error("Error while creating new office", exc);
      return undefined;
    });
};

/**
 * API method to load an office by id
 * @param axios instance of axios
 * @param id of the office to get
 * @returns office if found, undefined if no office was found
 */
export const getOfficeById = async (
  axios: AxiosInstance,
  id: string
): Promise<Office | undefined> => {
  return axios
    .get("/office/", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getOffice"),
      });
      console.error("Error while loading office", exc);
      return undefined;
    });
};

/**
 * API method to update an office on the db
 * @param axios instance of axios
 * @param office updated office object
 * @returns updated office or undefined
 */
export const updateOffice = async (
  axios: AxiosInstance,
  office: Office
): Promise<Office | undefined> => {
  return axios
    .post("/office/update", JSON.stringify(office, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateOffice"),
      });
      console.error("Error while updating office", exc);
      return undefined;
    });
};

/**
 * API method to toggle officeDeactivated
 * @param axios instance of Axios
 * @param id of the of the office to toggle deactivated
 * @returns  updated Offfice object, if successful or undefined
 */
export const toggleOfficeDeactivated = async (
  axios: AxiosInstance,
  id: string
): Promise<Office | undefined> => {
  return axios
    .post("/office/deactivate", id, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => {
      generateNotification({
        type: NotificationType.SUCCESS,
        value: i18n.t("general.notification.success.updateOffice"),
      });
      return res.data;
    })
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateOffice"),
      });
      console.error("Error while toggle active on office", exc);
      return undefined;
    });
};
